// sidebarSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import React, { ComponentType } from 'react';

// Define the type for the initial state
interface SidebarState {
    isInitialised: boolean;
    editComponent: ComponentType;
}

// Define an empty component to use as the initial state
const DefaultComponent: React.FC = () => {
    return null
}

// Define the initial state using that type
const initialState: SidebarState = {
    isInitialised: false,
    editComponent: DefaultComponent
};


// Create the slice
const editAutomationSidebar = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        updateSidebar(state, action: PayloadAction<{ editComponent: ComponentType; isInitialised: boolean }>) {
            state.isInitialised = action.payload.isInitialised;
            state.editComponent = action.payload.editComponent;
        }
    }
});

export const { updateSidebar } = editAutomationSidebar.actions;

export default editAutomationSidebar.reducer;
