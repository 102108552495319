import React, { useEffect, useState } from "react";
import { Pencil1Icon, TrashIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@Constants/routes";
import { Switch } from "@Components/shadcn/ui/switch";
import { useUpdateAutomationStatusMutation } from "../../apiSlice";
import { formatTimestampDifference } from "@Utils/common"


interface AutomationTableInterface {
    data: Array<any>,
    openDeleteAutomationModal: (id:number) => void,
    fetchAutomations: ()=>void
  }

const AutomationTable = (props: AutomationTableInterface) => {

    const navigate = useNavigate()

    let [UpdateAutomationStatus, { data: UpdateAutomationStatusData, error: UpdateAutomationStatusError, isLoading: isUpdateAutomationStatusLoading }] = useUpdateAutomationStatusMutation()

    const handleSwitchOnCheck = (id:number, status:boolean) => {
        UpdateAutomationStatus({
            automation_id: id, status
        })
    }

    useEffect(() => {
        if (UpdateAutomationStatusData) {
            props.fetchAutomations()
        }
    }, [UpdateAutomationStatusData, UpdateAutomationStatusError])
    

    return(
        <div className="overflow-x-auto">
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left">
                    <thead className="text-sm border-b text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-8">
                                Name
                            </th>
                            <th scope="col" className="px-6 py-8">
                                Triggered
                            </th>
                            {/* <th scope="col" className="px-6 py-8">
                                Steps Finished
                            </th> */}
                            <th scope="col" className="px-6 py-8">
                                Status
                            </th>
                            <th scope="col" className="px-6 py-8">
                                Modified on
                            </th>
                            <th scope="col" className="px-6 py-8">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {props?.data && props?.data.length > 0 ? (
                            props.data.map((automation: any, index: number) => (
                                <tr key={automation.id} className="bg-white">
                                    <td className="p-4">
                                        <p className="text-[16px] text-[#000FFF] hover:cursor-pointer" onClick={() => navigate(ROUTES.FLOW_BUILDER.replace(':automation_doc_id', automation.id))}>{automation.name}</p>
                                    </td>
                                    <td className="px-[3rem]">
                                        <p className="text-[16px]">{automation.triggered}</p>
                                    </td>
                                    <td className="px-[3rem]">
                                        <p className="text-[16px] flex align-middle">
                                            <Switch className="data-[state=checked]:bg-green-500 "
                                                disabled={isUpdateAutomationStatusLoading}
                                                checked={automation?.status} id={`${automation?.id}-status`}
                                                name="automation-status"
                                                onCheckedChange={(status) => { handleSwitchOnCheck(automation?.id, status) }} />
                                            <label className={"ml-3 font-bold " + (automation?.status ? ' text-green-600' : 'text-gray-400')} htmlFor={`${automation?.id}-status`}>{automation?.status ? 'Active' : 'Inactive'}</label>
                                        </p>
                                    </td>
                                    <td className="px-4 py-4">
                                        <div className="flex flex-col items-start">
                                            <p className="text-[13px] text-[#333333]">Updated: <b>{formatTimestampDifference(automation.updated_at)}</b></p>
                                            <p className="text-[13px] text-[#333333]">Created: <b>{formatTimestampDifference(automation.created_at)}</b></p>
                                        </div>
                                    </td>
                                    <td className="flex items-center px-6 py-4">
                                        <div className="flex h-full gap-4 align-middle">
                                            <div className="p-2 hover:cursor-pointer hover:bg-[#887DF2] hover:text-[#ffffff] rounded-xl bg-[#f3f4f6]" onClick={() => navigate(ROUTES.FLOW_BUILDER.replace(':automation_doc_id', automation.id))}>
                                                <Pencil1Icon />
                                            </div>
                                            <div className="p-2 hover:cursor-pointer hover:bg-[#887DF2] hover:text-[#ffffff] rounded-xl bg-[#f3f4f6]" onClick={() => props.openDeleteAutomationModal(automation.id)}>
                                                <TrashIcon />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={5} className="text-center p-4 text-2xl">
                                    No automations found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
} 

export default AutomationTable