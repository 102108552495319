const styles = {
  CONTAINER: {
    paddingTop: "0.75rem",
    paddingBottom: "0",
  },
  WRAPPER: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: "1rem",
  },
  PROFILE_WRAPPER: {
    flexShrink: "0",
  },
  PROFILE_IMAGE: {
    width: "3rem",
    height: "3rem",
    borderRadius: "50%",
    marginRight: "0.75rem",
  },
  NAME_WRAPPER: {
    flex: "1",
    minWidth: "0",
  },
  TEXT_P_BOLD: {
    fontSize: "0.875rem",
    color: "#333333",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  BUTTON_WRAPPER: {
    display: "inline-flex",
    alignItems: "center",
    fontSize: "1rem",
    fontWeight: "600",
    color: "#333333",
  },
};

export default styles;
