const styles = {
    INPUT_TEXT_CONTAINER: {
        borderRadius: '10px',
        backgroundColor: 'var(--LIGHTGREY)',
        color: 'var(--BLACK)',
    },
    INPUT_TEXT: {
        backgroundColor: 'var(--LIGHTGREY)',
        color: 'var(--BLACK)',
        width: '100%',
        fontSize: '12px',
    },
};

export default styles