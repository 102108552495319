import React, { useEffect, useState } from "react";
import styles from "./styles";
import Navbar from "@Components/navbar";
import ScheduledPostTable from "./components/ScheduledPostsTable";
import { useNavigate } from "react-router-dom";
import { useCreateBaseSchedulePostMutation, useLazyFetchAllScheduledPostsQuery, useDeleteScheduledPostMutation } from "./apiSlice";
import { ROUTES } from "@Constants/routes";
import Loader from "@Components/Loader";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@Components/shadcn/ui/pagination"
import ReactModal from "react-modal";
import { displayToast } from "@Utils/toast";

const ScheduledPostList = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState()
  const [pageLimit, setPageLimit] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [deleteScheduledPostModal, setDeleteScheduledPostModal] = useState(false)
  const [schedulePostId, setSchedulePostId] = useState("")

  const [
    createBaseSchedulePost,
    { data: schedulePostData, error: schedulePostError, isLoading: isLoading },
  ] = useCreateBaseSchedulePostMutation();

  const [
    fetchAllScheduledPosts,
    { data: postData, isLoading: postLoading, error: postErr  }
  ] = useLazyFetchAllScheduledPostsQuery()

  const [
    deleteScheduledPost,
    { data: deletePostData, isLoading: isDeleteing, error: deletePostError}
  ] = useDeleteScheduledPostMutation()

  const schedulePostButtonClick = () => {
    createBaseSchedulePost({});
  };

  useEffect(() => {
    if (schedulePostData) {
      console.log(schedulePostData?.id);
      navigate(
        ROUTES.EDIT_SCHEDULED_POST.replace(
          ":schedule_post_id",
          schedulePostData?.id
        ) + "?edit=false"
      );
    }
  }, [schedulePostData, schedulePostError]);

  useEffect(() => {
    fetchAllScheduledPosts({page: pageNumber, pageLimit: pageLimit})
  },[])

  useEffect(() => {
    if(postData){
      setPosts(postData?.posts?.posts)
    }

    if(postErr){
      console.log(postErr);
    }
  }, [postData])

  useEffect(() => {
    fetchAllScheduledPosts({page: pageNumber, pageLimit: pageLimit})
  },[pageLimit, pageNumber, deletePostData])

  useEffect(() => {
    if(deletePostData){
      displayToast('success', deletePostData?.message || "Scheduled post deleted")
    }

    if(deletePostError){
      displayToast('error', deletePostError?.message || "Error deleting the scheduled post")
    }
  }, [deletePostData, deletePostError])

  const handlePageLimitChange = (e: any) => {
    if (e?.target?.name == 'pageLimit') {
      setPageNumber(1)
      setPageLimit(e?.target?.value)
    }
  }

  const handlePageNumberChange = (type: number) => {
    
    if (type === -1) setPageNumber(pageNumber - 1)
    if (type === 1) setPageNumber(pageNumber + 1)
  }

  const closeDeleteModal = () => {
    setDeleteScheduledPostModal(!deleteScheduledPostModal)
  }

  const openDeleteModal = (doc_id:string) => {
    setSchedulePostId(doc_id)
    setDeleteScheduledPostModal(!deleteScheduledPostModal)
  }

  const deleteBtnClick = (e:any) => {
    deleteScheduledPost(schedulePostId)
    setDeleteScheduledPostModal(!deleteScheduledPostModal)
  }

  const refreshScheduledPosts = () => {
    fetchAllScheduledPosts({page: pageNumber, pageLimit: pageLimit})
  }

  return (
    <div style={styles.BACKGROUND}>
      <div>
        <Navbar />
        {isLoading ? (
          <Loader isLoading={true} />
        ) : (
          <div>
            <div className="mt-[4rem] px-[3rem] flex justify-between items-center">
              <div className="flex items-center gap-[4rem]">
                <div className="flex gap-4 items-center">
                  <p className="text-2xl font-bold">Scheduled Posts</p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <div
                  className="flex flex-col items-center gap-4"
                  onClick={schedulePostButtonClick}
                >
                  <button style={styles.ADD_BUTTON}>Schedule Post</button>
                </div>
              </div>
            </div>
            <div className="mt-[3rem] px-[3rem]">
              {postLoading ? <Loader isLoading={true} /> : <ScheduledPostTable data={postData?.posts?.posts} openModal={openDeleteModal} fetchPosts={refreshScheduledPosts} />}
            </div>
            <DeleteSchedulePostModal isModalOpen={deleteScheduledPostModal} onClose={closeDeleteModal} deletePost={deleteBtnClick} scheduledPostId={schedulePostId} />
            <div className="mt-3">
        <Pagination>
          <PaginationContent>
            {pageNumber == 1
            ? <></> :
              <PaginationItem onClick={(e) => handlePageNumberChange(-1)}>
              <PaginationPrevious />
            </PaginationItem>
            }

            <PaginationItem>
              <PaginationLink isActive>{pageNumber}</PaginationLink>
            </PaginationItem>

            { pageLimit*pageNumber < postData?.totalCount  && 
              <PaginationItem onClick={(e) => handlePageNumberChange(1)}>
                <PaginationNext />
              </PaginationItem>
            }
          </PaginationContent>
          <div className="flex items-center ml-5">
            <label>Page length:</label>
            <select className="outline-none" name="pageLimit" onChange={handlePageLimitChange} value={pageLimit}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </Pagination>
      </div>
          </div>
        )}
      </div>
    </div>
  );
};

interface DeleteSchedulePostModalProps{
  isModalOpen: boolean
  onClose: () => void
  deletePost: (e:any) => void
  scheduledPostId: string
}

const DeleteSchedulePostModal = (props: DeleteSchedulePostModalProps) => {
  return(
    <ReactModal
      isOpen={props.isModalOpen}
      onRequestClose={props.onClose}
      style={
        { overlay: {}, content: styles.deleteContactModalContent }
      }
      portalClassName={"ReactModalPortal"}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      role={"dialog"}
      preventScroll={false} 
    >
      <>
        <div className="pb-[.5rem] border-b-2 border-slate-300" style={styles.modalHeader}>
          <h3>Confirm</h3>
        </div>
        <div className="py-[1rem]" style={styles.modalContent}>
          <p>Do you want to remove this post?</p>
          <div className="flex justify-end mt-[2rem]">
            <button
            className="px-6 py-1 border-2 border-solid rounded border-green-500 mx-2"
            style={styles.greenButton}
              onClick={props.onClose}>
              Cancel
            </button>
            <button className="px-6 py-1 border-2 border-solid rounded" style={styles.redButton} onClick={props.deletePost}>Delete</button>
          </div>
        </div>
      </>
    </ReactModal>
  )
}

export default ScheduledPostList;
