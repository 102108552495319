import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import Loader from "@Components/Loader";
import { Handle, Position, useNodeId } from "reactflow";
import styles from "./styles";
import Select from "react-select";
import { useGetAllIGPostsMutation } from "./apiSlice";
import { RootState, useAppSelector, useAppDispatch } from "@Store/store";
import {updateNodeData} from '@Store/nodeReducer'

interface SelectPostModalInterface {
  isModalOpen: boolean;
  selectPostModalOnClose: () => void;
}

const StartNode = (props:any) => {
  const [automationPost, setAutomationPost] = useState<null | any>(null);
  const [selectPostnModal, setSelectPostModal] = useState(false);
  const [triggerType, setTriggerType] = useState('')
  const [automationIncludeWords, setautomationIncludeWords] = useState<
    String[]
  >([]);
  const [automationExcludeWords, setautomationExcludeWords] = useState<
    String[]
  >([]);
  const [includeTrigger, setIncludeTrigger] = useState("");
  const [excludeTrigger, setExcludeTrigger] = useState("");

  const nodeId = useNodeId()
  const dispatch = useAppDispatch()

  const memoizedNodeData = React.useMemo(() => ({
    includeWords: automationIncludeWords,
    excludeWords: automationExcludeWords,
    automationPost: automationPost,
  }), [automationIncludeWords, automationExcludeWords, automationPost]);



  useEffect(() => {
    if(props?.data){
      setautomationIncludeWords(props?.data?.includeWords || [])
      setautomationExcludeWords(props?.data?.excludeWords || [])
      setAutomationPost(props?.data?.automationPost)
    }

    
  }, [])

  useEffect(() => {
    console.log(props?.selected)
  }, [props?.selected])
  
  

  useEffect(() => {
    if(nodeId){
        dispatch(updateNodeData({id:nodeId, data:memoizedNodeData}))
    }
  }, [memoizedNodeData])

  const addAutomationTriggerWord = (
    trigger_type: string,
    trigger_word: string
  ) => {
    if (trigger_word.length === 0) return;
    if (trigger_type === "include") {
      setautomationIncludeWords((prevWords) => [
        ...prevWords, trigger_word
      ]);
      setIncludeTrigger("");
    }

    if (trigger_type === "exclude") {
      setautomationExcludeWords((prevWords) => [
        ...prevWords, trigger_word
      ]);
      setExcludeTrigger("");
    }
  };

  const deleteWord = (trigger_type: string, index: number) => {
    if (trigger_type === "include") {
      const new_include_words = [...automationIncludeWords]
      new_include_words.splice(index,1)
      setautomationIncludeWords(new_include_words);
    } else if (trigger_type === "exclude") {
      const new_exclude_words = [...automationExcludeWords]
      new_exclude_words.splice(index, 1)
      setautomationExcludeWords(new_exclude_words);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    if (e?.target?.name == "includeTrigger") setIncludeTrigger(e.target.value);
    if (e?.target?.name == "excludeTrigger") setExcludeTrigger(e.target.value);
  };

  const { user } = useAppSelector((state: RootState) => state.profile);

  let [
    getAllIGPosts,
    { data: IGPostsData, error: IGPostsError, isLoading: isIGPostsLoading },
  ] = useGetAllIGPostsMutation();

  const closeSelectPostModal = () => {
    setSelectPostModal(!selectPostnModal);
  };

  const selectIGPostBtnClick = () => {
    if (user?.ig_ids.length === 1) getAllIGPosts({ ig_id: user?.ig_ids[0]?.igsid });

    setSelectPostModal(true);
  };

  const options = [
    { value: "post", label: "Post", disabled: false },
    { value: "stories", label: "Stories", disabled: true },
    { value: "live", label: "Live", disabled: true },
  ];

  const TriggerWord = ({ word, onDelete}: any) => {
    return (
      <div className="inline-flex items-center justify-center px-2 py-1 rounded-2xl my-1 mx-1 bg-[#e7eff0]">
        <span>{word}</span>
        <button
          className="ml-2 text-red-500 hover:text-red-700"
          onClick={(e) => onDelete()}
        >
          &times;
        </button>
      </div>
    );
  };

  const SelectPostModal = (props: SelectPostModalInterface) => {
    const selectPostForAutomation = (post_data: any) => {
      setAutomationPost(post_data);
      props.selectPostModalOnClose();
    };

    return (
      <ReactModal
        isOpen={props.isModalOpen}
        onRequestClose={props.selectPostModalOnClose}
        style={{ overlay: {}, content: styles.deleteContactModalContent }}
        portalClassName={"ReactModalPortal"}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        role={"dialog"}
        preventScroll={false}
        ariaHideApp={false}
      >
        <>
          <div
            className="pb-[.5rem] border-b-2 border-slate-300"
            style={styles.modalHeader}
          >
            <h3>Select Post for Automation</h3>
          </div>
          <div className="py-[1rem]" style={styles.modalContent}>
            {isIGPostsLoading ? (
              <Loader isLoading={true} />
            ) : (
              <div className="grid grid-cols-3 gap-3">
                {IGPostsData?.data?.data.length > 0 ? (
                  IGPostsData?.data?.data.map((post: any, index: number) => {
                    return (
                      <div
                        className="w-[100%] aspect-square border-2 rounded"
                        onClick={(e) => selectPostForAutomation(post)}
                        key={index}
                      >
                        <img
                          className="inline w-full h-full object-cover"
                          src={post.thumbnail_url || post.media_url}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className="col-span-3 flex items-center justify-center mt-[50%]">
                    <p className="text-2xl">No posts found</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      </ReactModal>
    );
  };

  return (
    <div
      style={styles.CONTAINER}
      className="p-4 br-5 border-[1px] border-[#A1A1A1]"
    >
      <div>
        <p className="text-xl">Trigger</p>
        <hr className="mt-2" />
      </div>
      <div className="mt-[1rem] flex flex-col gap-2">
        <div className="flex items-center justify-center gap-4">
          <label>Trigger Type:</label>
          <select className="font-bold border p-1 px-2">
            {options?.map((opt, idx)=><option value={opt.value} disabled={opt.disabled}>{opt.label}</option>)}
          </select>
        </div>
        <div className="flex items-center justify-center">
          {automationPost ? (
            <div className="w-[100%] shadow-2xl mx-auto p-2 border-2 border-solid rounded  border-[#23a455] ">
              <div className="h-[300px]">
                <img
                  className="h-full object-cover w-full rounded"
                  src={
                    automationPost?.thumbnail_url || automationPost?.media_url
                  }
                />
              </div>
              <p
                className="mt-2 text-[#646966]"
                style={styles.selectedPostCaption}
              >
                {automationPost && automationPost?.caption?.replace("\n", " ").substring(0, 25)}...
              </p>
              <button
                className="px-6 w-full py-1 border-2 border-solid rounded mx-2 border-[#646966] text-[#646966] mx-auto block my-2"
                // style={styles.selectPostButton}
                onClick={(e) => selectIGPostBtnClick()}
              >
                Change Post
              </button>
            </div>
          ) : (
            <button
              className="px-6 py-1 border-2 border-solid rounded mx-2 border-[#23a455] rounded text-[#23a455] mx-auto block my-2"
              // style={styles.selectPostButton}
              onClick={(e) => selectIGPostBtnClick()}
            >
              Select Post
            </button>
          )}
        </div>
        <div className="w-[100%] mx-auto my-5 p-2 border-2 border-solid rounded border-[#23a455] ">
          <p className="text-[#000]">Trigger Keywords</p>
          <div className="w-[100%] text-[#646966] mx-auto my-1 p-2 border-2 border-solid rounded-[10px] border-[#f2f2f2] ">
            <p>
              Comments should <span className="text-[#000]">include</span> these
              Keywords:
            </p>
            <div className="flex flex-row gap-2 border-[1px] rounded p-1 mt-2">
              <input
                style={styles.enterKeywordInput}
                className="basis-3/4 p-1"
                type="text"
                placeholder="Type the keyword"
                name="includeTrigger"
                onChange={handleChange}
                value={includeTrigger}
              />
              <button
                onClick={(e) =>
                  addAutomationTriggerWord("include", includeTrigger)
                }
                className="basis-1/4 border-2 rounded"
              >
                Add
              </button>
            </div>
            {automationIncludeWords && automationIncludeWords?.length > 0 && (
              <div className="mt-3 flex flex-wrap">
                {automationIncludeWords.map((wordObj, idx) => (
                  <TriggerWord
                    key={idx}
                    word={wordObj}
                    onDelete={() => deleteWord("include", idx)}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="w-[100%] text-[#646966] mx-auto my-1 p-2 border-2 border-solid rounded-[10px] border-[#f2f2f2] ">
            <p>
              Comments should <span className="text-[#000]">exclude</span> these
              Keywords:
            </p>
            <div className="flex flex-row gap-2 border-[1px] rounded p-1 mt-2">
              <input
                style={styles.enterKeywordInput}
                className="basis-3/4 p-1"
                type="text"
                placeholder="Type the keyword"
                name="excludeTrigger"
                onChange={handleChange}
                value={excludeTrigger}
              />
              <button
                onClick={(e) =>
                  addAutomationTriggerWord("exclude", excludeTrigger)
                }
                className={
                  "basis-1/4 border-2 rounded" +
                  " bg-[#f9f9f9] border-[#f1f1f1]"
                }
              >
                Add
              </button>
            </div>
            {automationExcludeWords && automationExcludeWords?.length > 0 && (
              <div className="mt-3 flex flex-wrap">
                {automationExcludeWords.map((wordObj, idx) => (
                  <TriggerWord
                    key={idx}
                    word={wordObj}
                    onDelete={() => deleteWord("exclude", idx)}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <SelectPostModal
        isModalOpen={selectPostnModal}
        selectPostModalOnClose={closeSelectPostModal}
      />
      <Handle
        type="source"
        position={Position.Right}
        id={nodeId + "_source"}
        style={{
          bottom: 10,
          width: "10px",
          height: "10px",
          backgroundColor: "black",
        }}
      />
    </div>
  );
};

export default StartNode;
