import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAppSelector, RootState, useAppDispatch } from '@Store/store';
import { hasAuthToken } from '@Utils/checks';
import Loader from '@Components/Loader';
import { ROUTES } from '@Constants/routes';

export const ProtectedRoute: React.FC<any> = ({ Component, props }) => {
    const { user, isUserfetched } = useAppSelector((state: RootState) => state.profile);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    useEffect(() => {

        if ((!user?.email && isUserfetched) || !hasAuthToken()) {
            navigate(ROUTES.LOGIN, { replace: true });
        } 
        else if (user?.ig_ids.length == 0) {
            navigate(ROUTES.IG_CONNECT)
        }
    }, [user, isUserfetched]);

    return <>{isUserfetched ? <Component {...props} /> : <Loader isLoading={true} />}</>;
};
