import { baseApi } from '@Services/api';
import { RequestTypes } from '@Constants/api';

const loginApis = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        connectIGLogin: builder.mutation({
            query: (payload) => ({
                url: '/v0/instagram/connect',
                method: RequestTypes.POST,
                body: payload
            })
        })
    })
});

export const { useConnectIGLoginMutation } = loginApis;
