import Loader from '@Components/Loader';
import { useGetAllIGStoriesMutation } from '@Pages/AutomationBuilder/FlowBuilderComponents/StartNode/apiSlice';
import { useGetAllIGPostsMutation } from '@Pages/EditAutomation/apiSlice';
import { RootState, useAppSelector } from '@Store/store';
import React, { useEffect, useState } from 'react'


interface StoryListComponentProps {
    updateTriggerData: (trigger_data: any) => void
    triggerData: any
}




const StoryListComponent = (props: any) => {

    const { triggerData, updateTriggerData } = props

    const { user } = useAppSelector((state: RootState) => state.profile);

    const [automationPost, setAutomationPost] = useState<any>(triggerData?.automationPost);


    useEffect(() => {
        updateTriggerData({ ...triggerData, automationPost })
    }, [automationPost])


    const updateCommentTriggerData = (data: any) => {
        updateTriggerData({ ...triggerData, ...data })
    }




    let [
        getAllIGStories,
        { data: IGStoriesData, error: IGStoriesError, isLoading: isIGStoriesLoading },
    ] = useGetAllIGStoriesMutation();

    const GetStories = ()=>{

        if (user?.ig_ids.length === 1) getAllIGStories({ ig_id: user?.ig_ids[0]?.igsid });
    }

    useEffect(() => {
        GetStories()
    }, [])





    return (
        <div>
            <div className=' border-b-[1px] pb-2'>

                <p className='text-sm my-1 text-slate-500'>Step 1</p>
                <h3 className='my-1 font-bold'>Which Post or Reel do you want to use in automation?</h3>
                <button className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20' onClick={GetStories}>Refresh</button>
                {automationPost ? <RenderAutomationPost automationPost={automationPost} /> : <RenderPosts postsData={IGStoriesData} isPostLoading={isIGStoriesLoading} setAutomationPost={setAutomationPost} />}
                {automationPost && <div className='text-center text-slate-500 p-2' onClick={() => setAutomationPost(null)}>Change Post</div>}

                {automationPost && <>
                    <p className='text-sm my-1 text-slate-500'>Step 2</p>
                    <h3 className='my-1 font-bold'>Which comment should fire the automation?</h3>
                    <RenderTriggerKeyword triggerData={triggerData} updateCommentTriggerData={updateCommentTriggerData} />
                </>}
            </div>
        </div>
    )
}


interface RenderPostsProps {
    setAutomationPost: (trigger_data: any) => void
    postsData:any
    isPostLoading:boolean
}

const RenderPosts = (props: RenderPostsProps) => {
    const { postsData, isPostLoading, setAutomationPost } = props
    return <div>

        <p className='text-sm my-1'>Specific Reel or Post</p>
        {isPostLoading ? (
            <Loader isLoading={true} />
        ) : (
                <div className="grid grid-cols-3 gap-2 max-h-[250px] overflow-y-scroll border-2">

                    {postsData?.data?.data.length > 0 ? (
                        postsData?.data?.data.map((post: any, index: number) => {
                        return (
                            <div
                                className="aspect-square border-2 rounded"
                                onClick={(e) => setAutomationPost(post)}
                                key={index}
                            >
                                <img
                                    className="inline w-full h-full object-cover"
                                    src={post.thumbnail_url || post.media_url}
                                />
                            </div>
                        );
                    })
                ) : (
                    <div className="col-span-3 flex items-center justify-center mt-[50%]">
                        <p className="text-2xl">No Stories found</p>
                    </div>
                )}
            </div>
        )}
    </div>
}



interface RenderAutomationPostProps {
    automationPost: any | null;
}

const RenderAutomationPost = (props: RenderAutomationPostProps) => {
    const {automationPost} = props

    if (automationPost == null) return <p>NUll Post Automation selected</p>
    return (
        <div className="w-[100%] shadow-2xl mx-auto p-2 border-2 border-solid rounded-lg  border-[#23a455] ">
            <p className='text-sm my-1'>Specific Reel or Post</p>
            <div className="h-[200px]">
                <img
                    className="h-full object-cover w-full rounded"
                    src={
                        automationPost?.thumbnail_url || automationPost?.media_url
                    }
                />
            </div>
            <p
                className="mt-2 text-[#646966]"
            >
                {automationPost && automationPost?.caption?.replace("\n", " ").substring(0, 25)}...
            </p>
        </div>
    )
}

const RenderTriggerKeyword = (props:any) => {

    const { updateCommentTriggerData, triggerData } = props


    const [automationIncludeWords, setautomationIncludeWords] = useState<String[]>([]);
    const [automationExcludeWords, setautomationExcludeWords] = useState<String[]>([]);
    const [includeTrigger, setIncludeTrigger] = useState("");
    const [excludeTrigger, setExcludeTrigger] = useState("");


    useEffect(() => {
        setautomationIncludeWords(triggerData?.includeWords || [])
        setautomationExcludeWords(triggerData?.excludeWords || [])
    }, [triggerData?.includeWords, triggerData?.excludeWords])

    useEffect(() => {
        updateCommentTriggerData({
            includeWords: automationIncludeWords,
            excludeWords: automationExcludeWords
        })
    }, [automationIncludeWords, automationExcludeWords])
    





    const addAutomationTriggerWord = (
        trigger_type: string,
        trigger_word: string
    ) => {
        if (trigger_word.length === 0) return;
        if (trigger_type === "include") {
            setautomationIncludeWords((prevWords) => [
                ...prevWords, trigger_word
            ]);
            setIncludeTrigger("");
        }

        if (trigger_type === "exclude") {
            setautomationExcludeWords((prevWords) => [
                ...prevWords, trigger_word
            ]);
            setExcludeTrigger("");
        }
    };

    const deleteWord = (trigger_type: string, index: number) => {
        if (trigger_type === "include") {
            const new_include_words = [...automationIncludeWords]
            new_include_words.splice(index, 1)
            setautomationIncludeWords(new_include_words);
        } else if (trigger_type === "exclude") {
            const new_exclude_words = [...automationExcludeWords]
            new_exclude_words.splice(index, 1)
            setautomationExcludeWords(new_exclude_words);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
        if (e?.target?.name == "includeTrigger") setIncludeTrigger(e.target.value);
        if (e?.target?.name == "excludeTrigger") setExcludeTrigger(e.target.value);
    };


 
    
    return <div className="text-sm w-[100%] mx-auto my-5 p-2 border-2 border-solid rounded border-[#23a455] ">
        <p className="text-[#000]">Trigger Keywords</p>
        <div className="w-[100%] text-[#646966] mx-auto my-1 p-2 border-2 border-solid rounded-[10px] border-[#f2f2f2] ">
            <p>
                Comments should <span className="text-[#000]">include</span> these
                Keywords:
            </p>
            <div className="flex flex-row gap-2 border-[1px] rounded p-1 mt-2">
                <input
                    className="w-[70%] p-1 outline-none"
                    type="text"
                    placeholder="Type the keyword"
                    name="includeTrigger"
                    onChange={handleChange}
                    value={includeTrigger}
                />
                <button
                    onClick={(e) =>
                        addAutomationTriggerWord("include", includeTrigger)
                    }
                    className="w-[30%] border-2 rounded bg-[#f9f9f9] border-[#f1f1f1]"
                >
                    Add
                </button>
            </div>
            {automationIncludeWords && automationIncludeWords?.length > 0 && (
                <div className="mt-3 flex flex-wrap">
                    {automationIncludeWords.map((wordObj, idx) => (
                        <TriggerWord
                            key={idx}
                            word={wordObj}
                            onDelete={() => deleteWord("include", idx)}
                        />
                    ))}
                </div>
            )}
        </div>
        <div className="w-[100%] text-[#646966] mx-auto my-1 p-2 border-2 border-solid rounded-[10px] border-[#f2f2f2] ">
            <p>
                Comments should <span className="text-[#000]">exclude</span> these
                Keywords:
            </p>
            <div className="flex flex-row gap-2 border-[1px] rounded p-1 mt-2">
                <input
                    className="w-[70%] p-1 outline-none"
                    type="text"
                    placeholder="Type the keyword"
                    name="excludeTrigger"
                    onChange={handleChange}
                    value={excludeTrigger}
                />
                <button
                    onClick={(e) =>
                        addAutomationTriggerWord("exclude", excludeTrigger)
                    }
                    className={
                        "w-[30%] border-2 rounded bg-[#f9f9f9] border-[#f1f1f1]"
                    }
                >
                    Add
                </button>
            </div>
            {automationExcludeWords && automationExcludeWords?.length > 0 && (
                <div className="mt-3 flex flex-wrap">
                    {automationExcludeWords.map((wordObj, idx) => (
                        <TriggerWord
                            key={idx}
                            word={wordObj}
                            onDelete={() => deleteWord("exclude", idx)}
                        />
                    ))}
                </div>
            )}
        </div>
    </div>
}

const TriggerWord = ({ word, onDelete }: any) => {
    return (
        <div className="inline-flex items-center justify-center px-2 py-1 rounded-2xl my-1 mx-1 bg-[#e7eff0]">
            <span>{word}</span>
            <button
                className="ml-2 text-red-500 hover:text-red-700"
                onClick={(e) => onDelete()}
            >
                &times;
            </button>
        </div>
    );
};

export default StoryListComponent