import { nanoid } from '@reduxjs/toolkit';
import React from 'react'
import { Handle, MarkerType, Position, useNodeId, useReactFlow } from 'reactflow'

const EdgeDropNode = (props:any) => {

  const nodeId = useNodeId();
  
  const { screenToFlowPosition } = useReactFlow();

  const { setNodeData, setEdgesData, NodeData, EdgesData, source, sourceHandle, show } = props?.data


  const handleSendMessageClick = () => {
    const id = `IG#sendMessage#` + nanoid()
    let payload = {
      id,
      type: "sendMessage",
      data: [],
      position: { x: props?.xPos, y: props?.yPos },
    };

    const allNodes = [...NodeData, payload];
    setNodeData(allNodes)

    const markerEnd = {
      type: MarkerType.ArrowClosed,
      width: 40,
      height: 40
    }
    const allEdges = [...EdgesData, { id, source, sourceHandle, target: id, markerEnd}]
    const new_allEdges = allEdges.filter((edge: any) => !edge.id.includes("TriggerEdgeDropNode"))
    setEdgesData(new_allEdges)
  };

  const handleButtonText = () => {
    const id = `IG#buttonText#` + nanoid()
    let payload = {
      id,
      type: "buttonTextNode",
      data: [],
      position: { x: props?.xPos, y: props?.yPos },
    };
    const allNodes = [...NodeData, payload];
    setNodeData(allNodes);
    const markerEnd = {
      type: MarkerType.ArrowClosed,
      width: 40,
      height: 40
    }
    const allEdges = [...EdgesData, { id, source, sourceHandle, target: id, markerEnd }]
    const new_allEdges = allEdges.filter((edge: any) => !edge.id.includes("TriggerEdgeDropNode"))
    setEdgesData(new_allEdges)
  };

  const handleGallery = () => {
    const id = `IG#gallery#` + nanoid()
    let payload = {
      id,
      type: "gallery",
      data: [
        {
          image_url: "",
          title: "",
          subtitle: "",
          buttons: [],
        },
      ],
      position: { x: props?.xPos, y: props?.yPos },
    };
    const allNodes = [...NodeData, payload];
    setNodeData(allNodes);
    const markerEnd = {
      type: MarkerType.ArrowClosed,
      width: 40,
      height: 40
    }
    const allEdges = [...EdgesData, { id, source, sourceHandle, target: id, markerEnd }]
    const new_allEdges = allEdges.filter((edge: any) => !edge.id.includes("TriggerEdgeDropNode"))
    setEdgesData(new_allEdges)
  }
  const handleConditionalNode = () => {
    const id = `system#conditional#` + nanoid()

    let payload = {
      id,
      type: "conditionalNode",
      data: [
        {
          image_url: "",
          title: "",
          subtitle: "",
          buttons: [],
        },
      ],
      position: { x: props?.xPos, y: props?.yPos },
    };
    const allNodes = [...NodeData, payload];
    setNodeData(allNodes);
    const markerEnd = {
      type: MarkerType.ArrowClosed,
      width: 40,
      height: 40
    }
    const allEdges = [...EdgesData, { id, source, sourceHandle, target: id, markerEnd }]
    const new_allEdges = allEdges.filter((edge: any) => !edge.id.includes("TriggerEdgeDropNode"))
    setEdgesData(new_allEdges)
  }


  const handleReplyComment = () => {
    const id = `IG#replyComment#` + nanoid()
    let payload = {
      id,
      type: "replyComment",
      data: [],
      position: { x: props?.xPos, y: props?.yPos },
    };
    const allNodes = [...NodeData, payload];
    setNodeData(allNodes);
    const markerEnd = {
      type: MarkerType.ArrowClosed,
      width: 40,
      height: 40
    }
    const allEdges = [...EdgesData, { id, source, sourceHandle, target: id, markerEnd }]
    const new_allEdges = allEdges.filter((edge: any) => !edge.id.includes("TriggerEdgeDropNode"))
    setEdgesData(new_allEdges)
  }


  return (
    <div className='w-[150px] bg-slate-200 text-md p-2 text-[#0084ff]'>
      <Handle
        id={`EdgeDropNode#${nodeId}`}
        type='target'
        position={Position.Left}
        style={{
            top: "10%",
            width: "2px",
            height: "2px",
            opacity:0
        }}
      />
        {show.includes("reply") && <p className='hover:text-black hover:cursor-pointer' onClick={(e) => handleReplyComment()}>Reply Comment</p>}

      {show.includes("sendMessage") && <p className='hover:text-black hover:cursor-pointer' onClick={(e) => handleSendMessageClick()}>Text Message</p> }

      {show.includes("buttonText") && <p className='hover:text-black hover:cursor-pointer' onClick={(e) => handleButtonText()}>Button Text</p> }

      {show.includes("card") && <p className='hover:text-black hover:cursor-pointer' onClick={(e) => handleGallery()}>Card</p> }

      {show.includes("conditional") && <p className='hover:text-black hover:cursor-pointer' onClick={(e) => handleConditionalNode()}>Conditional Node</p> }

    </div>
  )
}

export default EdgeDropNode