import React from "react";
import { Link } from "react-router-dom";
import { FBWhite, FbLogoPNG, LPLogoWName, Logo1024PNG, Selfie, Selfie2 } from "../../assets/index";
import SignupForm from "@Components/SignupForm";
import styles from "./styles";

const Signup2 = () => {
  return (
    <div className="grid grid-cols-2">
      <div className="h-[100vh] bg-[#EAE8FD] px-[1rem] py-[1rem]">
        <div>
         <img src={LPLogoWName} className="w-[250px]" />
        </div>
        <div className="mt-[1rem]">
          <img src={Selfie2} className="w-[70%] item-center justify-center" />
          <div className="mt-[4rem]">
            <p className="font-bold text-[48px]">Connect Instagram</p>
            <p className="text-[#787781]">Follow the instruction to connect to instagram, account</p>
          </div>
        </div>
      </div>
      <div className="flex item-center justify-center  align-middle my-auto">
        {/* <div className="absolute right-10 top-10">
            <button className="border-2 border-[#7772FF] font-bold rounded-3xl p-3 text-[14px]">Get Started Free</button>
        </div> */}
        <div className="w-[70%] mt-[2rem] flex item-center justify-center shadow-lg" style={styles.SIGNUP_CONTAINER}>
            <SignupForm/>
        </div>
    </div>
    </div>
  );
};

export default Signup2;
