const styles = {
    CONTAINER: {
      width: '350px',
      backgroundColor: '#FFFFFF',
      borderRadius: '20px',
      cursor: 'default',
    },
    modalHeader: {
        fontSize: '1.3rem',
        color: "#000000d9",
        borderBottom: '2px solid #22242626'
    },
    deleteContactModalContent: {
        height: '70%',
        width: '500px',
        margin: 'auto',
        fontFamily: 'DM Sans',
        borderRadius: '20px'
    },
    modalContent: {
        fontSize: '1rem'
    },
    selectedPostCaption: {
        whiteSpace: 'pre-line'
    },
    enterKeywordInput: {
        outline: 0,
        // '&:focus-visible': {
        //     outline: '0',
        // }
    }
}

export default styles