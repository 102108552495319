// src/components/Users.js
import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, query, where, orderBy, onSnapshot } from "firebase/firestore";
import { db } from '@Services/firebase/firestore'

import styles from "./styles";
import { MagnifyingGlassCircleIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { format } from 'timeago.js/lib/format';
import _ from 'lodash';
import Loader from '@Components/Loader';

interface UsersProps {
    clientId: string,
    updateChatUser: any,
    currentChatUser: any,
}

const Users = (props: UsersProps) => {
    const [userList, setUserList] = useState<any>([]);
    const [searchUserList, setSearchUserList] = useState<any>([]);
    const [showUserList, setShowUserList] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);
    const [debouncedSearchValue, setDebouncedSearchValue] = React.useState("")

    const [searchText, setSearchText] = useState('')
    const [searchType, setSearchType] = useState('name')

    useEffect(() => {
        if (!props.clientId) return;

        const clientDocRef = collection(db, "userChats");
        const queryRef = query(
            clientDocRef,
            where('ig_id', '==', props.clientId),
            orderBy('last_interacted_timestamp', 'desc')
        );

        const unsubscribe = onSnapshot(queryRef, (snapshot) => {
            const users = snapshot.docs.map(doc => doc.data());
            setUserList(users);
            setLoading(false);
        }, (err) => {
            setError(err);
            setLoading(false);
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [props.clientId]);


    const handleSearch = (event: any) => {
        setSearchText(event.target.value);
    };

    const handleSearchType = (event: any) => {
        setSearchType(event.target.value);
    };

    useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
            setDebouncedSearchValue(searchText);
        }, 300);
        return () => clearTimeout(delayInputTimeoutId);
    }, [searchText, 300]);

    useEffect(() => {
        if (!props.clientId) return;

        const newArrayList:any = []

        userList.forEach((el:any) => {
            if (searchType == 'username') {
                if (el.username.toLowerCase().includes(searchText.toLowerCase())) {
                    newArrayList.push(el)
                }
            }

            if (searchType == 'name') {
                if (el.name.toLowerCase().includes(searchText.toLowerCase())) {
                    newArrayList.push(el)
                }
            }

        });

        setSearchUserList(newArrayList)

    }, [debouncedSearchValue])

    useEffect(() => {
      if (searchText.length != 0){
          setShowUserList(searchUserList)
      } else {
          setShowUserList(userList)
      }
    }, [userList, searchUserList])
    
    



    if (loading) {
        return <div>{props.clientId? <Loader isLoading={true}/> : "Connect IG from settings"}</div>;
    }

    if (error) {
        return <div>Error: {error?.message}</div>;
    }


    function formatTimestamp(seconds?: number): string {
        if (!seconds) return 'Invalid timestamp';

        const timestamp = new Date(seconds * 1000);
        const now = new Date();

        const isToday = (date: Date) => {
            return date.toDateString() === now.toDateString();
        };

        const isYesterday = (date: Date) => {
            const yesterday = new Date(now);
            yesterday.setDate(now.getDate() - 1);
            return date.toDateString() === yesterday.toDateString();
        };

        if (isToday(timestamp)) {
            return timestamp.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            });
        }

        if (isYesterday(timestamp)) {
            return 'Yesterday';
        }

        return timestamp.toLocaleDateString('en-GB'); // Format: DD/MM/YYYY
    }

    const renderLastUserMessage = (last_message:any)=>{
        if ((last_message?.type == "normal_text" && last_message?.sub_type == "text")){
            return last_message?.data.substring(0, 30) + (last_message?.data.length>30?"...":"")
        }
        if ((last_message?.type == "story_reply" && last_message?.sub_type == "story_reply_text")) {
            return last_message?.data.substring(0, 30) + (last_message?.data.length > 30 ? "..." : "")
        }

        if (last_message?.type == "attachments") {

            if (last_message?.sub_type == "image") return "📷 Image";
            if (last_message?.sub_type == "video") return "🎥 Video";
            if (last_message?.sub_type == "audio") return "🎤 Audio";
        }

        if (last_message?.type == "deleted_message") {
            return "Deleted Message"
        }
    }

    return (
        <div>
            <div className=' flex justify-center m-3 mx-5 gap-3 bg-[#f5f6fa] p-2 rounded-lg'>
                <div>
                    <MagnifyingGlassIcon className='size-5' />
                </div>
                <div className='grow border-r-2 pr-4'>
                    <input className='w-[100%] outline-none border-b-2 bg-transparent text-[#666666]' placeholder='Search' onChange={handleSearch}/>
                </div>
                <div>
                    <select className='outline-none bg-transparent' onChange={handleSearchType}>
                        <option value='name'>Name</option>
                        <option value='username'>Username</option>
                    </select>
                </div>
            </div>
            {showUserList.length > 0 ? showUserList.map((user: any, index: number) => {
                return (
                    <div
                        style={styles.UserChat}
                        className={"border-b userChat " + (props?.currentChatUser?.ig_user_id == user?.ig_user_id ? "bg-[#887df2] text-white" : "text-black")}
                        key={user?.ig_user_id}
                        onClick={() => props.updateChatUser(user)}
                    >
                        <img style={styles.UserChatImg} src={user.profile_pic} alt="" />
                        <div style={styles.userChatInfoSpan} className="relative userChatInfo w-full">
                            <span style={styles.userChatInfoSpan}>{user.name}</span>
                            <p className={" text-[1rem] " + (user.is_unread ? "text-[#887df2]" : " text-[#00000080]")}>{renderLastUserMessage(user?.last_message)}</p>
                            <p className={"text-[.35rem] absolute right-0 top-[0%]  italic " + (props?.currentChatUser?.ig_user_id == user?.ig_user_id ? " text-white" : "text-[#00000080]")} style={styles.userChatInfoP}>
                                {formatTimestamp(user?.last_interacted_timestamp?.seconds)}
                            </p>
                            {user.is_unread ? <div className='absolute right-0 top-[50%] h-[10px] w-[10px] rounded-full bg-[#887df2]'></div>: ""}
                        </div>
                    </div>
                )
            }) : (
                <div>No users found</div>
            )}
        </div>
    );
};

export default Users;
