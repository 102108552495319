import Navbar from "@Components/navbar";
import styles from "./styles";
import ContactTable from "./components/ContactTable/ContactTable";
import {DownloadIcon,UploadIcon} from "@radix-ui/react-icons"
import { PlayIcon, TrashPNG } from "@assets/index";
import { useGetCommentsMutation, useReplyCommentMutation } from "./apiSlice";
import { useRef, useState, useEffect } from "react";

import ReactModal from 'react-modal';
import ContactEditModal from "./components/ContactEditModal";
import { RootState, useAppSelector } from "@Store/store";
import { useGetAllIGPostsMutation } from "@Pages/EditAutomation/apiSlice";
import { ChatBubbleOvalLeftEllipsisIcon, HeartIcon } from "@heroicons/react/24/solid";
import { format } from "timeago.js";
import Loader from "@Components/Loader";

const Comments = () => {

  const [IGUser, setIGUser] = useState<any>(null)
  const [UserIG, setUserIG] = useState<any>([])
  const { user } = useAppSelector((state: RootState) => state.profile);
  const [selectedPost, setSelectedPost] = useState<any>(null)
  const [CommentData, setCommentData] = useState<any>(null)
  const [CommentIdMap, setCommentIdMap] = useState<any>({})
  const [ReplyComment, setReplyComment] = useState<any>(null)
  const [ReplyCommentText, setReplyCommentText] = useState<string>('')
  const [IGPosts, setIGPosts] = useState<any>([])
  
  
  let [
    getAllIGPosts,
    { data: IGPostsData, error: IGPostsError, isLoading: isIGPostsLoading },
  ] = useGetAllIGPostsMutation();

  const [getComments, { data: commentsData, error: commentsErr, isLoading: commentsLoading }] = useGetCommentsMutation()

  const [replyComment, { data: replyCommentData, error: replyCommentErr, isLoading: replyCommentLoading }] = useReplyCommentMutation()



  useEffect(() => {
    if (user == null) return;

    if (user?.ig_ids.length > 0) {
      setIGUser(user?.ig_ids[0]);
      setUserIG(user?.ig_ids)
    }

  }, [user])
  
  useEffect(() => {
    getAllIGPosts({ ig_id: IGUser?.igsid })
  }, [IGUser])

  useEffect(() => {
    if (IGPostsData){
      //console.log(IGPostsData);
      setIGPosts([...IGPosts, ...IGPostsData?.data?.data])
    }

    if (IGPostsError) {
      console.error(IGPostsError);
    }
  }, [IGPostsData, IGPostsError])

  
  const onPostClickHandler = (post:any) => {
    getComments(({
      media_id:post?.id,
      ig_id: IGUser?.igsid
    }))
    setSelectedPost(post)
  }


  useEffect(() => {
    if (commentsData) {
      //console.log(commentsData);

      const temp_obj:any = {}
      for (let i = 0; i < commentsData?.data.length; i++) {
        const el = commentsData?.data[i];
        temp_obj[el?.id] = el
        
      }
      const filteredComments = commentsData?.data.filter((item:any) => !item.hasOwnProperty('parent_id'));
      setCommentData(filteredComments)
      setCommentIdMap({ ...CommentIdMap, ...temp_obj})
      
    }

    if (commentsErr) {
      console.error(commentsErr);
    }
  }, [commentsData, commentsErr])


  useEffect(() => {
    if (replyCommentData) {
      const new_reply = {
        timestamp: new Date().toISOString(),  // Ensure consistent timestamp format
        id: replyCommentData?.id,
        from: {
          username: IGUser?.username,
          id: IGUser?.igsid
        },
        user: {
          id: IGUser?.instagram_id,
          profile_picture_url: IGUser?.profile_picture_url
        },
        text: `@${ReplyComment.username} ` + ReplyCommentText
      };

      let temp_comment_data = [...CommentData];

      // Check if the comment object is extensible
      if (!Object.isExtensible(temp_comment_data[ReplyComment.comment_idx])) {
        // Create a new object with the same properties if it's not extensible
        temp_comment_data[ReplyComment.comment_idx] = { ...temp_comment_data[ReplyComment.comment_idx] };
      }

      // Ensure the 'replies' property is initialized and not frozen
      if (!temp_comment_data[ReplyComment.comment_idx]?.replies) {
        temp_comment_data[ReplyComment.comment_idx].replies = { data: [] };
      } else if (!Object.isExtensible(temp_comment_data[ReplyComment.comment_idx].replies)) {
        temp_comment_data[ReplyComment.comment_idx].replies = { ...temp_comment_data[ReplyComment.comment_idx].replies };
      }

      // Ensure the 'data' property is initialized and not frozen
      if (!temp_comment_data[ReplyComment.comment_idx].replies.data) {
        temp_comment_data[ReplyComment.comment_idx].replies.data = [];
      } else if (!Object.isExtensible(temp_comment_data[ReplyComment.comment_idx].replies.data)) {
        temp_comment_data[ReplyComment.comment_idx].replies.data = [...temp_comment_data[ReplyComment.comment_idx].replies.data];
      }

      // Copy the current 'replies.data' array to a new array
      let repliesData = [...temp_comment_data[ReplyComment.comment_idx].replies.data];

      // Push the new reply into the new array
      repliesData.push(new_reply);

      // Assign the modified array back to 'temp_comment_data'
      temp_comment_data[ReplyComment.comment_idx].replies.data = repliesData;
      
      const temp_id_map = { ...CommentIdMap }
      temp_id_map[replyCommentData?.id] = new_reply
      setCommentIdMap(temp_id_map)

      // .push(new_reply)
      setCommentData(temp_comment_data)

      setReplyCommentText('')


    }

    if (replyCommentErr) {
      console.error(IGPostsError);
    }
  }, [replyCommentData, replyCommentErr])



  const ReplyCommentHandle = (comment_id:string) => {
    replyComment({ comment_id, reply_text: `@${ReplyComment.username} `+ReplyCommentText, igs_id: IGUser?.igsid })
  }


  const ReplyComponent = () => {
    return (
      <div className="ml-[30px] my-2 flex  gap-2 items-center " >
        <div className="flex justify-center text-xl bg-[#b8b8b8] font-bold text-white rounded-full w-[30px] h-[30px]">
          <img className="w-full rounded-full h-full object-cover" src={UserIG[0].profile_picture_url} />
        </div>
        <div className="">
          <div className={`flex gap-3 border-[1px] rounded-full p-2 px-4 border-[#2c4bff] shadow-[0px_0px_0px_3px_#abb7ff] shadow-[#abb7ff] items-center ${replyCommentLoading ? " bg-[#a4a4a480]" : ''}`}>
            <span className="text-slate-400 text-sm font-medium">@{ReplyComment.username}</span>
            <input className="bg-transparent w-full outline-none text-sm font-normal" name="replyCommentText" onChange={(e) => setReplyCommentText(e.target.value)} placeholder="Type Comment here" value={ReplyCommentText} />
            <button className="text-xs w-[150px]" disabled={replyCommentLoading} onClick={(e) => ReplyCommentHandle(ReplyComment?.comment_id)}>Send Reply</button>
          </div>
        </div>
      </div>
    )
  }
  


  
  return (
    <div className="h-screen flex flex-col">
      <Navbar />
      <div className="flex flex-grow overflow-hidden">
        <div className="w-1/3 h-full overflow-y-auto border-r-2 rounded border-gray-300 p-2">
          {/* <Users currentChatUser={chatUser} updateChatUser={setChatUser} clientId={ClientId} /> */}
          <p className="font-bold">Instagram Account</p>
          <div className="m-2">
            <select className="border p-1 px-2 italic">
              {UserIG.map((el: any, idx: number) => {
                return <option value={el.igsid}>@{el?.username}</option>
              })}
            </select>
          </div>
          <div className="mx-auto">
            <div className="flex flex-wrap gap-2 justify-center">
              {isIGPostsLoading ? <Loader isLoading={true} /> : <></>}
              {!isIGPostsLoading && IGPosts?.map((el: any, idx: number) => {
                return (
                  <div onClick={(e)=>onPostClickHandler(el)} className="relative h-[129px] w-[129px]">
                    <img src={el.media_url} className="rounded-lg h-full w-full object-cover brightness-50 hover:cursor-pointer hover:brightness-100" />
                    <div className="absolute bottom-[5px] right-[5px] flex bg-red-500 p-1 gap-1 text-[12px] text-white rounded-lg">
                      {el?.comments_count}
                      <ChatBubbleOvalLeftEllipsisIcon className={`size-4 text-[#fff]`} /> 
                      </div>
                  </div>)
              })}
            </div>

          </div>
          

        </div>
        <div className="w-2/3 h-full  overflow-y-auto">
          <div className="w-[80%] mx-auto mt-2">
            {selectedPost && 
              <div className="flex border-2  p-3 gap-3">
                <div className="hover:cursor-pointer hover:brightness-50 w-[129px] h-[129px]" onClick={(e) => window.open(selectedPost?.permalink, "_blank", "noreferrer")}>
                  <img className="rounded-lg h-full w-full object-cover" src={selectedPost?.media_url} />
                </div>
                <div>
                  <div className="flex gap-1">
                    <ChatBubbleOvalLeftEllipsisIcon className={`size-6 text-[#bbb]`} /> <p>{selectedPost?.comments_count}</p>
                    <HeartIcon className={`ml-2 size-6 text-[#bbb]`} /> <p>{selectedPost?.like_count}</p>
                  </div>
                  <p className="text-sm">{format(new Date(selectedPost?.timestamp)) }</p>
                </div>

              </div>
            }

            {!commentsLoading && CommentData?.map((el:any, idx:number)=>{
              // if (Object.keys(el).includes("parent_id")) return;

              return (<div className="my-5 border-2 p-2 rounded">
                <div className="flex  gap-2">

                  <div className="flex justify-center text-xl bg-[#b8b8b8] font-bold text-white rounded-full w-[30px] h-[30px]">
                    {el?.user?.profile_picture_url ? <img className="w-full rounded-full h-full object-cover" src={el?.user?.profile_picture_url} /> : <div className="p-2 self-center">{el?.from?.username.charAt(0).toUpperCase()}</div>}
                  </div>
                  <div>
                    <div className="text-sm">
                      <span className="font-bold ">{el?.from?.username}</span> {el?.text}
                    </div>
                    <div>
                      <span className="text-xs font-light">{format(new Date(el?.timestamp))}</span>
                      <span className="text-xs ml-3 font-medium hover:cursor-pointer" onClick={(e) => setReplyComment({ comment_idx: idx, comment_id: el?.id, username: el?.from?.username })}>Reply</span>
                    </div>
                  </div>
                </div>
                {el?.replies?.data.map((rep: any, rep_idx: number) => {
                  return (
                    <div className="ml-[30px] my-2 flex  gap-2">
                      <div className="flex justify-center text-xl bg-[#b8b8b8] font-bold text-white rounded-full w-[30px] h-[30px]">
                        {CommentIdMap[rep?.id]?.user?.profile_picture_url ? <img className="w-full rounded-full h-full object-cover" src={CommentIdMap[rep?.id]?.user?.profile_picture_url} /> : <div className="p-2 self-center">{CommentIdMap[rep?.id]?.from?.username.charAt(0).toUpperCase()}</div>}
                      </div>
                      <div>
                        <div className="text-sm">
                          <span className="font-bold ">{CommentIdMap[rep?.id]?.from?.username}</span> {rep?.text}
                        </div>
                        <div>
                          <span className="text-xs font-light">{format(new Date(CommentIdMap[rep?.id]?.timestamp))}</span>
                          {UserIG[0]?.username != CommentIdMap[rep?.id]?.from?.username ?
                            <span className="text-xs ml-3 font-medium" onClick={(e) => {
                              setReplyComment({ comment_idx: idx, comment_id: rep?.id, username: CommentIdMap[rep?.id]?.from?.username})
                              }}>Reply</span> : <></>}
                        </div>
                      </div>
                    </div>
                  )
                })}
                {ReplyComment?.comment_idx == idx  && ReplyComponent()}
              </div>)
            })}

            {commentsLoading ? <Loader isLoading={true} />:<></>}
          </div>
        </div>
      </div>
    </div>
  );
};




export default Comments;
