import React, { useEffect, useState } from 'react'
import TriggerNodeSidebarComponent from './NodesComponent/TriggerNode'
import { useAppDispatch, useAppSelector } from '@Store/store'
import { updateNodeData } from '@Store/nodeReducer'

const SideBarEditor = (props: any) => {


	const { editComponent: EditComponent, isInitialised } = useAppSelector((state) => state.sidebar);

    return (
	    <div>
			{isInitialised ? <EditComponent /> : <h3 className="font-normal p-2">Automation Editor</h3>}
		</div>
		
		
    )
}

export default SideBarEditor