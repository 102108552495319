import { baseApi } from '@Services/api';
import { RequestTypes } from '@Constants/api';

const editSchedulePostApis = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        schedulePost: builder.mutation({
            query: (payload) => ({
                url: '/v0/schedule_post',
                method: RequestTypes.POST,
                body: payload
            })
        }),
        getScheduledPost: builder.query({
            query:(payload) => ({
                url: `/v0/scheduled_post/${payload}`,
                method: RequestTypes.GET,
            })
        }),
        updateScheduledPost: builder.mutation({
            query: (payload) => ({
                url: '/v0/scheduled_post/update',
                method: RequestTypes.POST,
                body: payload
            })
        })
    })
});

export const { useSchedulePostMutation, useLazyGetScheduledPostQuery, useUpdateScheduledPostMutation } = editSchedulePostApis;
