// @ts-nocheck
import React, { useEffect, useState } from 'react'
import styles from './style'
import { Handle, Position, NodeProps, Node, useNodeId, NodeToolbar, useReactFlow } from "reactflow"

import {DeleteBin} from '../../../../assets/index'

import TextInput from './Reusables/TextInput'
import AudioInput from './Reusables/AudioInput'
import ImageInput from './Reusables/ImageInput'
import VideoInput from './Reusables/VideoInput'
import { nanoid } from '@reduxjs/toolkit'
import {useAppDispatch} from "@Store/store"
import {updateNodeData} from "@Store/nodeReducer"
import InstagramHeader from '../InstagramHeader'
import { TrashIcon } from '@heroicons/react/24/solid'

type NodeData = {
  key:string,
  data:string
}

const ElementMap = {
  text: TextInput,
  audio: AudioInput,
  image: ImageInput,
  video: VideoInput,
};

const SendMessage = (props:NodeProps) => {
    const [sendMessageComponents, setSendMessageComponents] = useState<any>([])
    const [nodeData, setNodeData] = useState<NodeData[]>([])

    const nodeId = useNodeId()
    const reactflow = useReactFlow()

    const dispatch = useAppDispatch()

    React.useEffect(() => {
      if (nodeId) {
        setSendMessageComponents(props.data)
        dispatch(updateNodeData({ id: nodeId, data: props.data }))
      }
    }, [])

    // useEffect(() => {
    //   dispatch(updateNodeData({ id: nodeId || '', data: [] }))
    // }, [])

  const deleteNode = (e) => {
    if (nodeId) {
      reactflow.deleteElements({ nodes: [{ id: nodeId }] })
    }
  }
    

    const components = [
        {
            name: 'Text',
            id: 'text',
            component: 'text'
        },
        // {
        //     name: 'Audio',
        //     id: 'audio',
        //     component: 'audio'
        // },
        // {
        //     name: 'Image',
        //     id: 'image',
        //     component: 'image'
        // },
        // {
        //     name: 'Video',
        //     id: 'video',
        //     component: 'video'
        // },
    ] 

  const onComponentTypeClick = (component_type: string, innerElementId: any) => {
    const component_data = {
      id: `${component_type}_${nanoid()}`,
      type: component_type,
      data: []
    }
    const newComponents = [...sendMessageComponents, component_data]
    setSendMessageComponents(newComponents)
    }

    const deleteInnerComponent = (idx: number) => {
      const newComponents = [...sendMessageComponents];
      newComponents.splice(idx, 1);
      setSendMessageComponents(newComponents);
      dispatch(updateNodeData({ id: nodeId, data: newComponents }))
    }

    return (
        <div style={styles.CONTAINER} className="p-4 br-5 border-[1px] border-[#A1A1A1]">
        <NodeToolbar
          isVisible={true}
          position={Position.Top}
        >
          <button className='bg-red-100 p-1 border-2 rounded-lg border-red-400' onClick={deleteNode}>
            {/* <TrashIcon className='size-5'/> */}
            <DeleteBin width='15px' />
          </button>
        </NodeToolbar>
          <div>
            <InstagramHeader header={"Send Message"} />
          </div>
          <div>
            <Handle
              type="target"
              id={nodeId+"_target"}
              position={Position.Left}
              style={{
                top: 30,
                width: "10px",
                height: "10px",
                left: -5,
                backgroundColor: "black",
              }}
            />
            <Handle 
              type="source" 
              id={nodeId + "_source"}
              position={Position.Right} 
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: "black",
              }}
              // isValidConnection={(connection) => console.log(connection)}

            onConnect={(params) => console.log('handle onConnect', params)}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
          {sendMessageComponents && sendMessageComponents?.map((component:any, idx:number) => {
              const {type} = component
              //ignore
              const Component=ElementMap[type];
              return (<div className="" style={{ marginTop: "20px", marginBottom: "20px" }} key={idx}>
                <Component elementId={component?.id} nodeId={nodeId} data={component?.data} orderIndex={idx}/>
                <div
                  className="absolute shadow-xl"
                  style={{
                    ...styles.DELETE_ICON,
                    marginRight: "10px",
                    paddingTop: "5px",
                    marginTop: "-10px",
                  }}
                  onClick={() => deleteInnerComponent(idx)}
                >
                  <DeleteBin height={15} width={15} />
                </div>
              </div>
            )})}
          </div>
          <div style={{ marginTop: "20px" }}>
            <div className="grid grid-cols-3 gap-2">
            {sendMessageComponents && sendMessageComponents?.length <1 && components?.map((el:any, idx) => (
                <div
                  key={el.id}
                  className="text-center"
                  style={{
                    ...styles.COMPONENT,
                    ...(idx === 7 ? { gridColumn: "span 2" } : {}),
                  }}
                  onClick={() => onComponentTypeClick(el.component, nanoid())}
                >
                  {el.name}
                </div>
              ))}
            </div>
          </div>
        </div>
    );
}

export default SendMessage