import { baseApi } from '@Services/api';
import { RequestTypes } from '@Constants/api';

const contactsApis = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getComments: builder.mutation({
            query: (payload) => ({
                url: '/v0/comments',
                method: RequestTypes.POST,
                body: payload
            })
        }),
        replyComment: builder.mutation({
            query: (payload) => ({
                url: '/v0/comment/reply',
                method: RequestTypes.POST,
                body: payload
            })
        })
    })
});

export const { useGetCommentsMutation, useReplyCommentMutation } = contactsApis;
