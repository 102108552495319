import React, { useEffect, useState } from 'react'


import { BoltIcon } from '@heroicons/react/24/solid'
import { Handle, Position, useEdges, useNodeId } from 'reactflow'


import { useAppDispatch, useAppSelector } from '@Store/store'
import { updateSidebar } from '@Store/editAutomationSidebar'
import TriggerNodeSidebarComponent from '../SideBarEditor/NodesComponent/TriggerNode'
import { updateNodeData } from '@Store/nodeReducer'
import InstagramHeader from '../InstagramHeader'

const TriggerNode = (props: any) => {

    const [TriggerData, setTriggerData] = useState(props?.data)


    const dispatch = useAppDispatch()

    const nodeId = useNodeId();


    const Edges = useEdges();

    useEffect(() => {
        console.log(props?.selected)
        if (props?.selected == true) dispatch(updateSidebar({ isInitialised: true, editComponent: EditComponent  }));
        else dispatch(updateSidebar({ isInitialised: false, editComponent: ()=><></> }));
        
    }, [props?.selected])
    


    useEffect(() => {
        setTriggerData(TriggerData)
        dispatch(updateNodeData({ id: props?.id, data: TriggerData }))
    }, [TriggerData])



    const EditComponent = () =>{
        return <TriggerNodeSidebarComponent TriggerData={TriggerData} updateTriggerData={setTriggerData} />
    }


    return (
        <div className="p-4 br-5 border-[1px] border-amber-500 bg-white rounded-2xl w-[300px] shadow hover:cursor-pointer">
            <div>
                <div className='flex flex-row items-center'>
                    <div className='h-full'>
                        <BoltIcon className='size-6 text-amber-500' />
                    </div>
                    <div className='ml-2'>
                        <div className="text-xs">Trigger</div>
                        <div className="text-s font-bold">When</div>
                    </div>
                </div>
            </div>

            {TriggerData?.triggerType == "" || TriggerData?.triggerType == null&& <div className='border-dashed p-2 border-2 w-full h-30px mt-2 text-center rounded-lg '>
                Click to edit trigger
            </div>}

            {TriggerData?.triggerType == "POSTREELCOMMENT" && <div className='border-[1px] p-2 rounded-lg hover:border-[#0084ff] hover:cursor-pointer my-2'>
                <InstagramHeader header='User Comments on your post or reel' image_size={'25px'} header_text_weight='text-sm font-medium' />
            </div>}
            
            {TriggerData?.triggerType == "STORYCOMMENT" && <div className='border-[1px] p-2 rounded-lg hover:border-[#0084ff] hover:cursor-pointer my-2'>
                <InstagramHeader header='User replies to your stories' image_size={'25px'} header_text_weight='text-sm font-medium' />
            </div>}

            {TriggerData?.triggerType == "LIVECOMMENT" && <div className='border-[1px] p-2 rounded-lg hover:border-[#0084ff] hover:cursor-pointer my-2'>
                <InstagramHeader header='User comments on your live' image_size={'25px'} header_text_weight='text-sm font-medium' />
            </div>}

            

            
            <Handle
                type="source"
                position={Position.Right}
                
                id={nodeId + "_source"}
                style={{
                    bottom: 10,
                    width: "10px",
                    height: "10px",
                    backgroundColor: "black",
                }}
            />
        </div>
    )
}

export default TriggerNode