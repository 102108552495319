import { baseApi } from '@Services/api';
import { RequestTypes } from '@Constants/api';

const editAutomationFlowApis = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getFlowAutomation: builder.mutation({
            query: (payload) => ({
                url: '/v1/user/automations/details',
                method: RequestTypes.POST,
                body: payload
            })
        }),
        updateFlowAutomation: builder.mutation({
            query: (payload) => ({
                url: '/v1/user/automations/update',
                method: RequestTypes.POST,
                body: payload
            })
        }),
    })
});

export const { useGetFlowAutomationMutation, useUpdateFlowAutomationMutation } = editAutomationFlowApis;
