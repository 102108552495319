// src/components/Chat.js
import React, { useState, useEffect, useRef } from 'react';
import { collection, query, orderBy, limit, onSnapshot } from "firebase/firestore";
import { db } from '@Services/firebase/firestore';
import { format } from 'timeago.js';

import './index.css';
import { useSendMessageMutation } from './apiSlice';
import { displayToast } from '@Utils/toast';

interface ChatProps {
    clientId: string,
    userId: string
}

const Chat = ({ clientId, userId }: ChatProps) => {
    const [chatData, setChatData] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);
    const [sendMessageText, setSendMessageText] = useState('');
    const [rows, setRows] = useState(1);
    const maxRows = 5;
    const scrollBelow = useRef<any>(null);

    const [sendMessage, { data: sendMessageData, error: sendMessageErr, isLoading: sendMessageIsLoading }] = useSendMessageMutation();


    useEffect(() => {
        setLoading(true);
    }, [userId]);

    useEffect(() => {
        if (!userId) return;

        const userChatCollectionRef = collection(db, "chats", clientId, userId);
        const orderedQuery = query(userChatCollectionRef, orderBy("timestamp", "asc"), limit(100));

        const unsubscribe = onSnapshot(orderedQuery, (snapshot) => {
            const chatData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setChatData(chatData);
            setLoading(false);
        }, (err) => {
            setError(err);
            setLoading(false);
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [clientId, userId]);

    useEffect(() => {
        if (scrollBelow.current) {
            scrollBelow.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatData]);

    const renderChat = (chat: any) => {

        if (chat.is_deleted === true) {
            return (
                <p className={`whitespace-pre-line py-[7px] px-[12px] text-xs font-light italic  rounded-[18px] w-full max-w-[360px]  ${chat.is_ig_channel_message ? " bg-[#887df2] text-white " : "bg-[#efefef]"} text-black `}>
                    this message is deleted by {chat.is_ig_channel_message ? "you" : "user"}
                </p>
            );
        }

        if (chat.type === "normal_text" || chat.type === "postback") {
            return (
                <p className={`whitespace-pre-line py-[7px] px-[12px] rounded-[18px] w-full max-w-[360px] font-[400] ${chat.is_ig_channel_message ? " bg-[#887df2] text-white " : "bg-[#efefef]"} text-black `}>
                    {chat?.data}
                </p>
            );
        }
        if (chat.type === "attachments") {
            switch (chat.sub_type) {
                case "image":
                    return <img className='w-full rounded-[18px] max-w-[260px] max-h-[360px]' src={chat.data} alt={'attachment'} />;
                case "audio":
                    return <audio className='w-full rounded-[18px] max-w-[360px] max-h-[360px]' controls src={chat.data} />;
                case "video":
                    return <video className='w-full rounded-[18px] max-w-[360px] max-h-[360px]' controls src={chat.data} />;
                default:
                    return null;
            }
        }

        if (chat.type === "story_reply") {
            return <div>
                <img className='w-[100px]' src={chat.reply_to.story?.url} />
                <div className={`leading-3 whitespace-pre-line py-[7px] px-[12px]  w-full max-w-[360px] font-[400] ${chat.is_ig_channel_message ? " bg-[#887df2] text-white " : "bg-[#efefef]"} text-black `}>
                    <p>{chat?.data}</p>
                    <small className='text-[10px] '>(Story Reply)</small>
                </div>
            </div>

        }
    };

    const formattedDate = (chat: any) => {
        const timestampInSeconds = chat?.timestamp?.seconds;
        if (typeof timestampInSeconds !== 'number') {
            return 'Invalid timestamp';
        }

        const timestampInMillis = timestampInSeconds * 1000;
        const chatDate = new Date(timestampInMillis);
        const oneDayInMillis = 24 * 60 * 60 * 1000;
        const currentTime = new Date();

        const val = currentTime.getTime() - chatDate.getTime();

        return (val > oneDayInMillis) ? chatDate.toLocaleString() : format(chatDate, 'yyyy-MM-dd HH:mm:ss');
    };

    const handleChange = (event: any) => {
        const textareaLineHeight = 30;
        const { value } = event.target;
        const previousRows = event.target.rows;

        event.target.rows = 1; // reset number of rows in textarea

        const currentRows = Math.floor(event.target.scrollHeight / textareaLineHeight);

        if (currentRows === previousRows) {
            event.target.rows = currentRows;
        }

        if (currentRows >= maxRows) {
            event.target.rows = maxRows;
            event.target.scrollTop = event.target.scrollHeight;
        }

        setSendMessageText(value);
        setRows(currentRows < maxRows ? currentRows : maxRows);
    };

    const handleSendMessageBtn = () => {
        if (sendMessageText.length === 0) return;
        sendMessage({ ig_id: clientId, user_ig_id: userId, type: 'text', data: sendMessageText });
    };

    useEffect(() => {
        if (sendMessageData) {
            if (sendMessageData?.status) {
                setSendMessageText('');
                setRows(1); // Reset the textarea rows after sending the message
                displayToast("success", "Message Sent")
            }
            else {
                displayToast("error", sendMessageData?.message)
            }
        }

        if (sendMessageErr) {
            console.error(sendMessageErr);
            displayToast("error", "Some Error occured")
        }
    }, [sendMessageData, sendMessageErr]);
    

    return (
        <div className='w-[100%] h-[96%]'>
            <div className='sticky top-0'>
                {/* Add any header or sticky content here */}
            </div>
            <div className='h-[93%] overflow-y-auto text-wrap border-r-2 rounded border-gray-300'>
                {chatData.length > 0 ? (
                    <div className=' p-3'>
                        <div>
                            {chatData.map((chat: any) => {
                                console.log(chat)
                                if (chat?.type == "attachments" && chat?.sub_type == "template") return;
                                return (
                                    <div key={chat.id} className={`my-2 flex ${chat.is_ig_channel_message ? '' : 'flex-row-reverse'}`}>
                                        <div className='flex-auto w-1'></div>
                                        <div className='text-[.65rem] mx-2 flex items-end'>
                                            <span className='align-baseline inline-block'>
                                                {formattedDate(chat)}
                                            </span>
                                        </div>
                                        <div>
                                            {renderChat(chat)}
                                        </div>
                                    </div>
                                )
                                })}
                        </div>
                        <div ref={scrollBelow}></div>
                    </div>
                ) : (
                    <div className='h-full'></div>
                )}
            </div>


            <div className='sticky bottom-0 border-r-2 h-[auto] border-gray-300'>
                {userId &&
                    <div className=' bg-[#efefef]'>
                    <div className=' p-2 flex gap-2'>
                        <textarea
                            className='border w-full p-3 outline-none rounded-lg custom-scrollbar'
                            placeholder='Type message here'
                            rows={rows}
                            // rows={1}
                            value={sendMessageText}
                            onChange={handleChange}
                            style={{ lineHeight: '24px' }}
                        ></textarea>
                        <div className='flex items-end'>
                                <button disabled={sendMessageIsLoading} className='bg-[#887df2] text-white rounded-lg h-[50px] p-3 w-[150px] disabled:opacity-[.5]'
                                onClick={handleSendMessageBtn}
                            >Send Message</button>
                        </div>
                    </div>
                    <p className='text-xs pl-4'>Note: Its Takes some time to reflect the sent message on the inbox dashboard</p>
                </div>
                }
            </div>
        </div>
    );
};

export default Chat;
