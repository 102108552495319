import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import styles from './styles'
import InstagramHeader from '@Pages/AutomationBuilder/FlowBuilderComponents/InstagramHeader'
import { InstagramLogo } from '@assets/index'
import { XCircleIcon } from '@heroicons/react/24/solid'
import PostReelCommentComponent from './PostReelComment'
import StoryListComponent from './StoryComment'
import LiveCommentComponent from './LiveComment'

interface TriggerNodeSidebarComponentProps  {
    updateTriggerData: (data:any)=>void
    TriggerData: any
}


const TriggerNodeSidebarComponent = (props: TriggerNodeSidebarComponentProps) => {

    const [STModal, setSTModal] = useState(false);
    const [TempTriggerData, setTempTriggerData] = useState(props?.TriggerData)


    const switchTriggerModal = () =>{
        setSTModal(!STModal)
    }

    const selectTriggerType = (trigger_type:string) => {
        const TempTriggerData = { triggerType: trigger_type }
        console.log(TempTriggerData)
        setTempTriggerData(TempTriggerData)
        props?.updateTriggerData(TempTriggerData)
        switchTriggerModal()
    }

    const deleteTrigger = (e:any) => {
        const TempTriggerData = {}
        setTempTriggerData(TempTriggerData)
        props?.updateTriggerData(TempTriggerData)
    }

    const updateData = (data:any) => {

        const TempTriggerData = { ...data }
        setTempTriggerData(TempTriggerData)
        props?.updateTriggerData(TempTriggerData)
    }

    const RenderTriggerEditData = (trigger_data:any) => {
        if (trigger_data?.triggerType == "POSTREELCOMMENT") {
            return <PostReelCommentComponent triggerData={TempTriggerData} updateTriggerData={updateData} />
        }
        if (trigger_data?.triggerType == "STORYCOMMENT") {
            return <StoryListComponent triggerData={TempTriggerData} updateTriggerData={updateData} />
        }

        if (trigger_data?.triggerType == "LIVECOMMENT") {
            return <LiveCommentComponent triggerData={TempTriggerData} updateTriggerData={updateData} />
        }
    }
    



    return (
        <div>
            <p className='w-full bg-amber-200 text-center p-3 font-medium text-lg'>When</p>
            <div className='p-2'>
                {TempTriggerData?.triggerType == "POSTREELCOMMENT" ? <div className='border-[1px] p-2 m-2 rounded-lg hover:border-[#0084ff] my-2 hover:bg-slate-200 relative'>
                    <InstagramHeader header='User Comments on your post or reel' image_size={'25px'} header_text_weight='text-xs font-medium' />
                    <XCircleIcon className='size-6 absolute top-0 right-0 text-red-500' onClick={deleteTrigger} />
                </div> : ""}

                <div onClick={switchTriggerModal} className='text-center hover:cursor-pointer hover:text-[#0084ff] text-sm text-slate-500 border-b-[1px] pb-2'>
                    Select Trigger
                </div>

                <div>
                    {RenderTriggerEditData(TempTriggerData)}
                </div>
                <SelectTriggerModal isModalOpen={STModal} closeModal={switchTriggerModal} selectTrigger={selectTriggerType} />
            </div>
            
        </div>
    )
}



interface SelectTriggerModalProps {
    isModalOpen: boolean
    closeModal: () => void
    selectTrigger:(trigger_type:string)=> void
}

const SelectTriggerModal = (props: SelectTriggerModalProps) => {

    return (
        <ReactModal
            isOpen={props.isModalOpen}
            onRequestClose={props.closeModal}
            style={{ overlay: {}, content: styles.chooseTriggerModalContent }}
            portalClassName={"Select Trigger "}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            role={"dialog"}
            preventScroll={false}
            ariaHideApp={false}
        >
            <div>
                <p className='text-sm'>
                    Triggers
                </p>
                <p className='text-xs text-slate-400 font-light mb-2'>
                    Specific Instagram event that starts your automation.
                </p>
                <div className='border-[1px] p-2 rounded-lg hover:border-[#0084ff] hover:cursor-pointer my-2' onClick={(e)=>props.selectTrigger('POSTREELCOMMENT')}>
                    <InstagramHeader header='User Comments on your post or reel' image_size={'25px'} header_text_weight='text-sm font-medium'  />
                </div>

                <div className='border-[1px] p-2 rounded-lg hover:border-[#0084ff] hover:cursor-pointer my-2' onClick={(e) => props.selectTrigger('STORYCOMMENT')}>
                    <InstagramHeader header='User replies to your stories' image_size={'25px'} header_text_weight='text-sm font-medium'/>
                </div>

                <div className='border-[1px] p-2 rounded-lg hover:border-[#0084ff] hover:cursor-pointer my-2'
                    onClick={(e) => props.selectTrigger('LIVECOMMENT')}>
                    <InstagramHeader header='User comments on your live' image_size={'25px'} header_text_weight='text-sm font-medium' />
                </div>
            </div>
        </ReactModal>
    )
}

export default TriggerNodeSidebarComponent