import { FBBlue, Facebook, Google } from "@assets/index";
import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import OTPInput from "react-otp-input";

import styles from "./styles";
import { useResendOtpMutation, useUserSignupMutation, useVerifyOtpMutation } from "./signupSlice";
import LocalStorage from "@Utils/storage";
import { Tokens } from "@Constants/common";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@Constants/routes";
import { displayToast } from "@Utils/toast";
import { setUser } from "@Pages/Profile/profileSlice";
import { useAppDispatch } from "@Store/store";
import { CheckCircledIcon, CheckIcon } from "@radix-ui/react-icons";
import { CheckBadgeIcon, CheckCircleIcon } from "@heroicons/react/24/solid";

const SignupForm = () => {
    const navigate = useNavigate();

    // const notify = () => toast.error("Wow so easy !", { theme: 'colored'});

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string | any>("");
    const [otp, setOtp] = useState<string>("");
    const [showOtp, setShowOtp] = useState<boolean>(false);
    const [isPasswordStrong, setIsPasswordStrong] = useState(false);

    const [timeLeft, setTimeLeft] = useState(15);
    const [canResend, setCanResend] = useState(false);


    const [
        userSignup,
        {
            data: userSignupData,
            error: userSignupErr,
            isLoading: userSignupLoading,
        },
    ] = useUserSignupMutation();

    const [verifyOtp, { data: verifyOtpData, error: verifyOtpErr }] = useVerifyOtpMutation();
    const [resendOtp, { data: resendOtpData, error: resendOtpError}] = useResendOtpMutation();

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setTimeout(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);

            return () => clearTimeout(timer);
        } else {
            setCanResend(true);
        }
    }, [timeLeft]);


    const onResendOTPClick = (e:any) =>{
        resendOtp({email})
    }

    useEffect(() => {
        console.log(resendOtpData, resendOtpError)
        if (resendOtpData) {
            displayToast("success", resendOtpData?.message);
            setTimeLeft(15)
        }

        if (verifyOtpErr) {
            displayToast("error", resendOtpError?.message);
        }
    }, [resendOtpData, resendOtpError])
    


    const dispatch = useAppDispatch();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (e?.target?.name == "email") setEmail(e.target.value);
        if (e?.target?.name == "password") setPassword(e.target.value);
        if (e?.target?.name == "firstname") setFirstName(e.target.value);
        if (e?.target?.name == "lastname") setLastName(e.target.value);
        if (e?.target?.name == "phonenumber") setPhoneNumber(e.target.value);
    };

    useEffect(() => {
        const passwordStrength = CheckPasswordStrength(password);
        setIsPasswordStrong(passwordStrength);
    }, [password]);

    const onSubmitClick = (e: any) => {
        e.preventDefault();
        // notify()
        if (!isPasswordStrong) {
            displayToast('error', 'Password is not strong enough!')
            return;
        }
        if (email?.length > 0 && isPasswordStrong )
            userSignup({
                email,
                password,
                first_name: firstName,
                last_name: lastName,
                phone_number: phoneNumber,
            });

        
    };

    const handleOtp = (e: any) => {
        e.preventDefault();
        verifyOtp({ otp });
    };

    useEffect(() => {
        if (userSignupData?.status) {
            LocalStorage.setItem(
                Tokens.ACCESS_TOKEN,
                userSignupData.data.user_access_token
            );
            LocalStorage.setItem(
                Tokens.REFRESH_TOKEN,
                userSignupData.data.refresh_user_access_token
            );

            displayToast("success", "User Signup Successful");
            if (userSignupData?.user?.verified == 0) {
                setShowOtp(true);
                setTimeLeft(15)
            }
            dispatch(setUser(userSignupData.user));
            // if (userSignupData.status) navigate(ROUTES.CONNECT_FACEBOOK)
        }
        if (userSignupErr) {
            // setShowError(parseErrorMessage(verifyErr));
            displayToast("error", userSignupErr?.data?.message);
            console.error(userSignupErr?.data);
        }
    }, [userSignupData, userSignupErr]);

    useEffect(() => {
        if (verifyOtpData?.status) {
            displayToast("success", "Email Verified Successfully");
            navigate(ROUTES.IG_CONNECT);
        }

        if (verifyOtpErr) {
            // setShowError(parseErrorMessage(verifyErr));
            displayToast("error", verifyOtpErr?.data?.message);
        }
    }, [verifyOtpData, verifyOtpErr]);

    function CheckPasswordStrength(password: string) {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        if (
            password.length >= minLength &&
            hasUpperCase &&
            hasLowerCase &&
            hasNumbers &&
            hasSpecialChars
        ) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <div className="w-[100%] px-[2rem]">
            {showOtp ? (
                <>
                    <div className="relative right-0 mt-5">
                        <h2 className="font-bold text-xl text-center">
                            Verify Email
                        </h2>
                        <p className="text-sm text-center mt-2">
                            Email is sent on <span className="text-[#007bff]">{email}</span>{" "}
                            for verification
                        </p>
                        <p className="text-sm text-center mt-2">(Check your spam if you haven't received)</p>
                    </div>
                    <form className="mt-8 mb-8">
                        <div className="flex justify-center items-center text-2xl">
                            <OTPInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderSeparator={<span> </span>}
                                renderInput={(props) => <input {...props} />}
                                inputStyle={styles.OTP_INPUT}
                            />
                        </div>
                    </form>
                    <div className='mx-[1rem] text-center'>
                        <button className={`text-[12px] ${timeLeft ? '' :'text-[#007bff]'}`} disabled={timeLeft?true:false} onClick={onResendOTPClick} >Resend OTP {timeLeft ? `(${timeLeft})`:''}</button>
                </div>
                    <div className="flex justify-center items-center my-4">
                        <button
                            className="p-2 w-full"
                            onClick={handleOtp}
                            style={styles.SUBMIT_BUTTON}
                        >
                            Verify
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <div className="relative right-0 mt-5">
                        <h2 className="font-bold text-xl text-center">
                            Create your free account
                        </h2>
                    </div>

                    <form className="mt-8 mb-8">
                        <div>
                            <div className="flex items-center gap-4">
                                <input
                                    type="text"
                                    name="firstname"
                                    placeholder="First Name"
                                    className="w-[50%]"
                                    value={firstName}
                                    style={styles.INPUT_TEXT}
                                    onChange={handleChange}
                                />
                                <input
                                    type="text"
                                    name="lastname"
                                    placeholder="Last Name"
                                    className="w-[50%]"
                                    value={lastName}
                                    style={styles.INPUT_TEXT}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="mt-4">
                                <PhoneInput
                                    placeholder="Enter your phone number"
                                    defaultCountry="IN"
                                    countryCallingCodeEditable={false}
                                    international
                                    initialValueFormat="national"
                                    value={phoneNumber}
                                    className="outline-none focus:outline-none w-full"
                                    type="text"
                                    numberInputProps={{
                                        className: " outline-none focus:outline-none w-full",
                                    }}
                                    onChange={setPhoneNumber}
                                    style={styles.INPUT_TEXT}
                                />
                            </div>
                            <div className="mt-4">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email Address"
                                    className="w-[100%]"
                                    value={email}
                                    style={styles.INPUT_TEXT}
                                    onChange={handleChange}
                                />
                                {email && (
                                    <p className="text-xs text-[#5e5e5e] mt-1 ml-1 italic">
                                        email will sent on{" "}
                                        <span className="text-[#007bff]">{email}</span> for
                                        verification
                                    </p>
                                )}
                            </div>
                            <div className="mt-4">
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    className="w-[100%]"
                                    value={password}
                                    style={styles.INPUT_TEXT}
                                    autoComplete="new-password"
                                    onChange={handleChange}
                                    onFocus={(e)=>console.log(e)}
                                />
                                { password &&
                                <div className="grid grid-cols-2 gap-1 mt-1 text-xs">
                                    <div
                                        className={`flex ${password.length >= 8 ? "text-emerald-500" : ""
                                            }`}
                                    >
                                        <CheckCircledIcon className="text-sm" />
                                        Minimum 8 Character
                                    </div>
                                    <div
                                        className={`flex ${/(?=.*[a-z])(?=.*[A-Z])/.test(password)
                                                ? "text-emerald-500"
                                                : ""
                                            }`}
                                    >
                                        <CheckCircledIcon className="size-4" />
                                        Upper & Lower case
                                    </div>

                                    <div
                                        className={`flex ${/\d/.test(password) ? "text-emerald-500" : ""
                                            }`}
                                    >
                                        <CheckCircledIcon className="size-4" />
                                        One Numerical (123)
                                    </div>

                                    <div
                                        className={`flex ${/[!@#$%^&*(),.?":{}|<>]/.test(password)
                                                ? "text-emerald-500"
                                                : ""
                                            }`}
                                    >
                                        <CheckCircledIcon className="size-4" />
                                        Special Char (@#$%)
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </form>
                    <div className="mx-[1rem] text-center">
                        <p className="text-[12px] text-[#666666]">
                            By signing up you agree to the{" "}
                                <a href="https://linkplease.co/terms-and-conditions/" style={styles.TEXT_LINK}>Terms</a> and{" "}
                                <a href="https://linkplease.co/privacy-policy" style={styles.TEXT_LINK}>Privacy Policy</a>
                        </p>
                    </div>
                    <div className="flex justify-center items-center my-4 w-full">
                        <button
                            className="p-2  w-full"
                            style={styles.SUBMIT_BUTTON}
                            onClick={onSubmitClick}
                        >
                            Signup
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default SignupForm;
