import React from "react";
import { PhotoIcon } from "@heroicons/react/24/solid";
import { displayToast } from "@Utils/toast";
import { useUploadImageMutation } from "./apiSlice";
import { convertBase64Image, convertTobase64Video } from "./utils";
import Loader from "@Components/Loader";

interface UploadImageProps {
  images: string[];
  onImageChange: (url: string) => void;
  mediaType: string;
}

const UploadImage = ({
  images,
  onImageChange,
  mediaType,
}: UploadImageProps) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  let [
    uploadImage,
    { data: UploadImageData, isLoading: isUploading, error: UploadImageError },
  ] = useUploadImageMutation();

  const handleClick = () => {
    if (mediaType == "carousel" && images.length >= 10) {
      displayToast("error", "can't upload more than 10 images");
      return;
    } else if (mediaType == "reel" && images.length == 1) {
      displayToast("error", "can't upload more than one video");
      return;
    } else if (mediaType == "post" && images.length == 1) {
      displayToast("error", "can't upload more than one post");
      return;
    } else if (mediaType == "") {
      displayToast("error", "select the type of post");
      return;
    }

    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const asset =
        mediaType == "reel"
          ? await convertTobase64Video(file)
          : await convertBase64Image(file);
      const response = await uploadImage({
        image_data: asset,
        file_name: file.name,
        file_type: file.type,
      }).unwrap();
      onImageChange(response?.url);
      // const url = URL.createObjectURL(file)
      // onImageChange(url)
    }
  };
  return (
    <div
      onClick={handleClick}
      className="flex justify-center w-[6vw] h-[10vh] items-center text-center border-2 text-[#8D9EB2] rounded-lg border-dashed border-[#8D9EB2] p-3"
    >
      {isUploading ? (
        <Loader isLoading={true} />
      ) : (
        <div>
          <div className="">
            <PhotoIcon className={`size-7 mx-auto`} />
            <p>Add an {mediaType == "reel" ? "Video" : "Image"}</p>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept="image/* || video/*"
            style={{ display: "none" }}
          />
        </div>
      )}
    </div>
  );
};

export default UploadImage;
