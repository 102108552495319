import React, { useState, useRef, ChangeEvent, useEffect } from "react";
import styles from "./styles";
import { useAppSelector, useAppDispatch } from "@Store/store";
import { updateNodeData } from "@Store/nodeReducer";
import { convertBase64Image } from "../../utils";
import { useUploadAssetMutation } from "../../apiSlice";
import Loader from "@Components/Loader";

interface InputImageProps {
  inCard?: boolean;
  elementId: string;
  nodeId: any;
  data: any;
  orderIndex: number;
}

const ImageInput: React.FC<InputImageProps> = ({
  inCard = false,
  elementId,
  nodeId,
  data,
  orderIndex,
}) => {
  const [imageUrl, setImageUrl] = useState<string>("");
  const imgRef = useRef<HTMLInputElement>(null);

  let { edges, nodes } = useAppSelector((state: any) => state.node);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let tempNodeData = [...nodes[nodeId]];
    let innerNodeDataKey = tempNodeData.map((el) => {
      return el.id;
    });

    if (innerNodeDataKey.indexOf(elementId) == -1) {
      tempNodeData.push({ id: elementId, data: [], type: "image" });
      dispatch(updateNodeData({ id: nodeId, data: tempNodeData }));
    } 

    if (data != null) {
      setImageUrl(data);
    }
  }, []);

  let [uploadAsset, {data: UploadAssetData, isLoading: isUploading, error: UploadAssetError}] = useUploadAssetMutation()

  const handleFileChange = async(event: ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    event.target.value = "";
    const objectURL = URL.createObjectURL(fileObj);
    try {
      const img = await convertBase64Image(fileObj)
      const response = await uploadAsset({image_data: img, file_name: fileObj.name, file_type: fileObj.type}).unwrap();
      setImageUrl(response?.url)
      let tempNodeData = [...nodes[nodeId]];
      tempNodeData = tempNodeData.map((el: any, idx: number) => {
        if (el.id == elementId) {
          let new_data = { ...el, data: response?.url };
          return new_data;
        }
        return el;
      });
      
      dispatch(updateNodeData({ id: nodeId, data: tempNodeData }));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      style={
        inCard
          ? styles.INPUT_TEXT_CONTAINER
          : { ...styles.INPUT_TEXT_CONTAINER}
      }
      className="p-2 rounded-lg"
    >
      {isUploading ? (
        <Loader/>
      ) : imageUrl != "" && (
        <img
          src={imageUrl}
          style={styles.IN_CARD_IMAGE}
          className="object-cover"
        />
      )}

      <input
        ref={imgRef}
        className={`hidden`}
        type="file"
        accept="image/*"
        onChange={handleFileChange}
      />
      {inCard && imageUrl ? (
        <></>
      ) : (
        <button
          onClick={() => imgRef.current?.click()}
          style={styles.UPLOAD_BTN}
            className={`w-full py-2 text-center border`}
        >
          {imageUrl ? "Change Image" : "Upload Image"}
        </button>
      )}
    </div>
  );
};

export default ImageInput;
