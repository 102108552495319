import React from "react";
import {XCircleIcon} from "@heroicons/react/24/outline"

interface ImageComponentProps {
  image: string;
  mediaType: string
  idx: number
  onImageDelete: (idx: number) => void
}

const ImageComponent = ({image, mediaType, idx, onImageDelete}: ImageComponentProps) => {

  const handleClick = () => {
    onImageDelete(idx)
  }

  return (
    <div className="w-[6vw] h-[10vh]">
      <div className="absolute">
        <div className="relative left-[5rem] top-1">
          <XCircleIcon className="text-white size-6 bg-black rounded-[50%]" onClick={handleClick} />
        </div>
      </div>
      {mediaType == 'post' || mediaType == 'caraousel' ? (
         <img
         src={image}
         className="w-full h-full object-fill border-dashed border-[#8D9EB2] rounded-lg"
       />
      ): (
        <video
        src={image}
        className="w-full h-full object-fill border-dashed border-[#8D9EB2] rounded-lg"
      />
      )}
    </div>
  );
};

export default ImageComponent;
