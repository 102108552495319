import { baseApi } from "@Services/api";
import { RequestTypes } from "@Constants/api";

const sendMessageNodeApis = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        uploadAsset: builder.mutation({
            query: (payload) => {
                return {
                url: '/v0/upload',
                method: RequestTypes.POST,
                body: payload,
                headers:{
                    'Content-type': 'multipart/form-data'
                }
            }}
        })
    })
})

export const {useUploadAssetMutation} = sendMessageNodeApis