const styles = {
    CONTAINER: {
      width: '300px',
      backgroundColor: '#FFFFFF',
      borderRadius: '20px',
      cursor: 'default',
    },
    ALL_COMPONENTS: {},
    COMPONENT: {
      border: '1px dashed #999999',
      borderRadius: '5px',
      fontSize: '12px',
    },
    COMPONENT_HOVER: {
      border: '1px solid #FFFF00', // Yellow color
      backgroundColor: '#FFFF00', // Yellow color
      textAlign: 'center',
      borderRadius: '5px',
      fontSize: '12px',
      cursor: 'pointer',
    },
    DELETE_ICON: {
      right: '0',
      borderRadius: '20px',
      backgroundColor: '#FFFFFF', // White color
      cursor: 'pointer',
    },
  };
  
export default styles