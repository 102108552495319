import React, { useState, useRef, ChangeEvent, useEffect } from "react";
import styles from "./styles";
import { useAppSelector, useAppDispatch } from "@Store/store";
import { updateNodeData } from "@Store/nodeReducer";
import { convertTobase64 } from "../../utils";
import { useUploadAssetMutation } from "../../apiSlice";
import Loader from "@Components/Loader";

interface AudioInputProps {
  elementId: string;
  nodeId: any;
  data: any;
  orderIndex: number;
}

const AudioInput = ({
  nodeId,
  elementId,
  data,
  orderIndex,
}: AudioInputProps) => {
  const [videoUrl, setVideoUrl] = useState<string>("");
  const imgRef = useRef<HTMLInputElement>(null);

  let { edges, nodes } = useAppSelector((state: any) => state.node);

  useEffect(() => {
    let tempNodeData = [...nodes[nodeId]];
    let innerNodeDataKey = tempNodeData.map((el) => {
      return el.id;
    });

    if (innerNodeDataKey.indexOf(elementId) == -1) {
      tempNodeData.push({ id: elementId, data: '', type: "audio" });
      dispatch(updateNodeData({ id: nodeId, data: tempNodeData }));
    } else {
      console.log(tempNodeData);
    }

    if (data) {
      setVideoUrl(data);
    }
  }, []);

  const dispatch = useAppDispatch();

  let [uploadAsset, {data: UploadAssetData, isLoading: isUploading, error: UploadAssetError}] = useUploadAssetMutation()

  const handleFileChange = async(event: ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    // Reset file input
    if (imgRef.current) {
      imgRef.current.value = "";
    }

    // Set video URL
    const objectURL = URL.createObjectURL(fileObj);
    try {
      const audio = await convertTobase64(fileObj)
      const response = await uploadAsset({image_data: audio, file_name: fileObj.name, file_type: fileObj.type}).unwrap()

      setVideoUrl(response?.url)

      let tempNodeData = [...nodes[nodeId]];
      tempNodeData = tempNodeData.map((el: any, idx: number) => {
        if (el.id == elementId) {
          let new_data = { ...el, data: response?.url };
          return new_data;
        }
        return el;
      });
  
  
      dispatch(updateNodeData({ id: nodeId, data: tempNodeData }));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={styles.INPUT_TEXT_CONTAINER} className={`p-2 rounded-lg`}>
      {isUploading ? (<Loader isLoading={true}/>) : videoUrl!="" && <audio className="w-[100%]" src={videoUrl} autoPlay controls />}
      <input
        ref={imgRef}
        className="hidden"
        type="file"
        accept="audio/*"
        onChange={handleFileChange}
      />
      <button
        onClick={() => imgRef.current?.click()}
        style={styles.UPLOAD_BTN}
        className={`w-full py-2 text-center border`}
      >
        Upload Audio
      </button>
    </div>
  );
};

export default AudioInput;
