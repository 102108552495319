import Navbar from "@Components/navbar";
import { PlayIcon } from "@assets/index";
import styles from "./styles";
import {UploadIcon} from "@radix-ui/react-icons"
import AutomationTable from "./components/AutomationTable";
import { useCreateAutomationMutation, useDeleteAutomationMutation, useLazyGetAutomationsQuery } from "./apiSlice";
import { useEffect, useState } from "react";
import ReactModal from 'react-modal';
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@Constants/routes";


import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@Components/shadcn/ui/pagination"
import { displayToast } from "@Utils/toast";
import Loader from "@Components/Loader";


const AutomationList = () => {

  const [AutomationsData, setAutomationsData] = useState<any>({})
  
  const [getAutomation, { data: automationData, error: automationErr,isLoading: isAutomationLoading}] = useLazyGetAutomationsQuery()
  let [createNewAutomation, { data: createAutomationData, error: createAutomationError, isLoading: isCreateAutomationLoading }] = useCreateAutomationMutation()
  let [DeleteAutomation, { data: DeleteAutomationData, error: DeleteAutomationError, isLoading: isDeleteAutomationLoading }] = useDeleteAutomationMutation()

  const [editAutomationModal, setEditAutomationModal] = useState(false)
  const [EditAutomationModalData, setEditAutomationModalData] = useState({})
  const [deleteAutomationModal, setDeleteAutomationModal] = useState(false)
  const [DeleteAutomationModalData, setDeleteAutomationModalData] = useState(-1)
  const [pageLimit, setPageLimit] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [DeleteAutomationId, setDeleteAutomationId] = useState(-1)

  const navigate = useNavigate();

  const closeEditAutomationModal = () => {
    setEditAutomationModal(!editAutomationModal)
  }

  const openEditAutomationModal = (contact_index: number) => {
    setEditAutomationModalData(automationData?.data[contact_index])
    setEditAutomationModal(!editAutomationModal)
  }


  const closeDeleteAutomationModal = () => {
    setDeleteAutomationModal(!DeleteAutomationModal)
  }

  const openDeleteAutomationModal = (automation_id:number) => {
    setDeleteAutomationId(automation_id)
    setDeleteAutomationModal(!editAutomationModal)
  }

  const deleteAutomationButtonClick = (e:any) => {
    DeleteAutomation(DeleteAutomationId)
    setDeleteAutomationModal(!DeleteAutomationModal)
  }

  useEffect(() => {
    getAutomation({
      limit: pageLimit,
      page: pageNumber
    })
    
  }, [])


  useEffect(() => {
    if(automationData?.automations){
      setAutomationsData(automationData)
    }
    if(automationErr){
      console.error(automationErr)
    }
  }, [automationData])
  
  

  useEffect(() => {
    getAutomation({
      limit: pageLimit,
      page: pageNumber
    })
  }, [pageLimit, pageNumber, DeleteAutomationData])

  useEffect(() => {
    if (DeleteAutomationData) {
      displayToast('success', DeleteAutomationData?.message || 'Automation Deleted Successful')
    }

    if (DeleteAutomationError) {
      console.error(DeleteAutomationError);
      displayToast('error', DeleteAutomationError?.data?.message || 'Automation Deletion Unsuccessful')
    }
  
  }, [DeleteAutomationData, DeleteAutomationError])
  

  const addAutomationButtonClick = (e:any) => {
    createNewAutomation({})
  }

  const handlePageLimitChange = (e: any) => {
    if (e?.target?.name == 'pageLimit') {
      setPageNumber(1)
      setPageLimit(e?.target?.value)
    }
  }

  const handlePageNumberChange = (type: number) => {
    
    if (type === -1) setPageNumber(pageNumber - 1)
    if (type === 1) setPageNumber(pageNumber + 1)
  }

  useEffect(() => {
    
    if (createAutomationData) {
      navigate(ROUTES.FLOW_BUILDER.replace(':automation_doc_id', createAutomationData?.automation_id))
    }
  
    
  }, [createAutomationData, createAutomationError])

  const refreshAutomation = () => {
    getAutomation({
      limit: pageLimit,
      page: pageNumber
    })
  }
  


  return (
    <div style={styles.BACKGROUND}>
      <Navbar />
      <div className="mt-[4rem] px-[3rem] flex justify-between items-center">
        <div className="flex items-center gap-[4rem]">
          <div className="flex gap-4 items-center">
            <p className="text-2xl font-bold">Chatbots </p>
            {/* <p>({automationData?.automations.length }/{automationData?.totalCount})</p> */}
          </div>
          {/* <div className="relative">
            <input
              type="text"
              className="py-3 px-4 text-[#808080] rounded-[5px] text-[12px] font-bold border-[0.5px] border-[#A9A9A9] w-[200px] placeholder-[#D3D3D3] placeholder-font-normal"
              placeholder="Search..."
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
              }}
            >
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="gray"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          </div> */}
        </div>
        <div className="flex items-center gap-2">
            {/* <div className="flex items-center gap-2">
                <img src={PlayIcon} />
                <p className="text-[#7367F0] font-bold text-[16px]">+Watch Tutorial</p>
            </div>
            <div>
                <button className="border-2 border-[#50C878] text-[#50C878] font-bold px-4 py-2 rounded-[0.5rem]">Fallback Message</button>
            </div>
            <div>
                <button className="border-2 border-[#50C878] text-[#50C878] font-bold px-4 py-2 rounded-[0.5rem]">Chatbot Timer</button>
            </div>
            <div className="border-2 px-3 py-3 rounded-[0.5rem]">
              <UploadIcon/>
            </div> */}
          <div className="flex flex-col items-center gap-4" onClick={addAutomationButtonClick}>
                <button style={styles.ADD_BUTTON}>Add Chatbot</button>
             </div>
        </div>
      </div>
      <div className="mt-[3rem] px-[3rem]"> 
        {isAutomationLoading ? <Loader isLoading={true} /> : <AutomationTable data={AutomationsData?.automations} openDeleteAutomationModal={openDeleteAutomationModal} fetchAutomations={refreshAutomation}/>}
      </div>
      {/* <EditAutomationModal isModalOpen={editAutomationModal} editAutomationModalOnClose={closeEditAutomationModal} /> */}
      <DeleteAutomationModal isModalOpen={deleteAutomationModal} editAutomationModalOnClose={closeDeleteAutomationModal} deleteAutomation={deleteAutomationButtonClick} deleteAutomationId={DeleteAutomationId}/>
      <div className="mt-3">
        <Pagination>
          <PaginationContent>
            {pageNumber == 1
            ? <></> :
              <PaginationItem onClick={(e) => handlePageNumberChange(-1)}>
              <PaginationPrevious />
            </PaginationItem>
            }

            <PaginationItem>
              <PaginationLink isActive>{pageNumber}</PaginationLink>
            </PaginationItem>

            { pageLimit*pageNumber < automationData?.totalCount  && 
              <PaginationItem onClick={(e) => handlePageNumberChange(1)}>
                <PaginationNext />
              </PaginationItem>
            }
          </PaginationContent>
          <div className="flex items-center ml-5">
            <label>Page length:</label>
            <select className="outline-none" name="pageLimit" onChange={handlePageLimitChange} value={pageLimit}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </Pagination>
      </div>
      

    </div>
  );
};

interface EditAutomationModalInterface {
  isModalOpen: boolean;
  editAutomationModalOnClose: () => void;
}


const EditAutomationModal = (props: EditAutomationModalInterface) => {

  return (
    <ReactModal
      isOpen={props.isModalOpen}
      onRequestClose={props.editAutomationModalOnClose}
      style={
        { overlay: {}, content: styles.editContactModalContent }
      }
      portalClassName={"ReactModalPortal"}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      role={"dialog"}
      preventScroll={false} >
      Hello World
      </ReactModal>
  )
}



interface DeleteAutomationModalInterface {
  isModalOpen: boolean;
  editAutomationModalOnClose: () => void;
  deleteAutomation: (e:any) => void;
  deleteAutomationId: number
}


const DeleteAutomationModal = (props: DeleteAutomationModalInterface) => {
  

  return (
    <ReactModal
      isOpen={props.isModalOpen}
      onRequestClose={props.editAutomationModalOnClose}
      style={
        { overlay: {}, content: styles.deleteContactModalContent }
      }
      portalClassName={"ReactModalPortal"}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      role={"dialog"}
      preventScroll={false} 
      >
      <>
        <div className="pb-[.5rem] border-b-2 border-slate-300" style={styles.modalHeader}>
          <h3>Confirm</h3>
        </div>
        <div className="py-[1rem]" style={styles.modalContent}>
          <p>Do you want to remove this automation?</p>
          <div className="flex justify-end mt-[2rem]">
            <button
            className="px-6 py-1 border-2 border-solid rounded border-green-500 mx-2"
            style={styles.greenButton}
              onClick={props.editAutomationModalOnClose}>
              Cancel
            </button>
            <button className="px-6 py-1 border-2 border-solid rounded" style={styles.redButton} onClick={props.deleteAutomation}>Delete</button>
          </div>
        </div>
      </>
    </ReactModal>
  )
}

export default AutomationList;
