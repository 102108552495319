export const convertTobase64Video = async(file: File):Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
    
        fileReader.onload = () => {
          if (fileReader.result) {
            const base64 = fileReader.result.toString().split(',')[1];
            resolve(base64);
          } else {
            reject(new Error('Failed to read the file.'));
          }
        };
    
        fileReader.onerror = (error) => {
          reject(error);
        };
    });
}

export const convertBase64Image = async (imageItem: File) => {
    const base64Image = await encodeImageFileAsURL(imageItem);
  
    return base64Image;
  };
  
  const encodeImageFileAsURL = (file: any) =>
      new Promise((resolve) => {
        const image = new Image();
    
        image.crossOrigin = 'anonymous';
        image.src = URL.createObjectURL(file);
    
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
    
          canvas.height = image.naturalHeight;
          canvas.width = image.naturalWidth;
    
          ctx?.drawImage(image, 0, 0);
    
          const dataUrl = canvas.toDataURL();
    
          const encodedUrl = dataUrl.split('data:image/png;base64,')[1] || '';
    
          resolve(encodedUrl);
        };
      }
);
