import React, { useEffect, useState } from 'react'
import Navbar from '@Components/navbar'
import { db } from '@Services/firebase/firestore'
import { collection, doc, documentId, getDocs, getDoc, query, where } from "firebase/firestore";
import Chat from './components/chats';
import Users from './components/users';
import UserDetails from './components/userDetails/Index';
import { RootState, useAppSelector } from '@Store/store';





const Inbox = (props: any) => {

    const [chatUser, setChatUser] = useState<any>(null)
    const { user } = useAppSelector((state: RootState) => state.profile);
    const [ClientId, setClientId] = useState<string>('')

    useEffect(() => {
        if (user == null) return;
        
        if (user?.ig_ids.length > 0) setClientId(user?.ig_ids[0].igsid);
      
    }, [user])




    return (
        <div className="h-screen flex flex-col">
            <Navbar />
            <div className="flex flex-grow overflow-hidden">
                <div className="w-1/4 h-full overflow-y-auto border-r-2 rounded border-gray-300">
                    <Users currentChatUser={chatUser} updateChatUser={setChatUser} clientId={ClientId} />
                </div>
                <div className="w-1/2 h-full">
                    <Chat clientId={ClientId} userId={chatUser?.ig_user_id} />
                </div>
                <div className="w-1/4 h-full overflow-y-auto  rounded  shadow-xl">
                    <UserDetails user={chatUser} />
                    {/* Add more content here to enable scrolling */}
                </div>
            </div>
        </div>

    )
}

export default Inbox