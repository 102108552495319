const styles = {
    INPUT_TEXT_CONTAINER: {
        borderRadius: '10px',
        backgroundColor: '#f0f0f0',
        color: '#000000',
    },
    INPUT_TEXT: {
        backgroundColor: '#f0f0f0',
        color: '#000000',
        width: '100%',
        fontSize: '12px',
        userSelect: 'text',
    },
    UPLOAD_BTN: {
        backgroundColor: '#FFFFFF',
        borderRadius: '10px',
    },
    IN_CARD_IMAGE: {
        maxHeight: '280px',
        borderRadius: '.5rem',
        width: '100%'
    },
};

export default styles;