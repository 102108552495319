const styles = {
    UserChat: {
        "padding": "10px",
        "display": "flex",
        "alignItems": "center",
        "gap": "10px",
        "cursor": "pointer",
        '&:hover': {
            backgroundColor: '#2f2d52'
        }
    },
    UserChatImg: {
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        "object-fit": "cover"
    },
    userChatInfoSpan : {
        fontSize: "18px",
        fontWeight: "500",
    },
    userChatInfoP: {
        fontSize: "14px",
    }
}

export default styles;