import Navbar from "@Components/navbar";
import React, { ChangeEvent, useEffect, useState } from "react";
import UploadImage from "./components/UploadImage";
import { PostPreview } from "@assets/index";
import PostComponent from "./components/PostComponent";
import { RootState, useAppSelector } from "@Store/store";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@Components/shadcn/ui/dropdown-menu";

import {ArrowLeftIcon} from "@heroicons/react/24/outline"

import _ from "lodash";
import { displayToast } from "@Utils/toast";
import ReelComponent from "./components/ReelComponent";
import ImageComponent from "./components/ImageComponent";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "@Constants/routes";
import { useLazyGetScheduledPostQuery, useSchedulePostMutation, useUpdateScheduledPostMutation } from "./apiSlice";
import Loader from "@Components/Loader";

const EditScheduledPost = () => {

  const { user } = useAppSelector((state: RootState) => state.profile);

  const { schedule_post_id } = useParams()
  
  const [images, setImages] = React.useState<string[]>([]);
  const [caption, setCaption] = React.useState("");
  const [mediaType, setMediaType] = React.useState("");
  const [time, setTime] = useState("");
  const [name, setName] = useState("");
  const [igProfile, setIgProfile] = useState(user?.ig_ids[0])
  const [isEdit, setIsEdit] = useState("")

  const navigate = useNavigate()

  const [params] = useSearchParams()
  
  let [schedulePost, {data: schedulePostData, isLoading: scheduling, error: schedulePostError}] = useSchedulePostMutation()

  let [getScheduledPost, {data: postData, isLoading: loading, error: postError}] = useLazyGetScheduledPostQuery()

  let [updateScheduledPost, {data: updatePostData, isLoading: updating, error: updatePostError}] = useUpdateScheduledPostMutation()

  useEffect(() => {
    let edit = params.get('edit')
    if(edit) setIsEdit(edit)
    if(edit == "true"){
      getScheduledPost(schedule_post_id)
    }
  }, [])

  useEffect(() => {
    if(postData){
      console.log(postData);
      if (postData?.post?.media) setImages(postData?.post?.media);
      if (postData?.post?.caption) setCaption(postData?.post?.caption);
      if (postData?.post?.name) setName(postData?.post?.name);
      if (postData?.post?.time) setTime(postData?.post?.time);
      if (postData?.post?.ig) setIgProfile(postData?.post?.ig);
      if (postData?.post?.media_type) setMediaType(postData?.post?.media_type);
      
    }
    else{
      console.log(postError);
    }
  }, [postData, postError])

  const handleMediaType = (e: any) => {
    setMediaType(e.target.value);
  };

  const handleCaptionChange = (e: any) => {
    setCaption(e.target.value);
  };

  const handleImageChange = (url: string) => {
    let temp = _.cloneDeep(images);
    temp.push(url);
    setImages(temp);
  };

  const onImageDelete = (idx:number) => {
    let temp = _.cloneDeep(images)
    temp.splice(idx, 1)
    setImages(temp)
  }

  function toUTC(datetimeLocal: string) {
    const date = new Date(datetimeLocal);
    return date.toISOString().slice(0, 16);
  }

  const handleSchedulePostBtnClick = async() => {
    const payload:any = {
      "name": name,
      "time": toUTC(time),
      "media": images,
      "media_type": mediaType,
      "ig":{
        "ig_user_id": igProfile?.igsid || igProfile?.ig_user_id,
        "username": igProfile?.username,
        "profile_picture_url": igProfile?.profile_picture_url
      },
      "doc_id": schedule_post_id,
      "caption": caption || ""
    }

    if(isEdit == "true"){
      updateScheduledPost(payload)
    }else{
      const response = await schedulePost(payload).unwrap()
      if(response?.status){
        displayToast("success", "Post scheduled");
        navigate(ROUTES.SCHEDULED_POST)
        setName("")
        setTime("")
        setImages([])
        setMediaType("")
        setCaption("")
      }else{
        displayToast('error', 'Error while scheduling post')
      }
    }
  };

  const handleBack = () => {
    navigate(ROUTES.SCHEDULED_POST)
  };

  return (
    <div>
      <Navbar />
      <div className="px-8 py-2 bg-gray-50">
        <div>
          <div
            className="font-bold cursor-pointer"
            onClick={handleBack}
          >
            <ArrowLeftIcon className="size-6" />
          </div>
        </div>
      </div>
      {scheduling || updating ? (
        <Loader isLoading={true} />
      ) : (
          <div className="flex justify-center h-auto gap-[1rem] bg-gray-50">
          <div className="border-2 border-black w-[40vw] rounded-lg bg-white">
            <div className="p-4">
              <p className="font-bold">Create Post</p>
            </div>
            <div className="px-8 py-2 flex justify-between items-center">
              <img
                src={igProfile?.profile_picture_url}             
                className="rounded-[50%] h-[3rem] w-[3rem] object-contain"
              />
              <div>
                <DropdownMenu>
                  <DropdownMenuTrigger>Select an IG: <span className="font-bold">{igProfile?.username}</span></DropdownMenuTrigger>
                  <DropdownMenuContent>
                    {user?.ig_ids.map((ig:any) => (
                        <DropdownMenuItem onClick={() => setIgProfile(ig)}>{ig?.username}</DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
            <div className="border-2 border-gray-400 rounded-lg h-auto mx-[3rem]">
              <div className="p-4 flex items-center gap-6 border-b-2 border-gray-400">
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    id="post"
                    value="post"
                    name="mediaType"
                    onChange={handleMediaType}
                    checked={mediaType === "post"}
                  />
                  <label htmlFor="post">Post</label>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    id="caraousel"
                    value="caraousel"
                    name="mediaType"
                    onChange={handleMediaType}
                    checked={mediaType == "caraousel"}
                  />
                  <label htmlFor="caraousel">Caraousel</label>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    id="reel"
                    value="reel"
                    name="mediaType"
                    onChange={handleMediaType}
                    checked={mediaType == "reel"}
                  />
                  <label htmlFor="reel">Reel</label>
                </div>
              </div>
              <div className="p-4">
                <textarea
                  rows={4}
                  className="w-[100%] p-4 focus:outline-none"
                  placeholder="Start writing..."
                  value={caption}
                  onChange={handleCaptionChange}
                />
              </div>
              <div className="grid grid-cols-5 gap-2 p-4">
                {images?.length > 0 &&
                  images?.map((image, idx) => (
                    <ImageComponent idx={idx} image={image} mediaType={mediaType} onImageDelete={onImageDelete} />
                  ))}
                <UploadImage
                  images={images}
                  onImageChange={handleImageChange}
                  mediaType={mediaType}
                />
              </div>
            </div>
            <div className="mx-[3rem] mt-[1rem]">
              <div>
                <div className="flex flex-col gap-3 justify-center">
                  <div className="flex items-center gap-4">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="border-2 border-black rounded-lg px-4 py-2"
                      value={name}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setName(e.target.value)
                      }
                    />
                  </div>
                  <div className="flex items-center gap-4">
                    <label htmlFor="time">Date & Time</label>
                    <input
                      type="datetime-local"
                      name="time"
                      id="time"
                      className="border-2 border-black rounded-lg px-4 py-2"
                      value={time}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setTime(e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="mt-4 flex justify-end pb-4">
                  <button
                    type="submit"
                    className="bg-green-500 py-2 px-4 text-white rounded-lg font-bold"
                    onClick={handleSchedulePostBtnClick}
                  >
                    {isEdit == "true" ? "Save" : "Schedule Post"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="border-2 border-black w-[20vw] rounded-lg">
            <div className="p-4">
              <p className="font-bold">Instagram Preview</p>
            </div>
            {mediaType == "post" && images.length > 0 ? (
              <div className="p-4">
                <PostComponent igProfile={igProfile} caption={caption} images={images} />
              </div>
            ) : mediaType == "caraousel" && images.length > 0 ? (
              <div className="p-4">
                <PostComponent
                  igProfile={igProfile}
                  caption={caption}
                  images={images}
                  isCarousel={true}
                />
              </div>
            ) : mediaType == "reel" && images.length > 0 ? (
              <div className="p-4">
                <ReelComponent images={images} caption={caption} />
              </div>
            ) : (
              <div className="flex flex-col gap-2 mt-[8rem] justify-center items-center">
                <img src={PostPreview} />
                <p className="font-bold text-gray-400">
                  See yours posts preview here
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EditScheduledPost;
