import React, { useEffect } from "react";
import { Switch } from "@Components/shadcn/ui/switch";
import { Pencil1Icon, TrashIcon } from "@radix-ui/react-icons";
import { formatTimestampDifference } from "@Utils/common"
import { useUpdateSchedulePostStatusMutation } from "@Pages/ScheduledPostList/apiSlice";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@Constants/routes";
import { displayToast } from "@Utils/toast";

interface SchedulePostTableProps {
  data: any[];
  openModal: (doc_id:string) => void
  fetchPosts: () => void
}

const ScheduledPostTable = ({ data, openModal, fetchPosts }: SchedulePostTableProps) => {

  console.log(data);
  

  const navigate = useNavigate()

  const [
    updateSchedulePostStatus,
    { data: updatePostData, isLoading: isUpdating, error: updatePostError}
  ] = useUpdateSchedulePostStatusMutation()

  const handleSwitchCheck = (doc_id:string, status:boolean) => {
    updateSchedulePostStatus({doc_id, status})
  }

  useEffect(() => {
    
    if (updatePostData) {
      displayToast('success', 'Status Updated')
    }
    if (updatePostError) {
      displayToast('error', 'Status Update Unsuccessful')

    }
  }, [updatePostData, updatePostError])
  


  const handleEditBtnClick = (doc_id:string) => {
    navigate(
      ROUTES.EDIT_SCHEDULED_POST.replace(
        ":schedule_post_id",
        doc_id
      ) + "?edit=true"
    );
  }

  useEffect(() => {
    if(updatePostData){
      fetchPosts()
    }
  }, [updatePostData, updatePostError])

  return (
    <div className="overflow-x-auto">
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left">
          <thead className="text-sm border-b text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-8">
                Name
              </th>
              <th scope="col" className="px-6 py-8">
                Thumbnail
              </th>
              <th scope="col" className="px-6 py-8">
                Status
              </th>
              <th scope="col" className="px-6 py-8">
                IG Username
              </th>
              <th scope="col" className="px-6 py-8">
                Modified on
              </th>
              <th scope="col" className="px-6 py-8">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="border-b bg-white">
            {data && data.length > 0 ? (
              data.map((post) => (
                <tr className="border-b">
                  <td scope="col" className="px-6 py-2">
                    {post?.name}
                  </td>
                  <td scope="col" className="px-6 py-2">
                    <div>
                      <img
                        src={post?.media ? post?.media[0] : ""}
                        className="w-[200px] object-contain"
                      />
                    </div>
                  </td>
                  <td scope="col" className="px-6 py-2">
                    <p className="text-[16px] flex align-middle">
                    <Switch className="data-[state=checked]:bg-green-500"
                      checked={post?.status} id={`${post?.id}-status`}
                      name="automation-status"
                      onCheckedChange={(status) => handleSwitchCheck(post?.id, status)}
                        disabled={isUpdating}
                    />                   
                    <label className={"ml-3 font-bold " + (post?.status ? ' text-green-600' : 'text-gray-400')} htmlFor={`${post?.id}-status`}>{post?.status ? 'Active' : 'Inactive'}</label>       
                    </p>
                  </td>
                  <td scope="col" className="px-6 py-2">
                    {post?.ig?.username}
                  </td>
                  <td className="px-4 py-4">
                    <div className="flex flex-col items-start">
                    <p className="text-[13px] text-[#333333]">Updated: <b>{formatTimestampDifference(post?.modified_at)}</b></p>
                    <p className="text-[13px] text-[#333333]">Created: <b>{formatTimestampDifference(post?.created_at)}</b></p>
                    </div>
                  </td>
                  <td className="flex items-center px-6 py-[4rem]">
                    <div className="flex h-full gap-4 align-middle">
                      <div className="p-2 hover:cursor-pointer hover:bg-[#887DF2] hover:text-[#ffffff] rounded-xl bg-[#f3f4f6]" onClick={() => handleEditBtnClick(post?.id)}>
                        <Pencil1Icon />
                      </div>
                      <div className="p-2 hover:cursor-pointer hover:bg-[#887DF2] hover:text-[#ffffff] rounded-xl bg-[#f3f4f6]" onClick={() => openModal(post?.id)}>
                        <TrashIcon />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="text-center p-4 text-2xl">
                  No scheduled posts
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScheduledPostTable;
