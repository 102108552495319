import { baseApi } from '@Services/api';
import { RequestTypes } from '@Constants/api';
import { clearUser, setUser } from './profileSlice';

const profileApis = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getProfile: builder.query({
            query: (payload) => ({
                url: '/v0/me',
                method: RequestTypes.GET,
                params: payload
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setUser(data));
                } catch (err) {
                    dispatch(clearUser());
                }
            }
        })
    })
});

export const { useLazyGetProfileQuery } = profileApis;
