import React, { ChangeEvent, useEffect } from 'react'
import styles from "./styles"
import {NodeProps} from "reactflow"
import { useAppDispatch, useAppSelector } from '@Store/store';
import { updateNodeData } from '@Store/nodeReducer';

export interface TextInputProps{
  elementId: string;
  nodeId: any;
  data: any;
  orderIndex: number;
}

function TextInput ({ elementId, nodeId, data, orderIndex }:TextInputProps) {

  const dispatch = useAppDispatch()

  let { edges, nodes } = useAppSelector((state: any) => state.node);

  const [textarea, setTextarea] = React.useState<string>('')

  useEffect(() => {
    let tempNodeData = [...nodes[nodeId]]
    let innerNodeDataKey = tempNodeData.map(el=>{
      return el.id
    })
    
    if (innerNodeDataKey.indexOf(elementId) == -1){
      tempNodeData.push({ id: elementId, data: [], type: 'text' })
      dispatch(updateNodeData({ id: nodeId, data: tempNodeData}))
    } else {
      console.log(tempNodeData)
    }

  if(data){
    setTextarea(data)
  }
  }, [])
  


  const changeTextArea = (e:ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault()
    let tempNodeData = [...nodes[nodeId]]
    tempNodeData = tempNodeData.map((el: any, idx: number) => {
      if (el.id == elementId) {
        let new_data =  {...el, data:e.target.value}
        return new_data
      }
      return el
    })
    setTextarea(e.target.value)
    dispatch(updateNodeData({ id: nodeId, data: tempNodeData }))
  }

  return (
    <div style={styles.INPUT_TEXT_CONTAINER} className='p-2 rounded-lg select-text'>
      <textarea
          style={{
            // height: `${40 + textarea.length * 20}px`,
            outline: 'none',
            backgroundColor: 'var(--LIGHTGREY)',
            color: 'var(--BLACK)',
            width: '100%',
            fontSize: '12px',
          }}
          name='textdata'
          onChange={changeTextArea}
          placeholder='Enter your text here...'
          value={textarea} />
    </div>
  )
}

export default TextInput