import { baseApi } from '@Services/api';
import { RequestTypes } from '@Constants/api';

const loginApis = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    sendMessage: builder.mutation({
      query: (payload) => ({
        url: '/v0/inbox/send_message',
        method: RequestTypes.POST,
        body: payload
      })
    })
  })
});

export const { useSendMessageMutation } = loginApis;
