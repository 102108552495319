import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  Node,
  NodeChange,
  EdgeChange,
  Connection,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  MarkerType,
  ReactFlowProvider,
  useReactFlow,
} from "reactflow";
import { useAppSelector, useAppDispatch } from "@Store/store";
import { nanoid } from "@reduxjs/toolkit";

import "reactflow/dist/style.css";

import SendMessage from "./FlowBuilderComponents/SendMessage";
import StartNode from "./FlowBuilderComponents/StartNode";


import Navbar from "@Components/navbar";
import ButtonText from "./FlowBuilderComponents/ButtonTextNode/index";
import Gallery from "./FlowBuilderComponents/Gallery";
import ConditionalNode from "./FlowBuilderComponents/ConditionalNode";
import { useNavigate, useParams } from "react-router-dom";
import { useGetFlowAutomationMutation, useUpdateFlowAutomationMutation } from "./apiSlice";

import Loader from "@Components/Loader";
import { displayToast } from "@Utils/toast";
import { ArrowLeftIcon, ArrowTrendingUpIcon, ChatBubbleOvalLeftEllipsisIcon, CursorArrowRaysIcon, PhotoIcon, TagIcon, UserIcon } from "@heroicons/react/24/solid";
import ReplyComment from "./FlowBuilderComponents/ReplyComment";
import { ROUTES } from "@Constants/routes";
import TriggerNode from "./FlowBuilderComponents/TriggerNode";
import SideBarEditor from "./FlowBuilderComponents/SideBarEditor";
import EdgeDropNode from "./FlowBuilderComponents/EdgeDropNode";
import CustomDeleteEdge from "./FlowBuilderComponents/CustomDeleteEdge";


const nodeTypes = {
  sendMessage: SendMessage,
  startNode: StartNode,
  buttonTextNode: ButtonText,
  gallery: Gallery,
  conditionalNode: ConditionalNode,
  replyComment: ReplyComment,
  TriggerNode,
  EdgeDropNode
};

const edgeType = {
  default: CustomDeleteEdge
}

const AutomationBuilderWOProvider = () => {
  let { edges, nodes } = useAppSelector((state: any) => state.node);
  const [editingAutomationName, setEditingAutomationName] = useState(false)
  const [AutomationName, setAutomationName] = useState('')

  let { automation_doc_id } = useParams();
  const navigate = useNavigate();

  let [getFlowAutomationDetail, getFlowAutomationData] = useGetFlowAutomationMutation()
  let [updateFlowAutomation, updateFlowAutomationData] = useUpdateFlowAutomationMutation()


  const connectingNodeId = useRef<any>(null);

  const { screenToFlowPosition } = useReactFlow();


  useEffect(() => {
    getFlowAutomationDetail({ id: automation_doc_id })
  }, [])

  useEffect(() => {
    if (updateFlowAutomationData?.data) {
      displayToast("success", updateFlowAutomationData?.data?.data)
      if (!getFlowAutomationData?.data?.details?.status) displayToast("error", "To turn ON this automation go to the automations page!")
    }
    if (updateFlowAutomationData?.error) {
      displayToast("error", updateFlowAutomationData?.error)
    }
  }, [updateFlowAutomationData?.data, updateFlowAutomationData?.error])


  useEffect(() => {
    if (getFlowAutomationData?.data) {
      setAutomationName(getFlowAutomationData?.data?.details?.name)
      if (getFlowAutomationData?.data?.details?.nodes) {
        setNodeData(getFlowAutomationData?.data?.details?.nodes)
      } else {
        const start_node = [
          {
            id: `IG#TriggerNode#` + nanoid(),
            type: "TriggerNode",
            data: {},
            position: { x: Math.random() * 250, y: Math.random() * 25 },
          }
        ]
        setNodeData(start_node)
      }
      if (getFlowAutomationData?.data?.details?.edges) setEdgesData(getFlowAutomationData?.data?.details?.edges)
    }
  }, [getFlowAutomationData?.data, getFlowAutomationData?.isError])



  const [NodeData, setNodeData]: any = useState([]);
  const [EdgesData, setEdgesData]: any = useState([]);

  const dispatch = useAppDispatch();
  const { isInitialised } = useAppSelector((state) => state.sidebar);


  useEffect(() => {
    // console.log("----START---")
    // if (NodeData) console.log(NodeData);
    // if (EdgesData) console.log(EdgesData);
    // console.log("----END---")
  }, [NodeData, EdgesData])
  




  const handleSendMessageClick = () => {
    let payload: Node = {
      id: `IG#sendMessage#` + nanoid(),
      type: "sendMessage",
      data: [],
      position: { x: Math.random() * 250, y: Math.random() * 25 },
    };

    const allNodes = [...NodeData, payload];
    setNodeData(allNodes);
  };

  const handleButtonText = () => {
    let payload: Node = {
      id: `IG#buttonText#` + nanoid(),
      type: "buttonTextNode",
      data: [],
      position: { x: Math.random() * 250, y: Math.random() * 25 },
    };
    const allNodes = [...NodeData, payload];
    setNodeData(allNodes);
  };

  const handleGallery = () => {
    let payload: Node = {
      id: `IG#gallery#` + nanoid(),
      type: "gallery",
      data: [
        {
          image_url: "",
          title: "",
          subtitle: "",
          buttons: [],
        },
      ],
      position: { x: Math.random() * 250, y: Math.random() * 25 },
    };
    const allNodes = [...NodeData, payload];
    setNodeData(allNodes);
  }

  const AddConditionalNode = () => {
    let payload: Node = {
      id: `system#conditional#` + nanoid(),
      type: "conditionalNode",
      data: [],
      position: { x: Math.random() * 250, y: Math.random() * 25 },
    };
    const allNodes = [...NodeData, payload];
    setNodeData(allNodes);
  }

  const AddReplyComment = () => {
    let payload: Node = {
      id: `IG#replyComment#` + nanoid(),
      type: "replyComment",
      data: [],
      position: { x: Math.random() * 250, y: Math.random() * 25 },
    };
    const allNodes = [...NodeData, payload];
    setNodeData(allNodes);
  }

  const onNodeChange = (changes: NodeChange[]) => {
    const newNodeData = applyNodeChanges(changes, NodeData);
    setNodeData(newNodeData);
    // dispatch(nodeChange(changes))
  };

  const onEdgesChange = (changes: EdgeChange[]) => {
    const newEdges = applyEdgeChanges(changes, EdgesData);
    setEdgesData(newEdges);
  };

  const onConnect = (connection: Connection) => {
    const markerEnd = {
      type: MarkerType.ArrowClosed,
      width: 40,
      height: 40
    }
    const newEdges = addEdge({ ...connection, markerEnd }, EdgesData);
    setEdgesData(newEdges);
  };

  const handleSave = () => {
    const filterEdge = EdgesData.filter((edge: any) => !edge.id.includes("TriggerEdgeDropNode"))
    const filterNode = NodeData.filter((node: any) => { console.log(node); return node.type !== "EdgeDropNode"})

    let nodeNew = filterNode.map((el: any, idx: number) => {
      const nodeData = nodes[el.id]
      return { ...el, data: nodeData }
    })
    setNodeData(nodeNew);

    updateFlowAutomation({ ...getFlowAutomationData?.data?.details, nodes: filterNode, edges: filterEdge, name: AutomationName })
  };


  const onConnectStart = useCallback((event:any, nodeParams:any) => {
    // if (nodeParams?.nodeId.includes('TriggerNode')){
      connectingNodeId.current = {
        nodeId: nodeParams.nodeId,
        handleId: nodeParams.handleId
      }
    const filteredEdges = EdgesData.filter((edge: any) => (edge.source !== nodeParams.nodeId || edge.sourceHandle !== nodeParams.handleId));
      setEdgesData(filteredEdges);

      const filteredNode = NodeData.filter((node: any) => node.type !== 'EdgeDropNode');
      setNodeData(filteredNode);

    // }
    // else connectingNodeId.current = '';
    
  }, [EdgesData, NodeData]);

  const onConnectEnd = (event:any) => {

      if (connectingNodeId.current == null) return;

      const targetIsPane = event.target.classList.contains('react-flow__pane');
      if (targetIsPane) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const id = `TriggerEdgeDropNode#` + nanoid();
        let show = []

        if (connectingNodeId?.current?.nodeId?.includes('IG#TriggerNode#')) {
          show = ['sendMessage', 'buttonText', 'card', 'reply']
        } else if (connectingNodeId?.current?.nodeId?.includes('system#conditional') || connectingNodeId?.current?.handleId.includes("postback")) {
          show = ['sendMessage', 'buttonText', 'card', 'conditional', 'reply']
        }
         else {
          show = ['reply']
        }

        const markerEnd = {
          type: MarkerType.ArrowClosed,
          width: 40,
          height: 40
        }

        const allEdges = [...EdgesData, { id, source: connectingNodeId.current.nodeId, sourceHandle: connectingNodeId.current.handleId, target: id, markerEnd }]
        let payload: Node = {
          id,
          type: "EdgeDropNode",
          data: {
            setNodeData,
            setEdgesData,
            NodeData,
            EdgesData: allEdges,
            source: connectingNodeId.current.nodeId,
            sourceHandle: connectingNodeId.current.handleId,
            show
          },
          position: screenToFlowPosition({ x: event.clientX, y: event.clientY }),
        };
        const allNodes = [...NodeData, payload];
        setNodeData(allNodes);
        setEdgesData(allEdges);
      }
    };


  const handleAutomationNameChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    if (e?.target?.name == 'automationName') setAutomationName(e.target.value)
  };


  return (
    <div className="h-screen flex flex-col">
      <div className="flex-none">
        <Navbar />
      </div>
      <div className="flex flex-1">
        <div>
          {/* <div className="w-[350px] overflow-x-auto h-[90vh] p-4 border-r">
            <h2 className="text-lg font-bold">Primary Items</h2>
            <div
              className="bg-[#E95B69] px-4 py-3 rounded-lg my-2 hover:cursor-pointer relative"
              onClick={() => handleSendMessageClick()}
            >
              <div className="h-[80%] w-[40%] bg-[#ffffff33] absolute top-[-7px] left-[-7px] rounded-full"></div>
              <div className="bg-[#ffffff5c] float-right p-2 rounded-full">
                <ChatBubbleOvalLeftEllipsisIcon className="size-6 text-white" />
              </div>
              <p className="text-white font-bold">Send Message</p>
              <p className="text-white mt-1 text-xs ">with no response required from user</p>
            </div>

            <div
              className="bg-[#ff9933] px-4 py-3 rounded-lg my-2 hover:cursor-pointer relative"
              onClick={() => handleButtonText()}
            >
              <div className="h-[80%] w-[40%] bg-[#ffffff33] absolute top-[-7px] left-[-7px] rounded-full"></div>
              <div className="bg-[#ffffff5c] float-right p-2 rounded-full">
                <CursorArrowRaysIcon className="size-6 text-white" />
              </div>
              <p className="text-white font-bold">Button Text</p>
              <p className="text-white mt-1 text-xs ">Send texts with buttons like link's etc</p>
            </div>

            <div
              className="bg-[#28bf63] px-4 py-3 rounded-lg my-2 hover:cursor-pointer relative"
              onClick={() => handleGallery()}
            >
              <div className="h-[80%] w-[40%] bg-[#ffffff33] absolute top-[-7px] left-[-7px] rounded-full"></div>
              <div className="bg-[#ffffff5c] float-right p-2 rounded-full">
                <PhotoIcon className="size-6 text-white" />
              </div>
              <p className="text-white font-bold">Card or Gallery</p>
              <p className="text-white mt-1 text-xs ">Send Card or Gallery with links in Message</p>
            </div>

            <div
              className="bg-[#007aff] px-4 py-3 rounded-lg my-2 hover:cursor-pointer relative"
              onClick={() => AddConditionalNode()}
            >
              <div className="h-[80%] w-[40%] bg-[#ffffff33] absolute top-[-7px] left-[-7px] rounded-full"></div>
              <div className="bg-[#ffffff5c] float-right p-2 rounded-full">
                <ArrowTrendingUpIcon className="size-6 text-white" />
              </div>
              <p className="text-white font-bold">Set a Conditional</p>
              <p className="text-white mt-1 text-xs ">Send Messages according to conditions</p>
            </div>

            <div
              className="bg-[#a100ff] px-4 py-3 rounded-lg my-2 hover:cursor-pointer relative"
              // onClick={() => AddConditionalNode()}
            >
              <div className="h-[80%] w-[40%] bg-[#ffffff33] absolute top-[-7px] left-[-7px] rounded-full"></div>
              <div className="bg-[#ffffff5c] float-right p-2 rounded-full">
                <UserIcon className="size-6 text-white" />
              </div>
              <p className="text-white font-bold">User Input</p>

              <p className="text-s text-yellow-300 italic">(coming soon)</p>
              <p className="text-white mt-1 text-xs ">Get User Input and save them in variables</p>
            </div>
            

            <div>
              <h2 className="text-lg font-bold mt-3 mb-2">Instagram</h2>
              <div className="flex flex-row">
                <div className="basis-1/2">
                  <div onClick={() => AddReplyComment()} className="bg-[#9595955c] hover:bg-[#0000005c] p-1 w-[3rem] h-[3rem] rounded-full w-[fit-content] mx-auto flex items-center">
                    <img className="h-[2rem] mx-auto" src="https://img.icons8.com/pulsar-color/96/reply.png" />
                  </div>
                  <p className="text-xs text-center">Reply Comment</p>
                  <p className="text-xs text-center text-yellow-500 italic">(coming soon)</p>
                </div>
              </div>
            </div>


            <div>
              <h2 className="text-lg font-bold mt-3 mb-2">Integration</h2>
              <div className="flex flex-row">
                <div className="basis-1/2">
                  <div className="bg-[#9595955c] hover:bg-[#0000005c] p-1 w-[3rem] h-[3rem] rounded-full w-[fit-content] mx-auto flex items-center">
                    <img className="h-[2rem] mx-auto" src="https://img.icons8.com/color/48/google-sheets.png" />
                  </div>
                  <p className="text-xs text-center">Google Sheets</p>
                  <p className="text-xs text-center text-yellow-500 italic">(coming soon)</p>
                </div>
              </div>
            </div>

            <div>
              <h2 className="text-lg font-bold mt-3 mb-2">Operations</h2>
              <div className="flex flex-row flex-wrap">
                <div className="basis-1/2 my-2">
                  <div className="bg-[#9595955c] hover:bg-[#0000005c] p-1 w-[3rem] h-[3rem] rounded-full w-[fit-content] mx-auto flex items-center">
                    <img className="h-[2rem] mx-auto" src="https://img.icons8.com/fluency/48/subscription.png" />
                  </div>
                  <p className="text-xs text-center">Subscribe</p>
                  <p className="text-xs text-center text-yellow-500 italic">(coming soon)</p>
                </div>
                <div className="basis-1/2 my-2">
                  <div className="bg-[#9595955c] hover:bg-[#0000005c] p-1 w-[3rem] h-[3rem] rounded-full w-[fit-content] mx-auto flex items-center">
                    <img className="h-[2rem] mx-auto" src="https://img.icons8.com/fluency/48/unsubscribe.png" />
                  </div>
                  <p className="text-xs text-center">Unsubscribe</p>
                  <p className="text-xs text-center text-yellow-500 italic">(coming soon)</p>
                </div>
                <div className="basis-1/2 my-2">
                  <div className="bg-[#9595955c] hover:bg-[#0000005c] p-1 w-[3rem] h-[3rem] rounded-full w-[fit-content] mx-auto flex items-center">
                    <img className="h-[2rem] mx-auto" src="https://img.icons8.com/fluency/48/tag--v1.png" />
                  </div>
                  <p className="text-xs text-center">Set Tags</p>
                  <p className="text-xs text-center text-yellow-500 italic">(coming soon)</p>
                </div>

                <div className="basis-1/2 my-2">
                  <div className="bg-[#9595955c] hover:bg-[#0000005c] p-1 w-[3rem] h-[3rem] rounded-full w-[fit-content] mx-auto flex items-center">
                    <img className="h-[2rem] mx-auto" src="https://img.icons8.com/fluency/48/tag--v1.png" />
                  </div>
                  <p className="text-xs text-center">Time Delay</p>
                  <p className="text-xs text-center text-yellow-500 italic">(coming soon)</p>
                </div>
              </div>
            </div>
            
          </div> */}
        </div>


        <div className={`w-[380px] overflow-x-auto h-[90vh] ${isInitialised?'':''}`}>

          <SideBarEditor/>
        </div>
        <div
          className="w-[100%] flex flex-col">
          <div className="flex-none h-15 p-3 flex place-content-between item-center border-b">
            <div className="flex items-center ">
              <ArrowLeftIcon onClick={(e) => navigate(ROUTES.AUTOMATIONS)} className="size-6 hover:cursor-pointer" />
              {!editingAutomationName && <h1 className='ml-4 inline text-lg' onClick={(e) => setEditingAutomationName(true)}>{AutomationName}</h1>}
              {editingAutomationName && (
                <div className="ml-4">
                  <input className='outline-none  text-md border-b-2 border-[#cbcbcb] w-[250px]' autoFocus type='text' value={AutomationName} name='automationName' onChange={handleAutomationNameChange} />
                  <button
                    className="px-6 py-1 border-2 border-solid rounded border-[#23a455] text-[#23a455] mx-2"
                    onClick={(e) => { setEditingAutomationName(false) }}>
                    Save
                  </button>
                </div>

              )}
            </div>

            <div>
              <button className='px-8 py-2 bg-[#218A4A] text-white rounded' onClick={(e) => handleSave()}>Save Flow</button>
            </div>
          </div>
          {/* {getFlowAutomationData.isLoading ? 
          <Loader isLoading={true} />
           :  */}
          <div className="flex-1">
              <ReactFlow
                nodes={NodeData}
                edges={EdgesData}
                onNodesChange={onNodeChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                nodeTypes={nodeTypes}
                edgeTypes={edgeType}
                onConnectStart={onConnectStart}
                onConnectEnd={onConnectEnd}
                deleteKeyCode={[]}
              >
                <Controls />
                <MiniMap />
                <Background gap={12} size={1} />
              </ReactFlow>
          </div>
          {/* }  */}
        </div>
      </div>
    </div>
  );
};

function AutomationBuilder() {
  return (
    <ReactFlowProvider>
      <AutomationBuilderWOProvider />
    </ReactFlowProvider>
  );
}
export default AutomationBuilder;
