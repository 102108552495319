import React, { ChangeEvent, useEffect, useState } from 'react';
import { Handle, NodeProps, NodeToolbar, Position } from 'reactflow';
import styles from './styles';
import { nanoid } from '@reduxjs/toolkit';
import {useAppDispatch} from "@Store/store"
import {updateNodeData} from "@Store/nodeReducer"
import { useNodeId, useReactFlow } from 'reactflow';
import ReactModal from 'react-modal';
import InstagramHeader from '../InstagramHeader';
import { DeleteBin } from '@assets/index';
import _ from 'lodash';
const ReplyComment = (props:NodeProps) => {
  
  const [text, setText] = useState("")

  const reactflow = useReactFlow()
  const nodeId = useNodeId()

  const dispatch = useAppDispatch()

  useEffect(() => {
    setText(props?.data[0]?.text || '')
  }, [])
  

  const deleteNode = () => {
    if(nodeId){
      reactflow.deleteElements({nodes: [{id: nodeId}]})
    }
  }
  
  useEffect(() => {
    dispatch(updateNodeData({ id: props?.id, data: [{text}] }))
  }, [text])
  

  const handleText = (e:ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value)
  }

  return (
    <div style={styles.INPUT_TEXT_CONTAINER} className='border-2 p-4'>

      <NodeToolbar
        isVisible={true}
        position={Position.Top}
      >
        <button className='bg-red-100 p-1 border-2 rounded-lg border-red-400' onClick={deleteNode}>
          {/* <TrashIcon className='size-5'/> */}
          <DeleteBin width='15px' />
        </button>
      </NodeToolbar>
      <div>
        <InstagramHeader header={"Reply Comment"}/>
      </div>
      <Handle
        type='target'
        position={Position.Left}
        style={{
          top: "10%",
          width: "10px",
          height: "10px",
        }}
      />
      <div className='mt-4'>
        <textarea value={text} onChange={handleText} placeholder='Enter Reply Texts...' className='h-[10vh] w-full rounded-xl p-3 border-2 border text-xs' style={{
          height: `${50 + text.split("\n").length * 20}px`,
          outline: 'none'
        }}></textarea>
      </div>
      <Handle
        id={`${nodeId}_true`}
        type='source'
        position={Position.Right}
        style={{
          position: "absolute",
          top: "90%",
          right: "-4px",
          width: "10px",
          height: "10px",
          zIndex: 100,
        }}
      />
    </div>
  );
};

export default ReplyComment