import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactModal from 'react-modal';

import Navbar from '@Components/navbar';

import { useGetAllIGPostsMutation, useGetAutomationMutation, useUpdateAutomationMutation } from './apiSlice';
import styles from './style';
import { RootState, useAppSelector } from '@Store/store';
import Loader from '@Components/Loader';
import { TrashIcon } from 'lucide-react';
import { displayToast } from '@Utils/toast';
import { Switch } from '@Components/shadcn/ui/switch';

interface SendMessageLinkDataInterface {
    url: string,
    title: string,
    type: string
}

interface SendMessageDataInterface {
    textMessage: string,
    links: Array<SendMessageLinkDataInterface>
}

function EditAutomation() {

    const [selectPostnModal, setSelectPostModal] = useState(false)
    const [addLinkModal, setAddLinkModal] = useState(false)
    const [editingAutomationName, setEditingAutomationName] = useState(false)
    const [AutomationName, setAutomationName] = useState('')

    const [automationStatus, setAutomationStatus] = useState(false)

    const [automationPost, setAutomationPost] = useState<null | any>(null)
    const [automationIncludeWords, setautomationIncludeWords] = useState<Array<string>>([])
    const [automationExcludeWords, setautomationExcludeWords] = useState<Array<string>>([])

    const [includeTrigger, setIncludeTrigger] = useState('')
    const [excludeTrigger, setExcludeTrigger] = useState('')
    const [sendMessageData, setSendMessageData] = useState<SendMessageDataInterface>({ textMessage: '', links: [] })

    const { user } = useAppSelector((state: RootState) => state.profile);

    let { automation_id } = useParams();
    const navigate = useNavigate();

    let [getAutomationDetail, getAutomationData] = useGetAutomationMutation()
    let [updateAutomationDetail, updateAutomationDetailData] = useUpdateAutomationMutation()

    let [getAllIGPosts, { data: IGPostsData, error: IGPostsError, isLoading: isIGPostsLoading }] = useGetAllIGPostsMutation()


    useEffect(() => {
        getAutomationDetail({ automation_id })
    }, [])

    useEffect(() => {
        if (getAutomationData?.data){
            const node_payload = JSON.parse(getAutomationData?.data?.details?.node.node_payload)
            if (node_payload) setSendMessageData(node_payload)
            const trigger_payload = JSON.parse(getAutomationData?.data?.details?.trigger?.trigger_payload)
            if (trigger_payload?.include_keywords) setautomationIncludeWords(trigger_payload?.include_keywords)
            if (trigger_payload?.exclude_keywords) setautomationExcludeWords(trigger_payload?.exclude_keywords)
            setAutomationPost(trigger_payload?.automation_post_data)
            setAutomationName(getAutomationData?.data?.details?.automation_name)
            setAutomationStatus(getAutomationData?.data?.details?.automation_status)
            
        }
    }, [getAutomationData])
    




    const saveAutomation = () =>{
        
        const payload:any = {}
        payload['automation_id'] = automation_id
        payload['automation_name'] = editingAutomationName ? getAutomationData?.data?.details?.automation_name : AutomationName
        payload['automation_status'] = automationStatus

        payload['trigger'] = {
            'identifier_id': automationPost?.id,
            'trigger_payload': {
                include_keywords: automationIncludeWords,
                exclude_keywords: automationExcludeWords,
                automation_post_data: automationPost
            },
            'trigger_type': 'post_automation'
        }
        payload['node'] = {
            node_id: getAutomationData?.data?.details?.node?.node_id,
            node_type: 'send_message',
            node_subtype: sendMessageData?.links.length > 0 ? 'button_text' : 'text_message',
            node_payload: sendMessageData
        }

        updateAutomationDetail(payload)

    }


    useEffect(() => {
        if (updateAutomationDetailData?.data) displayToast('success', 'Automation Updated Successfully')
        if (updateAutomationDetailData?.error) displayToast('error', 'Automation Update failed')
 
    }, [updateAutomationDetailData])
    

    const closeSelectPostModal = () => {
        setSelectPostModal(!selectPostnModal)
    }

    const closeAddLinkModalOnClose = () => {
        setAddLinkModal(!addLinkModal)
    }

    const selectIGPostBtnClick = () => {
        if (user?.ig_ids.length === 1)
            getAllIGPosts({ ig_id: user?.ig_ids[0] })

        setSelectPostModal(true)

    }

    const addAutomationTriggerWord = (trigger_type: string, trigger_word: string) => {
        if (trigger_word.length == 0) return
        if (trigger_type == 'include') {

            let temp_arr: Array<string> = [...automationIncludeWords]
            temp_arr.push(trigger_word)
            setautomationIncludeWords(temp_arr)
            setIncludeTrigger('')
        }

        if (trigger_type == 'exclude') {
            let temp_arr: Array<string> = [...automationExcludeWords]
            temp_arr.push(trigger_word)
            setautomationExcludeWords(temp_arr)
            setExcludeTrigger('')

        }
    }



    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {

        if (e?.target?.name == 'includeTrigger') setIncludeTrigger(e.target.value);
        if (e?.target?.name == 'excludeTrigger') setExcludeTrigger(e.target.value);
        if (e?.target?.name == 'sendMessageText') setSendMessageData({ ...sendMessageData, ['textMessage']: e.target.value });

        // if (e?.target?.name == 'addLinkModalTitle') setAddLinkModalTitle(e.target.value);
        // if (e?.target?.name == 'addLinkModalLink') setAddLinkModalLink(e.target.value)


    };


    const SelectPostModal = (props: SelectPostModalInterface) => {
        const selectPostForAutomation = (post_data: any) => {
            setAutomationPost(post_data)
            props.selectPostModalOnClose()
        }


        return (
            <ReactModal
                isOpen={props.isModalOpen}
                onRequestClose={props.selectPostModalOnClose}
                style={
                    { overlay: {}, content: styles.deleteContactModalContent }
                }
                portalClassName={"ReactModalPortal"}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                role={"dialog"}
                preventScroll={false}
                ariaHideApp={false}
            >
                <>
                    <div className="pb-[.5rem] border-b-2 border-slate-300" style={styles.modalHeader}>
                        <h3>Select Post for Automation</h3>
                    </div>
                    <div className="py-[1rem]" style={styles.modalContent}>
                        {isIGPostsLoading ? <Loader isLoading={true} />
                            :
                            <div className='grid grid-cols-3 gap-3'>
                                {IGPostsData?.data?.data.length > 0 ? IGPostsData?.data?.data.map((post: any, index: number) => {
                                    return <div className='w-[100%] aspect-square border-2 rounded' onClick={(e) => selectPostForAutomation(post)} key={index}>
                                        <img className='inline w-full h-full object-cover' src={post.thumbnail_url || post.media_url} />
                                    </div>
                                }):(
                                    <div className='col-span-3 flex items-center justify-center mt-[50%]'>
                                        <p className='text-2xl'>No posts found</p>
                                    </div>
                                )}
                            </div>}
                        {/* <p>Do you want to remove this automation?</p> */}
                        {/* <div className="flex justify-end mt-[2rem]">
                        <button
                            className="px-6 py-1 border-2 border-solid rounded border-green-500 mx-2"
                            style={styles.greenButton}
                            onClick={props.editAutomationModalOnClose}>
                            Cancel
                        </button>
                        <button className="px-6 py-1 border-2 border-solid rounded" style={styles.redButton} onClick={props.deleteAutomation}>Delete</button>
                    </div> */}
                    </div>
                </>
            </ReactModal>
        )
    }

    const AddLinkToSendMessageModal = (props: AddLinkModalInterface) => {
        const [addLinkModalTitle, setAddLinkModalTitle] = useState('')
        const [addLinkModalLink, setAddLinkModalLink] = useState('')

        const handleChangeSendMessageModal = (e: React.ChangeEvent<HTMLInputElement> | any) => {
            if (e?.target?.name == 'addLinkModalTitle') setAddLinkModalTitle(e.target.value);
            if (e?.target?.name == 'addLinkModalLink') setAddLinkModalLink(e.target.value)
        };

        const addLinkToSendMessageState = () => {
            const temp_links = sendMessageData.links
            temp_links.push({ url: addLinkModalLink, title: addLinkModalTitle, type: 'web_url' })
            setSendMessageData({ ...sendMessageData, ['links']: temp_links })

            props.addLinkModalOnClose()
        }


        return (
            <ReactModal
                isOpen={props.isModalOpen}
                onRequestClose={props.addLinkModalOnClose}
                style={
                    { overlay: {}, content: styles.addLinkModalContent }
                }
                portalClassName={"ReactModalPortal"}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                role={"dialog"}
                preventScroll={false}
                ariaHideApp={false}
            >
                <>
                    <div className="pb-[.5rem] border-b-2 border-slate-300" style={styles.modalHeader}>
                        <h3 className='font-bold'>Enter the link details for text</h3>
                    </div>
                    <div className="py-[1rem]" style={styles.modalContent}>
                        <div className='my-2'>
                            <label className='block font-medium'>Enter Link Title</label>
                            <input style={styles.enterKeywordInput} className='mt-1 p-2 rounded bg-[#f9f9f9] text-black w-full' type='text' placeholder='Enter Title'
                                name='addLinkModalTitle' onChange={handleChangeSendMessageModal} value={addLinkModalTitle} />
                        </div>
                        <div className='mt-4'>
                            <label className='block font-medium'>Enter Link URL</label>
                            <input style={styles.enterKeywordInput} className='mt-1 p-2 rounded bg-[#f9f9f9] text-black w-full' type='url' placeholder='Enter URL'
                                name='addLinkModalLink'
                                onChange={handleChangeSendMessageModal}
                                value={addLinkModalLink}
                            />
                        </div>
                        <button className='py-1 mt-[25px] w-full border-2 border-solid rounded text-[#23a455] bg-[#23a45500]' onClick={(e) => addLinkToSendMessageState()}>Save Link</button>
                    </div>
                </>
            </ReactModal>
        )
    }

    const DeleteLinkInSendMessage = (index: number) => {
        const temp_data = {...sendMessageData}
        temp_data.links.splice(index, 1)
        // console.log(index, temp_links);
        setSendMessageData(temp_data)
    }

    const handleAutomationNameChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
        if (e?.target?.name == 'automationName') setAutomationName(e.target.value)
    };

    const handleAutomationStatusChange = (status:boolean) => {
        setAutomationStatus(status)
    }


    return (
        <div>
            <Navbar />
            <div className='px-[3rem] py-[1rem] border-b-2 border-[#F5F5F5]'>
                <div className='flex justify-between'>
                    <div className='flex items-center'>
                        <img src={"https://img.icons8.com/forma-regular/48/long-arrow-left.png"} alt="back button" className={'h-[25px] inline mx-2'} onClick={(e) => navigate(-1)} />
                        {!editingAutomationName && <h1 className='inline text-3xl' onClick={(e)=>setEditingAutomationName(true)}>{AutomationName}</h1>}
                        {editingAutomationName && (
                            <div>
                                <input className='outline-none  text-xl border-b-2 border-[#cbcbcb] w-[250px]' autoFocus type='text' value={AutomationName} name='automationName' onChange={handleAutomationNameChange}/>
                                <button
                                    className="px-6 py-1 border-2 border-solid rounded border-[#23a455] text-[#23a455] mx-2"
                                    onClick={(e)=>{setEditingAutomationName(false)}}>
                                    Save
                                </button>
                            </div>
                        
                        )}
                    </div>
                    <div className='flex flex-row align-end gap-4'>
                        <div className="flex align-middle">
                            <Switch className="data-[state=checked]:bg-green-500 align-middle"
                                // disabled={isUpdateAutomationStatusLoading}
                                checked={automationStatus} id={`${getAutomationData?.data?.details?.id}-status`}
                                name="automation-status"
                                onCheckedChange={(status) => { handleAutomationStatusChange(status) }} />
                            <p className={"ml-3 font-bold " + (getAutomationData?.data?.details?.automation_status ? ' text-green-600' : 'text-gray-400')}>{getAutomationData?.data?.details?.automation_status ? 'Active' : 'Inactive'}</p>
                        </div>
                        <div>
                            <button className='px-[2rem] py-1 border-[2px] rounded' onClick={(e) => saveAutomation()}>Save Flow</button>
                        </div>

                    </div>
                    
                </div>
            </div>

            <div className='p-[2rem] grid grid-cols-3 gap-8 '>
                <div className={'w-full border-2 border-[black] p-4 rounded-[20px] border-[#2224266b]'}>
                    <h2 className='text-2xl font-bold'>Step 1</h2>
                    <h3 className='text-l'>Select Post for the automation to reply on the comments</h3>

                    {automationPost ?
                        <div className='w-[100%] shadow-2xl mx-auto my-5 p-2 border-2 border-solid rounded border-green-500  border-[#23a455] '>
                            <div className='h-[300px]'>
                                <img className='h-full object-cover w-full rounded' src={automationPost?.thumbnail_url || automationPost?.media_url} />
                            </div>
                            <p className='mt-2 text-[#646966]' style={styles.selectedPostCaption}>{automationPost?.caption.replace('\n', ' ').substring(0, 25)}...</p>
                            <button
                                className="px-6 w-full py-1 border-2 border-solid rounded mx-2 border-[#646966] rounded text-[#646966] mx-auto block my-2"
                                // style={styles.selectPostButton}
                                onClick={(e) => selectIGPostBtnClick()}
                            >
                                Change Post
                            </button>
                        </div>
                        :
                        <button
                            className="px-6 py-1 border-2 border-solid rounded mx-2 border-[#23a455] rounded text-[#23a455] mx-auto block my-2"
                            // style={styles.selectPostButton}
                            onClick={(e) => selectIGPostBtnClick()}
                        >
                            Select Post
                        </button>}

                </div>
                <div className={'w-full border-2 border-[black] p-4 rounded-[20px] border-[#2224266b]'}>
                    <h2 className='text-2xl font-bold'>Step 2</h2>
                    <h3 className='text-l'>What will start your DM automation?</h3>
                    <div className='w-[100%] shadow-2xl mx-auto my-5 p-2 border-2 border-solid rounded border-green-500  border-[#23a455] '>
                        <p className='text-[#000]'>Specific Keywords</p>
                        <div className='w-[100%] text-[#646966] mx-auto my-1 p-2 border-2 border-solid rounded-[10px] border-[#f2f2f2] '>
                            <p>Comments should <span className='text-[#000]'>include</span> these Keywords:</p>
                            <div className='flex flex-row gap-2 border-[1px] rounded p-1 mt-2'>
                                <input style={styles.enterKeywordInput} className='basis-3/4 p-1' type='text' placeholder='Type the keyword'
                                    name='includeTrigger' onChange={handleChange} value={includeTrigger} />
                                <button onClick={(e) => addAutomationTriggerWord('include', includeTrigger)} className='basis-1/4 border-2 rounded'>Add</button>
                            </div>
                            {automationIncludeWords && automationIncludeWords?.length > 0 && <div className='mt-3 flex flex-wrap'>
                                {automationIncludeWords.map((word, idx) => {
                                    return <p className="inline px-2 py-1 rounded-2xl my-1 mx-1 bg-[#e7eff0]" key={idx}>{word}</p>
                                })}
                            </div>}

                        </div>
                        <div className='w-[100%] text-[#646966] mx-auto my-1 p-2 border-2 border-solid rounded-[10px] border-[#f2f2f2] '>
                            <p>Comments should <span className='text-[#000]'>exclude</span> these Keywords:</p>
                            <div className='flex flex-row gap-2 border-[1px] rounded p-1 mt-2'>
                                <input style={styles.enterKeywordInput} className='basis-3/4 p-1' type='text' placeholder='Type the keyword'
                                    name='excludeTrigger' onChange={handleChange} value={excludeTrigger} />
                                <button onClick={(e) => addAutomationTriggerWord('exclude', excludeTrigger)} className={'basis-1/4 border-2 rounded' + ' bg-[#f9f9f9] border-[#f1f1f1]'}>Add</button>
                            </div>
                            {automationExcludeWords && automationExcludeWords?.length > 0 && <div className='mt-3 flex flex-wrap'>
                                {automationExcludeWords.map((word, idx) => {
                                    return <p className="inline px-2 py-1 rounded-2xl my-1 mx-1 bg-[#e7eff0]" key={idx}>{word}</p>
                                })}
                            </div>}

                        </div>
                    </div>
                </div>
                <div className={'w-full border-2 border-[black] p-4 rounded-[20px] border-[#2224266b]'}>
                    <h2 className='text-2xl font-bold'>Step 3</h2>
                    <h3 className='text-l'>Write the content which you want to send to the user</h3>
                    <div className='w-[100%] text-[#000000] mx-auto my-1 bg-[#f2f2f2] border-solid rounded-[10px] border-[#f2f2f2] leading-5'>
                        <div className=''>
                            <textarea className='w-full bg-[#f2f2f2] p-2 rounded hover:border-2 border-[#f2f2f2] hover:border-[#0084ff] ' value={sendMessageData?.textMessage || ''} style={{
                                height: `${100 + sendMessageData?.textMessage.split("\n").length * 20}px`,
                                outline: 'none'
                            }} onChange={handleChange} name='sendMessageText'></textarea>
                        </div>
                        <div className='bg-[#fff]'>
                            {sendMessageData?.links.map((link, id)=>{
                                return (
                                <div className='border-[1px] p-2 flex flex-row'>
                                        <div className='basis-11/12'>
                                        <p className='text-[16px] font-semibold text-[#0084ff]'>{link?.title}</p>
                                        <p className='text-[14px]'>{link?.url}</p>
                                    </div>
                                    <div className='basis-1/12'>
                                            <div className="p-1 rounded-xl bg-gray-4 " onClick={(e) => DeleteLinkInSendMessage(id)}>
                                                <TrashIcon className='p-[3px] h-[25px] bg-[#f3f4f6] rounded-xl'/>
                                        </div>
                                    </div>
                                </div>
                                )
                            })}
                        </div>
                        <div className='rounded-b-[10px] bg-[#fff] p-3 text-center border-dashed border-2 border-[#e1e5ea] hover:border-[#0084ff] hover:text-[#0084ff]' onClick={(e) => setAddLinkModal(true)}>
                            Add Link
                        </div>

                    </div>
                </div>
            </div>
            <SelectPostModal isModalOpen={selectPostnModal} selectPostModalOnClose={closeSelectPostModal} />
            <AddLinkToSendMessageModal isModalOpen={addLinkModal} addLinkModalOnClose={closeAddLinkModalOnClose} />
        </div>
    )
}



interface SelectPostModalInterface {
    isModalOpen: boolean;
    selectPostModalOnClose: () => void;
    // deleteAutomation: (e: any) => void;
}

interface AddLinkModalInterface {
    isModalOpen: boolean;
    addLinkModalOnClose: () => void;
    // deleteAutomation: (e: any) => void;
}


export default EditAutomation