import { baseApi } from '@Services/api';
import { RequestTypes } from '@Constants/api';

const schedulePostsApis = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        createBaseSchedulePost: builder.mutation({
            query: (payload) => ({
                url: '/v0/create_schedule_post',
                method: RequestTypes.POST,
                body: payload
            })
        }),
        fetchAllScheduledPosts: builder.query({
            query: (payload) => ({
                url: '/v0/schedule_posts',
                method: RequestTypes.GET,
                params: payload
            })
        }),
        deleteScheduledPost: builder.mutation({
            query: (doc_id) => ({
                url: `/v0/delete_post/${doc_id}`,
                method: RequestTypes.DELETE
            })
        }),
        updateSchedulePostStatus: builder.mutation({
            query: (payload) => ({
                url: '/v0/scheduled_post/status',
                method: RequestTypes.POST,
                body: payload
            })
        })
    })
})

export const { useCreateBaseSchedulePostMutation, useLazyFetchAllScheduledPostsQuery, useDeleteScheduledPostMutation, useUpdateSchedulePostStatusMutation } = schedulePostsApis