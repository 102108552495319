import React, { ChangeEvent, useEffect, useState } from 'react';
import { Handle, NodeProps, NodeToolbar, Position } from 'reactflow';
import styles from './styles';
import { nanoid } from '@reduxjs/toolkit';
import {useAppDispatch} from "@Store/store"
import {updateNodeData} from "@Store/nodeReducer"
import { useNodeId, useReactFlow } from 'reactflow';
import ReactModal from 'react-modal';
import InstagramHeader from '../InstagramHeader';
import { DeleteBin } from '@assets/index';
import { ArrowsRightLeftIcon } from '@heroicons/react/24/solid';
import _ from 'lodash';


const ConditionalNode = (props:NodeProps) => {

  const [IfField, setIfField] = useState<any>('')
  const [IfConditionType, setIfConditionType] = useState('')
  const [IfConditionValue, setIfConditionValue] = useState<any>('')

  const [ConditionTypes, setConditionTypes] = useState({})

  const nodeId = useNodeId()
  const dispatch = useAppDispatch()

  const reactflow = useReactFlow()

  useEffect(() => {
    setIfField(props?.data?.IfField || '')
    setIfConditionType(props?.data?.IfConditionType || '')
    setIfConditionValue(props?.data?.IfConditionValue || '')
  }, [])
  useEffect(() => {
    dispatch(updateNodeData({ id: props?.id, data: { IfField, IfConditionType, IfConditionValue } }))
  }, [IfField, IfConditionType, IfConditionValue])

  
  const conditionType = {
    "system_name": {
      is_equal: {
        title: 'Is Equal',
        value_type: 'string'
      },
      is_not_equal: {
        title: 'Is Not Equal',
        value_type: 'string'
      },
      is_empty: {
        title: 'Is Empty',
        value_type: 'string'
      },
      contains: {
        title: 'Contains',
        value_type: 'string'
      },
    },
    "system_email": {
      is_equal: {
        title: 'Is Equal',
        value_type: 'string'
      },
      is_not_equal: {
        title: 'Is Not Equal',
        value_type: 'string'
      },
      is_empty: {
        title: 'Is Empty',
        value_type: 'string'
      },
      contains: {
        title: 'Contains',
        value_type: 'string'
      },
    },
    "system_phone": {
      is_equal: {
        title: 'Is Equal',
        value_type: 'string'
      },
      is_not_equal: {
        title: 'Is Not Equal',
        value_type: 'string'
      },
      is_empty: {
        title: 'Is Empty',
        value_type: 'string'
      },
      contains: {
        title: 'Contains',
        value_type: 'string'
      },
    },
    "ig_field_username": {
      is_equal: {
        title: 'Is Equal',
        value_type: 'string'
      },
      is_not_equal: {
        title: 'Is Not Equal',
        value_type: 'string'
      },
      // is_empty: {
      //   title: 'Is Empty',
      //   value_type: 'string'
      // },

      contains: {
        title: 'Contains',
        value_type: 'string'
      },
    },
    "ig_field_verified": {
      is_equal: {
        title: 'Is Equal',
        value_type: 'boolean'
      }
    },
    "ig_field_follower_count": {
      greater_than: {
        title: 'Is Greater than',
        value_type: 'number'
      },
      less_than: {
        title: 'Is Less than',
        value_type: 'number'
      },
      is_equal: {
        title: 'Is Equal than',
        value_type: 'number'
      }
    },
    "ig_field_user_follows_you": {
      is_equal: {
        title: 'Is Equal',
        value_type: 'boolean'
      }
    },
    "ig_field_you_follow_user": {
      is_equal: {
        title: 'Is Equal',
        value_type: 'boolean'
      }
    },
    "ig_field_message_window_open": {
      is_equal: {
        title: 'Is Equal',
        value_type: 'boolean'
      }
    },
    // "ig_field_subscribed": {
    //   is_equal: {
    //     title: 'Is Equal',
    //     value_type: 'boolean'
    //   }
    // }
  } as any


  const renderConditionValueInput = (value_type: string) => {
    if(value_type == "string")
      return <div>
        <input
          type="text"
          placeholder="Enter Value"
          value={IfConditionValue}
          onChange={(e) => setIfConditionValue(e.target.value)}
          className="border-2 p-2 rounded-lg bg-[#ffffff] w-full outline-none"
        />
      </div>

    if (value_type == "number")
      return <div>
        <input
          type="number"
          placeholder="Enter Value"
          value={IfConditionValue}
          onChange={(e) => setIfConditionValue(e.target.value)}
          className="border-2 p-2 rounded-lg w-full outline-none"
        />
      </div>

    if (value_type == "boolean")
      return <div>
        <select onChange={(e) => setIfConditionValue(e.target.value)} className="border-2 p-2 rounded-lg bg-[#ffffff] w-full outline-none">
          <option value="">Select Value</option>
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
      </div>
  }
  

  const deleteNode = () => {
    if (nodeId) {
      reactflow.deleteElements({ nodes: [{ id: nodeId }] })
    }
  }
  
  return (
    <div style={styles.INPUT_TEXT_CONTAINER} className='border-2 p-4 static'>

      <NodeToolbar
        isVisible={true}
        position={Position.Top}
      >
        <button className='bg-red-100 p-1 border-2 rounded-lg border-red-400' onClick={deleteNode}>
          {/* <TrashIcon className='size-5'/> */}
          <DeleteBin width='15px' />
        </button>
      </NodeToolbar>
      <div className='flex flex-row'>
        <div className='h-full'>
          <ArrowsRightLeftIcon className='size-7 text-[#887DF2]'/>
        </div>
        <div className='ml-2'>
          <div className="text-xs">System</div>
          <div className="text-s font-bold">Conditional</div>
        </div>
      </div>
      <Handle type='target' position={Position.Left}
        style={{
          width: "10px",
          height: "10px",
          zIndex: 100,
          backgroundColor: "#000",
        }} />
      {/* <div className=''>
        <p className='font-bold'>Set the conditions</p>
      </div> */}
      <div className='w-full my-2  bg-[#f0f0f0] p-2 rounded-lg'>
        <label>If Condition Field</label>
        <select className='w-full text-sm border-2 border-solid p-2 rounded-lg outline-none' value={IfField} onChange={(e)=>{
          setIfField(e.target.value)
          setIfConditionType("")
          }}>
          <option value="">Choose Field</option>
          <optgroup label='System Fields'>
            <option value="system_name">Name</option>
            <option value="system_email">Email</option>
            <option value="system_phone">Phone</option>
            <option value="system_tag">Tag</option>
          </optgroup>
          <optgroup label='Instagram Fields'>
            <option value="ig_field_username">Username</option>
            <option value="ig_field_verified">Verified</option>
            <option value="ig_field_follower_count">Followers Count</option>
            <option value="ig_field_user_follows_you">User Follow's You</option>
            <option value="ig_field_you_follow_user">You Follow the User</option>
            <option value="ig_field_message_window_open">Message Window Open</option>
            <option value="ig_field_subscribed">Is Subscribed to Instagram Messages</option>
          </optgroup>
        </select>
      </div>

      <div className='w-full my-2  bg-[#f0f0f0] p-2 rounded-lg'>
        <label>Condition Type</label>
        <select disabled={IfField == ""? true:false} className='w-full  text-sm border-2 border-solid p-2 rounded-lg outline-none' value={IfConditionType} onChange={(e) => {
          setIfConditionType(e.target.value)
          setIfConditionValue('')}}>
          <option value="">Choose Condition Type</option>
          {IfField!="" && Object.keys(conditionType[IfField])?.map((e, idx)=>{
            return <option key={e} value={e}>{conditionType[IfField][e].title}</option>
          })}
        </select>
      </div>

      {IfField && IfConditionType &&
        <div className='w-full my-2  bg-[#f0f0f0] p-2 rounded-lg'>
        <label>Condition Value</label>
        {renderConditionValueInput(conditionType[IfField][IfConditionType].value_type)}
      </div>}

      <Handle
        id={`${nodeId}_true`}
        type='source'
        position={Position.Right}
        style={{
          position: "absolute",
          top: "80%",
          right: "-4px",
          width: "10px",
          height: "10px",
          zIndex: 100,
          backgroundColor: "#fff",
          border: "2px solid green"
        }}
      />

      <Handle
        id={`${nodeId}_false`}
        type='source'
        position={Position.Right}
        style={{
          position: "absolute",
          top: "90%",
          right: "-4px",
          width: "10px",
          height: "10px",
          zIndex: 100,
          backgroundColor: "#fff",
          border: "2px solid red"
        }}
      />

    </div>
  );
};

export default ConditionalNode