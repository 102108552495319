import React, {
  ChangeEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";


import _ from 'lodash';
import styles from "./styles";
import InstagramHeader from "../InstagramHeader";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { Handle, NodeToolbar, Position, useNodeId, useReactFlow } from "reactflow";
import Loader from "@Components/Loader";
import { useUploadImageMutation } from "./apiSlice";
import { convertBase64Image } from "./utils";
import { DeleteBin } from "@assets/index";

import "swiper/css";
import "swiper/css/navigation";
import ReactModal from "react-modal";
import { LinkIcon, PhotoIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { nanoid } from "@reduxjs/toolkit";
import { updateNodeData } from "@Store/nodeReducer";
import { useAppDispatch, useAppSelector } from "@Store/store";

interface Data {
  image_url: string;
  title: string;
  subtitle: string;
  buttons: Array<any>;
}

const Gallery = (props: any) => {

  const [images, setImages] = useState<any[]>([]);
  const [title, setTitle] = useState("title");
  const [subTitle, setSubTitle] = useState("sub");
  const [data, setData] = useState<Data[]>([
    {
      image_url: "",
      title: "",
      subtitle: "",
      buttons: [],
    },
  ]);
  const [galleryModal, setGalleryModal] = React.useState(false);

  const nodeId = useNodeId();

  let { edges, nodes } = useAppSelector((state: any) => state.node);

  const dispatch = useAppDispatch()

  const reactflow = useReactFlow()


  React.useEffect(() => {
    if (nodeId) {
      dispatch(updateNodeData({ id: nodeId, data: props.data }))
      setData(props.data)
    }
  }, [])

  useEffect(() => {

    if (nodeId) {
      dispatch(updateNodeData({ id: nodeId, data: data }))
    }

  }, [data])


  const handleGalleryModal = () => {
    setGalleryModal(!galleryModal);
  };


  const deleteNode = () => {
    if (nodeId) {
      reactflow.deleteElements({ nodes: [{ id: nodeId }] })
    }
  }


  return (
    <div style={styles.INPUT_TEXT_CONTAINER} className="border-2 p-4 w-[330px]">

      <NodeToolbar
        isVisible={true}
        position={Position.Top}
      >
        <button className='bg-red-100 p-1 border-2 rounded-lg border-red-400' onClick={deleteNode}>
          {/* <TrashIcon className='size-5'/> */}
          <DeleteBin width='15px' />
        </button>
      </NodeToolbar>
      <div>
        <InstagramHeader header={"Cards/Gallery"} />
      </div>

      <Handle
        type="target"
        position={Position.Left}
        id={nodeId + "_target"}
        style={{
          top: 30,
          width: "10px",
          height: "10px",
          left: -5,
          backgroundColor: "black",
        }}
      />

      <div
        className="flex-grow overflow-x-auto p-1 border-solid"
        style={{ overflow: "visible" }}
      >
        <div className="flex flex-nowrap space-x-4 " onClick={() => setGalleryModal(true)}>
          {data.map((el, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-full flex height-auto bg-[#EEF1F4] p-2 rounded-lg"
            >
              <div className="w-full border-2 border-solid rounded-lg">
                {el.image_url == "" ? (
                  <div className="flex justify-center h-[280px] h-min-[130px] items-center text-center border-2 w-[100%] text-[#8D9EB2] rounded-lg border-dashed border-[#8D9EB2] ">
                    <div className="">
                      <PhotoIcon className={`size-7 mx-auto`} />
                      <p>Add an Image</p>
                    </div>
                  </div>
                ) : (
                  <img className="w-[100%] h-[280px] object-cover  rounded-t-lg" src={el.image_url} />
                )}
                <div className="mt-2 px-2">
                  <p className="text-s">
                    {el.title == "" ? "Add a title" : el.title}
                  </p>
                  <p className="text-xs text-[#8D9EB2]">
                    {el.subtitle == "" ? "Add a subtitle" : el.subtitle}
                  </p>
                </div>
                <div className="p-2">
                  {el?.buttons?.map((btn: any, idx: number) => (
                    <div className="mt-1" key={idx}>
                      <button className="flex items-center  justify-between py-2 px-4 bg-white rounded-lg w-full">
                        {btn?.title}
                        <div>
                          {btn.type == "other" ? <Handle
                            position={Position.Right}
                            type="source"
                            id={nodeId + "_" + index + "_" + idx}
                            style={{
                              position: "relative",
                              top: "5px",
                              width: "10px",
                              height: "10px",
                              zIndex: 100,
                            }}
                          /> : <LinkIcon className="text-[#0084ff] size-4" />}
                        </div>
                      </button>
                    </div>
                  ))}
                </div>

              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-4">
        <button
          className="border-2 border-black py-2 px-4 rounded-xl"
          onClick={() => setGalleryModal(true)}
        >
          Edit
        </button>
      </div>
      <Handle
        position={Position.Right}
        type="source"
        id={nodeId + "_source"}
        style={{
          position: "absolute",
          top: "unset",
          bottom: "20px",
          width: "10px",
          height: "10px",
          zIndex: 100,
        }}
      />
      <GalleryModal
        isModalOpen={galleryModal}
        galleryModalClose={handleGalleryModal}
        title={title}
        subtitle={subTitle}
        // onTitleChange={handleTitleChange}
        // onSubTitleChange={handleSubTitleChange}
        // onImageChange={handleImageChange}
        images={images}
        // onDeleteImage={deleteImage}
        setImages={setImages}
        data={data}
        // addSlide={handleData}
        // addButtonText={addButtonText}
        // handleButtonData={handleButtonDataChange}
        // deleteButtonText={deleteButtonText}
        // deleteSlide={handleDeleteData}
        updateData={(new_data: any) => setData(new_data)}
      />
    </div>
  );
};

interface GalleryModal {
  isModalOpen: boolean;
  title: string;
  subtitle: string;
  images: any[];
  data: Data[];
  galleryModalClose: () => void;
  // onTitleChange: (e: ChangeEvent<HTMLInputElement>, idx: number) => void;
  // onSubTitleChange: (e: ChangeEvent<HTMLInputElement>, idx: number) => void;
  // onImageChange: (e: any, idx: number) => void;
  updateData: (data: any) => void;
  setImages: (data: any) => void;
  // addSlide: (el: Data) => void;
  // addButtonText: (idx: number) => void;
  // handleButtonData: (text: string, slide_id: number, button_id: number) => void;
  // deleteButtonText: (id:number, idx:number) => void
  // deleteSlide: (idx: number) => void
}

const GalleryModal = (props: GalleryModal) => {
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageChoose, setimageChoose] = useState(false);
  const [GalleryData, setGalleryData] = useState<any>([]);
  const [DataVerified, setDataVerified] = useState({ status: false, message: '' })

  useEffect(() => {
    setGalleryData(props?.data)
  }, [props?.data])


  useEffect(() => {
    //verify Data
    const verifiedObj = { status: true, message: '' }
    for (let i = 0; i < GalleryData.length; i++) {
      const buttonData = GalleryData[i];
      console.log(buttonData)
      if (buttonData?.image_url == "") {
        verifiedObj['status'] = false;
        verifiedObj['message'] = `Image is missing in card ${i}`;
        break;
      }
      if (buttonData?.text == "") {
        verifiedObj['status'] = false;
        verifiedObj['message'] = `Title is missing in card ${i}`;
        break;
      }
      const buttons = buttonData?.buttons;
      for (let j = 0; j < buttons.length; j++) {
        const button = buttons[j];
        if (button?.type) {
          if (button?.title == "") {
            verifiedObj['status'] = false;
            verifiedObj['message'] = `Button Title is missing in card ${i}`;
            break;
          }
          if (button?.data == "") {
            verifiedObj['status'] = false;
            verifiedObj['message'] = `Button link is missing in card ${i}`;
            break;
          }
          if (!button?.data.includes("https://")) {
            verifiedObj['status'] = false;
            verifiedObj['message'] = `Invalid link in button for card ${i}`;
            break;
          }
        }
      }


    }
    setDataVerified(verifiedObj)
  }, [GalleryData])



  const CloseGalleryModal = () => {
    setGalleryData(props?.data)
    props.galleryModalClose()
  }


  const handleData = (el: Data) => {
    let temp = [...GalleryData];
    temp.push(el);
    setGalleryData(temp);
  };

  const handleDeleteData = (idx: number) => {
    let temp = [...GalleryData];
    temp.splice(idx, 1);
    setGalleryData(temp);
  };


  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>, idx: number) => {
    let temp = _.cloneDeep(GalleryData);
    temp[idx]["title"] = e.target.value.slice(0, 80);

    setGalleryData(temp);
  };

  const handleSubTitleChange = (
    e: ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    let temp = _.cloneDeep(GalleryData);
    temp[idx]["subtitle"] = e.target.value.slice(0, 80);

    setGalleryData(temp);
  };

  const handleImageChange = (e: any, idx: number) => {
    let temp = _.cloneDeep(GalleryData);
    temp[idx]["image_url"] = e;
    setGalleryData(temp);
  };

  const deleteImage = (id: number) => {
    let temp = _.cloneDeep(GalleryData);
    temp[id].image_url = "";
    setGalleryData(temp);
  };

  const addButtonText = (idx: number) => {
    let temp = _.cloneDeep(GalleryData);
    temp[idx].buttons.push({ title: '', data: '', type: 'link' });
    setGalleryData(temp);
  };



  const deleteButtonText = (id: number, idx: number) => {
    let temp = _.cloneDeep(GalleryData);
    temp[id].buttons.splice(idx, 1)
    setGalleryData(temp);
  }

  const saveDataToNode = () => {
    props.updateData(GalleryData)
    props.galleryModalClose()
  }



  let [
    uploadImage,
    { data: UploadImageData, isLoading: isUploading, error: UploadImageError },
  ] = useUploadImageMutation();

  const onImageChange = (image_url: any, idx: number) => {
    if (image_url) {
      // setImage(URL.createObjectURL(event.target.files[0]));
      handleImageChange(image_url, idx);
    }
  };


  const addSlide = () => {
    let temp: Data = {
      title: "",
      subtitle: "",
      image_url: "",
      buttons: [],
    };

    handleData(temp);
  };

  const imgRef = useRef<HTMLInputElement>(null);

  const handleFileChange = async (
    event: ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const fileObj = event?.target?.files && event?.target?.files[0];
    if (!fileObj) {
      return;
    }

    event.target.value = "";
    const objectURL = URL.createObjectURL(fileObj);
    try {
      const img = await convertBase64Image(fileObj);
      const response = await uploadImage({
        image_data: img,
        file_name: fileObj.name,
        file_type: fileObj.type,
      }).unwrap();
      onImageChange(response.url, idx);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleImageUploadViaUrl = async (
    imageUrl: string | undefined,
    idx: number
  ) => {
    try {
      const response = await uploadImage({
        image_url: imageUrl,
        file_name: String(nanoid()),
        file_type: "",
      }).unwrap();
      handleImageChange(response?.url, idx);
      setImageUrl("");
    } catch (error) {
      console.error(error);
    }
  };

  const handleSlideData = (dataType: string, data: string, buttonIdx: number, slideIdx: number) => {

    let button_data = _.cloneDeep(GalleryData)
    console.log(button_data)

    if (dataType == "title") button_data[slideIdx]['buttons'][buttonIdx]['title'] = data
    if (dataType == "data") button_data[slideIdx]['buttons'][buttonIdx]['data'] = data
    if (dataType == "type") button_data[slideIdx]['buttons'][buttonIdx]['type'] = data

    setGalleryData(button_data);
  }

  return (
    <ReactModal
      isOpen={props.isModalOpen}
      onRequestClose={CloseGalleryModal}
      style={{ overlay: {}, content: styles.deleteContactModalContent }}
      portalClassName={"ReactModalPortal"}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      role={"dialog"}
      preventScroll={false}
      ariaHideApp={false}
    // className={'h-max-[90vh] bg-white border-2'}

    >
      <div className="w-auto h-auto rounded-lg ">
        <header className="mb-2 border-b-2 p-2 flex gap-4 items-center justify-between">
          <h2 className="font-bold">Edit Gallery</h2>
          <h2 className="" onClick={props.galleryModalClose}>
            X
          </h2>
        </header>

        <div className="">
          <Swiper
            pagination={{
              // clickable: true,
              type: 'fraction',
              el: '.swiper-pagination'
            }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper w-[350px] "
          >
            {GalleryData.map((el: Data, idx: number) => {
              return (
                <SwiperSlide className="border-2 rounded-lg" key={"swiperSlide_" + idx}>
                  <div className="border-b-2">
                    {el.image_url == "" ? (
                      <div className="flex justify-center h-[280px] min-h-[130px] items-center text-center border-2 w-[100%] text-[#8D9EB2] rounded-lg border-dashed border-[#8D9EB2]">
                        {isUploading ? (
                          <Loader isLoading={isUploading} />
                        ) : (
                          <div className="">
                            <PhotoIcon className={`size-7 mx-auto`} />
                            <p>
                              Add an <span
                                className="text-[#0084ff] hover:cursor-pointer"
                                onClick={() => { setimageChoose(true) }}
                              >
                                Image URL
                              </span> or <span
                                className="text-[#0084ff] hover:cursor-pointer"
                                onClick={() => { imgRef.current?.click(); setimageChoose(false) }}
                              >
                                Upload an Image
                              </span>

                              {imageChoose &&
                                <div className="flex gap-2">
                                  <input
                                    type="text"
                                    value={imageUrl}
                                    placeholder="Paste Image Link Here"
                                    className="border-2 border-blue-400 mt-2 w-[75%] rounded-lg p-2"
                                    onChange={(
                                      e: ChangeEvent<HTMLInputElement>
                                    ) => setImageUrl(e.target.value)}
                                  />
                                  <button
                                    className="px-4 py-2 rounded-lg bg-green-600 text-white font-semibold mt-2"
                                    onClick={() =>
                                      handleImageUploadViaUrl(imageUrl, idx)
                                    }
                                  >
                                    Upload
                                  </button>
                                </div>
                              }

                            </p>
                            <input
                              ref={imgRef}
                              className={`hidden`}
                              type="file"
                              accept="image/*"
                              onChange={(e) => handleFileChange(e, idx)}
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="relative">
                        <div>
                          <img
                            className="w-[100%] h-[280px] object-cover rounded-lg"
                            src={el.image_url}
                          />
                          <XCircleIcon
                            onClick={() => deleteImage(idx)}
                            className="size-6 absolute top-0 right-0 hover:text-[#616161] hover:cursor-pointer"
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col justify-center p-2 gap-2">
                    <div className="flex flex-col ">
                      <input
                        value={el.title}
                        id="title"
                        placeholder="Title"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleTitleChange(e, idx)
                        }
                        maxLength={80}
                        className="outline-none focus:bg-[#f2f2f2] p-1 rounded-lg text-sm border-2"
                      />
                    </div>
                    <div className="flex flex-col">
                      <input
                        value={el.subtitle}
                        id="subtitle"
                        placeholder="Subitle"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleSubTitleChange(e, idx)
                        }
                        maxLength={80}
                        className="outline-none focus:bg-[#f2f2f2] p-1 rounded-lg text-sm border-2"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 justify-center items-center ">
                    <h3 className="w-full px-2 font-bold">Buttons</h3>
                    {el?.buttons?.map((btn: any, id: number) => (
                      // <input type="text" value={btn} className="p-2 border-2 border-black rounded-lg" onChange={(e:ChangeEvent<HTMLInputElement>) => props.handleButtonText(e.target.value, idx, id)} />
                      <div key={"button_key" + idx + "_" + id} className="w-full px-2">
                        <div className="flex gap-1 items-center justify-center w-full">
                          <input
                            type="text"
                            placeholder="Enter Button Text"
                            value={btn?.title}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleSlideData('title', e.target.value, id, idx)
                            }
                            className="border-2 border-dotted p-1 text-sm rounded-lg focus:bg-[#f0f0f0] w-full outline-none"
                          />
                          <select className="border-2 border-solid p-1 text-sm rounded-lg"
                            onChange={(e) => handleSlideData('type', e.target.value, id, idx)} value={btn?.type}>
                            <option value="">Select Type</option>
                            <option value="link" selected>Link</option>
                            {/* <option value="other">Other</option> */}
                          </select>
                          <div
                            className="border-2 border-[#ef4444] p-[4px] rounded-lg bg-[#fee2e2]"
                            onClick={() => deleteButtonText(idx, id)}
                          >
                            <DeleteBin />
                          </div>
                        </div>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter the link here"
                            value={btn?.data}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleSlideData('data', e.target.value, id, idx)
                            }
                            className={`border-2 p-1 my-2 rounded-lg text-sm rounded-lg w-full outline-none w-full ${btn?.data.includes("https://") ? '' : 'bg-red-200'}`}
                          />
                          {!btn?.data.includes("https://") ? <p className="text-sm text-red-500">Link must have "https://" at the beginning</p> : <></>}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex gap-1 justify-center items-center">
                    {el?.buttons?.length < 3 && <button
                      className="px-4 py-2 border-2 border-black rounded-lg"
                      onClick={() => addButtonText(idx)}
                    >
                      Add Button Text
                    </button>}
                    <button
                      className="px-4 py-2 border-2 border-white rounded-lg bg-[#ff2929] text-white hover:bg-[#da1414]"
                      onClick={() => handleDeleteData(idx)}
                      disabled={GalleryData.length == 1}
                    >
                      Delete Slide
                    </button>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="swiper-button-prev"></div>
          <div className="swiper-button-next"></div>
          <div className="swiper-pagination text-center text-lg font-bold"></div>
        </div>
        <div className="mt-4 flex justify-center gap-4 items-center">
          <button
            className="w-[100%] bg-gray-500 hover:bg-black text-white px-2 py-2 rounded-lg disabled:bg-gray-400"
            onClick={() => addSlide()}
            disabled={GalleryData.length >= 10}
          >
            {GalleryData.length >= 10 ? "Maximum 10 Items limit reached" : "Add Slide"}
          </button>
          <button
            className="w-[100%] bg-green-500 hover:bg-green-600 text-white px-2 py-2 rounded-lg"
            onClick={() => saveDataToNode()}
            disabled={!DataVerified?.status}
          >
            Save
          </button>

        </div>
        <div>
          {!DataVerified?.status && <p className="text-center text-red-500 my-1">Cannot Save as {DataVerified?.message}</p>}
        </div>
      </div>
    </ReactModal>
  );
};

export default Gallery;
