import React from 'react'
import {EllipsisHorizontalIcon, HeartIcon, PaperAirplaneIcon, ChatBubbleOvalLeftIcon, BookmarkIcon} from '@heroicons/react/24/outline' 

interface PostComponentProps{
    caption: string
    images: string[]
    isCarousel?: boolean
    igProfile: any
}

const PostComponent = ({caption, images, isCarousel, igProfile}: PostComponentProps) => {
  return (
    <div className='border-2 border-gray-400 rounded-lg h-auto pb-2'>
        <div className='p-4 flex justify-between items-center'>
            <div className='flex items-center gap-2'>
                <img src={igProfile?.profile_picture_url} className='h-[2rem] w-[2rem] rounded-[50%] object-contain' />
                <p className='font-bold text-[14px]'>{igProfile?.username}</p>
            </div>
            <EllipsisHorizontalIcon className={`size-8`}/>
        </div>
        <div className='px-4 py-2'>
            <img src={images[0]} className='w-full h-[16vh] object-fill' />
        </div>
        <div className='px-4 py-2 flex justify-between items-center'>
            <div className='flex items-center gap-4'>
                <HeartIcon className={`size-4`} />
                <ChatBubbleOvalLeftIcon className={`size-4`} />
                <PaperAirplaneIcon className={`size-4`} />
            </div>
            <div>
                <BookmarkIcon className={`size-4`} />
            </div>
        </div>
        <div className='p-4'>
            {caption != "" && (
                <div>
                    <span className='font-bold'>{igProfile?.username + " "}</span>{caption}
                </div>
            )}
        </div>
    </div>
  )
}

export default PostComponent