const styles = {
    chooseTriggerModalContent: {
        height: 'fit-content',
        width: '500px',
        margin: 'auto',
        fontFamily: 'DM Sans',
        borderRadius: '20px'
    }
}

export default styles;