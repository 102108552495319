const styles = {
    INPUT_TEXT_CONTAINER: {
      borderRadius: 10,
      backgroundColor: "#f0f0f0",
      color: "#000",
    },
    INPUT_TEXT: {
      backgroundColor: "#f0f0f0",
      color: "#000",
      width: "100%",
      fontSize: "12px",
      userSelect: "text",
    },
    UPLOAD_BTN: {
      backgroundColor: "#fff",
      borderRadius: "10px",
    },
  };
  
  export default styles;