import React from 'react'
import {EllipsisHorizontalIcon, HeartIcon, PaperAirplaneIcon, ChatBubbleOvalLeftIcon, BookmarkIcon} from '@heroicons/react/24/outline' 

interface ReelComponentProps{
    images: string[]
    caption: string
}

const ReelComponent = ({images, caption}: ReelComponentProps) => {
    const [isPlaying, setIsPlaying] = React.useState(false);
  const videoRef = React.useRef<HTMLVideoElement>(null);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="relative w-full max-w-sm mx-auto h-[60vh] bg-black">
      <video
        ref={videoRef}
        className="w-full h-full object-cover"
        src={images[0]}
        loop
        onClick={togglePlay}
      />
      
      {!isPlaying && (
        <div className="absolute inset-0 flex items-center justify-center">
          <button
            className="bg-white bg-opacity-50 rounded-full p-4"
            onClick={togglePlay}
          >
            <svg className="w-8 h-8 text-black" fill="currentColor" viewBox="0 0 20 20">
              <path d="M6.3 2.841A1.5 1.5 0 004 4.11V15.89a1.5 1.5 0 002.3 1.269l9.344-5.89a1.5 1.5 0 000-2.538L6.3 2.84z" />
            </svg>
          </button>
        </div>
      )}
    
        <div className="absolute bottom-[3rem] left-4 flex flex-col items-center space-x-2">
          <div className='flex items-center gap-4'>
            <div className="w-8 h-8 rounded-[50%] flex items-center justify-center">
              <img src="https://cdn.pixabay.com/photo/2023/09/30/09/11/dachshund-8285216_1280.jpg" className='object-contain rounded-[50%]' />
            </div>
            <span className="text-white text-shadow-md">{"username"}</span>
          </div>
            <div className='text-white'>
                <p>{caption}</p>
            </div>
        </div>


      {/* Action buttons */}
      <div className="absolute right-4 bottom-1/4 flex flex-col space-y-4">
        <button className="text-white">
          <HeartIcon className='size-5' />
        </button>
        <button className="text-white">
          <ChatBubbleOvalLeftIcon className='size-5' />
        </button>
        <button className="text-white">
          <PaperAirplaneIcon className='size-5' />
        </button>
      </div>
      
      {/* More options button */}
      <button className="absolute right-4 bottom-4 text-white">
       <EllipsisHorizontalIcon className='size-6' />
      </button>
    </div>
  );
};

export default ReelComponent