import React from 'react'
import { InstagramLogo } from '../../../../assets/index'

interface HeaderProps {
  header: string
  image_size?: string
  header_text_weight?: string
  coming_soon?: boolean
}

const InstagramHeader = ({ header, image_size = '35px', header_text_weight = 'text-sm font-bold', coming_soon = false }: HeaderProps) => {
  return (
    <div className='flex flex-row'>
      <div className='h-full'>
        <img className={`w-[${image_size}]`} src={InstagramLogo} />
      </div>
      <div className='ml-2'>
        <div className="text-xs">Instagram 
          {coming_soon ? <span className='bg-amber-400 px-2 rounded-full text-xs ml-2'>Coming Soon</span> : ""}</div>
        <div className={`${header_text_weight}`}>{header}</div>
      </div>
    </div>
  )
}

export default InstagramHeader