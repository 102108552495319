import Navbar from "@Components/navbar";
import styles from "./styles";
import ContactTable from "./components/ContactTable/ContactTable";
import {DownloadIcon,UploadIcon} from "@radix-ui/react-icons"
import { PlayIcon, TrashPNG } from "@assets/index";
import { useDeleteContactMutation, useUserContactsMutation } from "./apiSlice";
import { useRef, useState, useEffect } from "react";

import ReactModal from 'react-modal';
import ContactEditModal from "./components/ContactEditModal";

const Contacts = () => {

  const [getContacts, { data: contactsData, error: contactsErr, isLoading: contactsLoading }] = useUserContactsMutation()
  const [deleteContact, { data: contactDeleteData, error: contactDeleteErr, isLoading: contactDeleteLoading }] = useDeleteContactMutation()
  

  const [editContactModal, setEditContactModal] = useState(false)
  const [EditContactModalData, setEditContactModalData] = useState({})

  const [deleteContactModal, setDeleteContactModal] = useState(false)
  const [DeleteContactModalData, setDeleteContactModalData] = useState(-1)

  useEffect(() => {
    getContacts({})
  }, [])

  useEffect(() => {
    if (contactsData) console.log(contactsData);

    if (contactsErr) console.log(contactsErr)
    
  }, [contactsData, contactsErr])

  useEffect(() => {
    if (contactDeleteData) {
      getContacts({})
    };

    if (contactDeleteErr) console.error(contactDeleteErr)

  }, [contactDeleteData, contactDeleteErr])


  const closeEditContactModal = () => {
    setEditContactModal(!editContactModal)
  }

  const openEditContactModal = (contact_index: number) => {
    setEditContactModalData(contactsData?.data[contact_index])
    setEditContactModal(!editContactModal)
  }


  const closeDeleteContactModal = () => {
    setDeleteContactModal(!DeleteContactModal)
  }

  const openDeleteContactModal = (contactmap_id: number) => {
    setDeleteContactModalData(contactmap_id)
    setDeleteContactModal(!editContactModal)
  }

  const deleteContactButtonClick = (e:any) => {
    deleteContact(DeleteContactModalData)
    setDeleteContactModal(!DeleteContactModal)
  }

  
  return (
    <div style={styles.BACKGROUND}>
      <Navbar/>

      <div className="flex justify-between py-[4rem] px-[4rem] bg-white-abs">
        <div className="flex flex-col gap-9">
          <p className="font-semibold text-[2rem] text-[#333]">Contacts</p>
          <p className="text-[1rem]">
            Contact list stores the list of all the Instagram accounts that have interacted with you.
          </p>
        </div>
        {/* <div className="flex items-center justify-center gap-8">
          <div className="flex items-center gap-2">
            <img src={PlayIcon} />
            <p className="text-[#7367F0] font-bold text-[16px]">+Watch Tutorial</p>
          </div>
          <div className="flex flex-col items-center gap-4 relative top-4">
            <button style={styles.ADD_BUTTON}>+ Add Contact</button>
            <p className="text-[12px] text-[#A9A9A9] font-bold">
              (375784 in total)
            </p>
          </div>
        </div> */}
      </div>

      <div className="flex flex-col">
        {/* <div className="flex justify-between p-[2rem] ">
          <div className="flex items-center gap-4">
            <p className="font-bold text-[16px]">Sorted by:</p>
            <div>
              <select
                name=""
                className="py-3 px-4 text-[#808080] rounded-[5px] text-[12px] font-bold border-[0.5px] border-[#A9A9A9]"
              >
                <option>Last Updated</option>
              </select>
            </div>
            <div className="relative">
              <input
                type="text"
                className="py-3 px-4 text-[#808080] rounded-[5px] text-[12px] font-bold border-[0.5px] border-[#A9A9A9] w-[200px] placeholder-[#D3D3D3] placeholder-font-normal" placeholder="Search..."
              />
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                }}
              >
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
            </div>
            <div className="p-3 rounded bg-[#22c55e] text-white-abs">
              <MixerHorizontalIcon/>
            </div>
          </div>
          <div className="flex gap-4">
            <div className="flex items-center gap-3 divide-x bg-white-abs rounded-3xl border-2 p-3 divide-slate-400 border-white-abs">
                <div className="flex items-center gap-2 text-[12px]">
                  <UploadIcon />
                  <p>Export</p>
                </div>
                <div className="flex items-center gap-2 text-[12px] pl-2">
                  <DownloadIcon />
                  <p>Import</p>
                </div>
            </div>
            <div className="items-center flex">
              <img src={TrashPNG} />
            </div>
          </div>
        </div>
        */}
        <div className="mt-[3rem] px-[3rem]"> 
          <ContactTable data={contactsData?.data} openEditContactModal={openEditContactModal} openDeleteContactModal={openDeleteContactModal}/>
        </div>
      </div> 

      <EditContactModal data={EditContactModalData} isModalOpen={editContactModal} editContactModalOnClose={closeEditContactModal} />
      <DeleteContactModal isModalOpen={deleteContactModal} editContactModalOnClose={closeDeleteContactModal} deleteContact={deleteContactButtonClick}/>
    </div>
  );
};



interface EditContactModalInterface {
  isModalOpen: boolean;
  data: any
  editContactModalOnClose: () => void;
}


const EditContactModal = (props: EditContactModalInterface) => {

  return (
    <ReactModal
      isOpen={props.isModalOpen}
      onRequestClose={props.editContactModalOnClose}
      style={
        { overlay: {}, content: styles.editContactModalContent }
      }
      portalClassName={"ReactModalPortal"}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      role={"dialog"}
      preventScroll={false} >
      <ContactEditModal data={props?.data} onCloseModal={props.editContactModalOnClose}/>
      </ReactModal>
  )
}



interface DeleteContactModalInterface {
  isModalOpen: boolean;
  editContactModalOnClose: () => void;
  deleteContact: (e:any) => void;
}


const DeleteContactModal = (props: DeleteContactModalInterface) => {

  return (
    <ReactModal
      isOpen={props.isModalOpen}
      onRequestClose={props.editContactModalOnClose}
      style={
        { overlay: {}, content: styles.deleteContactModalContent }
      }
      portalClassName={"ReactModalPortal"}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      role={"dialog"}
      preventScroll={false} 
      >
      <>
        <div className="pb-[.5rem] border-b-2 border-slate-300" style={styles.modalHeader}>
          <h3>Confirm</h3>
        </div>
        <div className="py-[1rem]" style={styles.modalContent}>
          <p>Do you want to remove this contact?</p>
          <div className="flex justify-end mt-[2rem]">
            <button
            className="px-6 py-1 border-2 border-solid rounded border-green-500 mx-2"
            style={styles.greenButton}
              onClick={props.editContactModalOnClose}>
              Cancel
            </button>
            <button className="px-6 py-1 border-2 border-solid rounded" style={styles.redButton} onClick={props.deleteContact}>Delete</button>
          </div>
        </div>
      </>
    </ReactModal>
  )
}



export default Contacts;
