import React, { ChangeEvent, useEffect, useState } from 'react';
import { Handle, NodeProps, NodeToolbar, Position } from 'reactflow';
import styles from './styles';
import { nanoid } from '@reduxjs/toolkit';
import {useAppDispatch} from "@Store/store"
import {updateNodeData} from "@Store/nodeReducer"
import { useNodeId, useReactFlow } from 'reactflow';
import ReactModal from 'react-modal';
import InstagramHeader from '../InstagramHeader';
import { DeleteBin } from '@assets/index';
import _ from 'lodash';
import { LinkIcon } from '@heroicons/react/24/solid';
const ButtonText = (props:NodeProps) => {
  
  const [text, setText] = useState("")
  const [buttonTextModal, setButtonTextModal] = useState(false)
  const [buttonText, setButtonText] = useState<any[]>([])

  const reactflow = useReactFlow()
  const nodeId = useNodeId()

  const dispatch = useAppDispatch()

  useEffect(() => {
    setText(props?.data[0]?.text || '')
    setButtonText(props?.data[0]?.buttonText || [])
  }, [])
  

  const deleteNode = () => {
    if(nodeId){
      reactflow.deleteElements({nodes: [{id: nodeId}]})
    }
  }
  
  useEffect(() => {
    dispatch(updateNodeData({ id: props?.id, data: [{buttonText, text}] }));
  }, [buttonText, text])
  

  const openButtonTextModal = () => {
    setButtonTextModal(true)
  }

  const closeButtonTextModal = () => {
    setButtonTextModal(!buttonTextModal)
  }

  const updateButtonData = (buttonData:any)=>{
    setButtonText(buttonData)
  }

  const updateTextData = (text:string) => {
    setText(text)
  }

  return (
    <div style={styles.INPUT_TEXT_CONTAINER} className='border-2 p-4'>
      <NodeToolbar
        isVisible={true}
        position={Position.Top}
      >
        <button className='bg-red-100 p-1 border-2 rounded-lg border-red-400' onClick={deleteNode}>
          {/* <TrashIcon className='size-5'/> */}
          <DeleteBin width='15px' />
        </button>
      </NodeToolbar>
      <div>
        <InstagramHeader header={"Button Text"}/>
      </div>
      <Handle
      type='target'
      position={Position.Left}
      style={{
        top: "10%",
        width: "10px",
        height: "10px",
      }}
      />
      <div className='mt-4'>
        <textarea value={text} placeholder='Enter text...' className='h-[10vh] w-full rounded-xl p-3 border-2 border-dotted' disabled style={{
          height: `${40 + text.split("\n").length * 20}px`,
          outline: 'none'
        }}></textarea>
      </div>

      <div className='flex flex-col gap-4'>
        {buttonText && buttonText?.map((btn:any, idx:number) => {
          return (
            <div className='flex items-center justify-center gap-2' key={idx}>
                <button className={`py-2 px-4 rounded-xl border-2 border-dotted h-[45px] w-full ${btn.title == "" ? " border-rose-600 bg-[#e11d4870]" : ""}`}>{btn.title}</button>
                {btn != "" && btn.type == "postback" ?<Handle 
                  position={Position.Right} 
                  type='source' 
                id={nodeId + "_" + idx + "_" + btn.type +"_source"} 
                  style={{
                    position: 'relative',
                    right:"25px",
                    top: "5px",
                    width: "10px",
                    height: "10px",
                    zIndex: 100
                  }}
              /> : <LinkIcon className="text-[#0084ff] size-4" />}
            </div>
          )
        })}
      </div>

      <div className='flex gap-4 items-center justify-center mt-4'>
        <button className='py-2 px-4 rounded-xl border-2 border-black' onClick={() => openButtonTextModal()}>Edit Button Text</button>
      </div>
      <ButtonTextModal isModalOpen={buttonTextModal} closeButtonTextModal={closeButtonTextModal} text={text} setTextData={updateTextData} buttonText={buttonText} setButtonData={updateButtonData} nodeId={nodeId} />
      <Handle
        id={`${nodeId}_true`}
        type='source'
        position={Position.Right}
        style={{
          position: "absolute",
          top: "90%",
          right: "-4px",
          width: "10px",
          height: "10px",
          zIndex: 100,
        }}
      />
    </div>
  );
};

interface ButtonTextModalProps{
  isModalOpen: boolean
  text: string
  buttonText: any[]
  setButtonData: (data:any) => void 
  setTextData: (text:string) => void
  closeButtonTextModal: () => void
  nodeId: string | null
}

const ButtonTextModal = (props:ButtonTextModalProps) => {

  const [ButtonTextData, setButtonTextData] = useState<any>(props?.buttonText)
  const [TextData, setTextData] = useState(props?.text)
  const [DataVerified, setDataVerified] = useState({ status: false, message: '' })


  useEffect(() => {
    setButtonTextData(props?.buttonText)
    setTextData(props?.text)
  }, [props?.text, props?.text])



  useEffect(() => {
    //verify Data
    const verifiedObj = { status: true, message: '' }
    for (let i = 0; i < ButtonTextData.length; i++) {
      const button = ButtonTextData[i];
      if (button?.type == "link") {
        if (button?.title == "") {
          verifiedObj['status'] = false;
          verifiedObj['message'] = `Button Title is missing in button ${i}`;
          break;
        }
        if (button?.data == "") {
          verifiedObj['status'] = false;
          verifiedObj['message'] = `Button link is missing in button ${i}`;
          break;
        }
        if (!button?.data.includes("https://")) {
          verifiedObj['status'] = false;
          verifiedObj['message'] = `Invalid link (missing https://) in button for button ${i}`;
          break;
        }
      } else if (button?.type == "postback") {
        verifiedObj['status'] = true;
      }


    }
    setDataVerified(verifiedObj)
  }, [ButtonTextData ])

  const saveDataToNode = () => {
    if (DataVerified){
      const new_button_data:any = []

      ButtonTextData.forEach((el:any, idx:number)=>{
        if (el.type == "link") new_button_data.push(el);
        else if (el.type == "postback") {
          let temp = _.cloneDeep(el);
          temp['data'] = props?.nodeId + "_" + idx + "_" + el.type + "_source-source"
          new_button_data.push(temp)
        }
      })
      props.setButtonData(new_button_data)
      
      props.setTextData(TextData)
      props.closeButtonTextModal()
    }
  }

  

  

  const handleText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTextData(e.target.value)
  }

  const handleButtonInput = (type: string, el: string, idx: number) => {
    let temp = _.cloneDeep(ButtonTextData);
    if (type == "title") temp[idx]['title'] = el
    if (type == "data") temp[idx]['data'] = el
    if (type == "type") temp[idx]['type'] = el
    setButtonTextData(temp)
  }

  const addButtonText = () => {
    let temp = _.cloneDeep(ButtonTextData);
    temp.push({ title: '', data: '', type: 'link' });
    setButtonTextData(temp)
  }

  const deleteButtonText = (idx: number) => {
    let temp = _.cloneDeep(ButtonTextData);
    temp.splice(idx, 1)
    setButtonTextData(temp)
  }


  return(
    <ReactModal
    isOpen={props.isModalOpen}
    onRequestClose={props.closeButtonTextModal}
    style={{ overlay: {}, content: styles.deleteContactModalContent }}
    portalClassName={"ReactModalPortal"}
    shouldCloseOnOverlayClick={true}
    shouldCloseOnEsc={true}
    role={"dialog"}
    preventScroll={false}
    ariaHideApp={false}
    >
      <div>
        <header className='mb-2 border-b-2 p-2 flex gap-4 items-center justify-between'>
          <h2 className='font-bold'>Set Button Text</h2>
          <h2 className='' onClick={props.closeButtonTextModal}>X</h2>
        </header>
        <div>
          <textarea value={TextData} placeholder='Enter text...' className='h-[20vh] w-full rounded-xl p-3 border-2 border-dotted' style={{
            height: `${100 + TextData.split("\n").length * 20}px`,
            outline: 'none',
          }} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleText(e)}></textarea>
        </div>

        <div className='flex flex-col justify-center items-center gap-1 mt-1'>

          {ButtonTextData?.map((btn: any, id: number) => (
            <div className='w-full' key={"button_key" + "_" + id}>
              <div className="flex gap-1 items-center justify-center w-full">
                <input
                  type="text"
                  placeholder="Enter Button Text"
                  value={btn?.title}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleButtonInput('title',e.target.value, id)}
                  className="border-2 border-dotted p-1 text-sm rounded-lg focus:bg-[#f0f0f0] w-full outline-none"
                />
                <select className="border-2 border-solid p-1 text-sm rounded-lg"
                  onChange={(e) => { handleButtonInput('type', e.target.value, id)}} value={btn?.type}
                  >
                  <option value="link">Link</option>
                  <option value="postback">Other</option>
                </select>
                <div
                  className="border-2 border-[#ef4444] p-[4px] rounded-lg bg-[#fee2e2]"
                  onClick={() => deleteButtonText(id)}
                >
                  <DeleteBin />
                </div>
              </div>
              {btn.type == "link" && <div className='w-full'>
                <input
                  type="text"
                  placeholder="Enter the link here"
                  value={btn?.data}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleButtonInput('data', e.target.value, id) }
                  className="border-2 p-1 my-2 rounded-lg text-sm rounded-lg w-full outline-none w-full"
                />
              </div>}
            </div>
          ))}
        </div>

        <div className='flex justify-center items-center mt-4 gap-5'>
          <button className='w-[100%] border-2 border-black py-2 px-4 rounded-xl' onClick={() => addButtonText()} disabled={!(ButtonTextData?.length < 2)}>{ButtonTextData?.length < 2 ? "Add Button":"Max 2 button limit"}</button>
          <button
            className="w-[100%] bg-green-500 hover:bg-green-600 text-white px-2 py-2 rounded-lg"
            onClick={() => saveDataToNode()}
            disabled={!DataVerified?.status}
          >
            Save
          </button>
        </div>
        <div>
          {!DataVerified?.status && <p className="text-center text-red-500 my-1">Cannot Save as {DataVerified?.message}</p>}
        </div>
        
      </div>
    </ReactModal>
  )
}

export default ButtonText