import { nanoid } from '@reduxjs/toolkit'
import {PayloadAction, createSlice} from "@reduxjs/toolkit"
import {Node, Edge, NodeChange, applyNodeChanges, EdgeChange, applyEdgeChanges, addEdge, Connection, ReactFlowJsonObject} from 'reactflow'

export type RFState = {
    nodes: Node[],
    edges: Edge[],
}

interface UpdateNodeDataPayload {
    id: string;
    data: any;
  }

export const initialState: any = {
    nodes: {},
}

const nodeSlice = createSlice({
    name: 'nodes',
    initialState,
    reducers: {
        updateNodeData: (state, action: PayloadAction<UpdateNodeDataPayload>) => {
            const {id, data} = action.payload
            const node_temp = {...state.nodes}
            node_temp[id] = data
            state.nodes = node_temp
        }
    }
})

export const {updateNodeData} = nodeSlice.actions

export default nodeSlice.reducer
