import { baseApi } from '@Services/api';
import { RequestTypes } from '@Constants/api';

const startNodeApis = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getAllIGPosts: builder.mutation({
            query: (payload) => ({
                url: '/v0/user/instagram/posts',
                method: RequestTypes.POST,
                body: payload
            })
        }),
        getAllIGStories: builder.mutation({
            query: (payload) => ({
                url: '/v0/user/instagram/stories',
                method: RequestTypes.POST,
                body: payload
            })
        }),
        getAllIGLive: builder.mutation({
            query: (payload) => ({
                url: '/v0/user/instagram/live_media',
                method: RequestTypes.POST,
                body: payload
            })
        }),
    })
})

export const {useGetAllIGPostsMutation, useGetAllIGLiveMutation, useGetAllIGStoriesMutation} = startNodeApis