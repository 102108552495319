import { AutomationIcon, Avatar, ContactIcon, InboxIcon, LPLogoWName, SettingsIcon } from "../../assets/index";
import { NavLink, useNavigate } from "react-router-dom";
import NavItem from "./NavItem";
import { ROUTES } from "@Constants/routes";
import { RootState, useAppSelector } from "@Store/store";
import { CogIcon, SquaresPlusIcon, UsersIcon, CalendarDaysIcon, ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/solid'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@Components/shadcn/ui/dropdown-menu"


import styles from './styles'
import { onLogout } from "@Utils/common";

const Navbar = () => {

  const { user } = useAppSelector((state: RootState) => state.profile);
  const navigate = useNavigate();

  const handleOnLogout = (e:any) => {
    onLogout()
    navigate(ROUTES.LOGIN)
  }
  
  return (
    <div className="flex bg-[#fff] px-4 py-3 items-center justify-between border-b">
      <div className="flex flex-row gap-7 items-center">
        <div className="">
          <img src={LPLogoWName} className="w-[180px]" alt="Link Please Logo"/>
        </div>
        <div className="h-[100%] border-l-2 border-[#D4D4D4]"></div>
        <div className="flex items-center gap-5 text-[#666666]">
          {/* <NavItem icon={InboxIcon} to={ROUTES.INBOX} name="inbox" /> */}

          <NavLink to={ROUTES.INBOX} className={({ isActive }) =>
            (isActive ? "text-[#887DF2]" : "") + " flex gap-2 items-center "
          }>
            <ChatBubbleOvalLeftEllipsisIcon className={`size-6 `} />
            <span>Inbox</span>
          </NavLink>

          <NavLink to={ROUTES.CONTACTS} className={({ isActive }) =>
            (isActive ? "text-[#887DF2]" : "") + " flex gap-2 items-center "
          }>
            <UsersIcon className={`size-6 `} />
            <span>Contacts</span>
          </NavLink>

          <NavLink to={ROUTES.AUTOMATIONS} className={({ isActive }) =>
            (isActive ? "text-[#887DF2] " : "") + " flex gap-2 items-center "
          }>
            <SquaresPlusIcon className={`size-6 `} />
            <span>Automation</span>
          </NavLink>

          {/* <div className="text-center">
            <NavLink to={ROUTES.SCHEDULED_POST} className={({ isActive }) =>
              (isActive ? "text-[#887DF2] " : "") + " flex gap-2 items-center "
            }>
              <CalendarDaysIcon className={`size-6 `} />
              <span>Scheduled Posts</span>
            </NavLink>
          </div> */}

          <div className="text-center">
            <NavLink to={ROUTES.COMMENTS} className={({ isActive }) =>
              (isActive ? "text-[#887DF2] " : "") + " flex gap-2 items-center "
            }>
              <ChatBubbleOvalLeftEllipsisIcon className={`size-6 `} />
              <span>Comments</span>
            </NavLink>
          </div>

          <NavLink to={ROUTES.USER_DETAILS} className={({ isActive }) =>
            (isActive ? "text-[#887DF2] " : "") + " flex gap-2 items-center "
          }>
            <CogIcon className={`size-6 `} />
            <span>Setting</span>
          </NavLink>
          {/* <NavItem icon={AutomationIcon} to={ROUTES.AUTOMATIONS} name="Automation" />
          <NavItem icon={SettingsIcon} to={ROUTES.SETTINGS} name="Settings" /> */}
        </div>
      </div>
      <div>
        <DropdownMenu>
          <DropdownMenuTrigger className="flex gap-3">
            <div>
              <p>
                {user?.first_name} {user?.last_name}
              </p>
              <p className="text-[#737373]">
                {user?.email}
              </p>
            </div>
            <div>
              <img className="rounded-full w-[50px]" src={Avatar} />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-full">
            <DropdownMenuLabel>My Account</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={handleOnLogout}>Logout</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        
      </div>
    </div>
  )
}

export default Navbar