const styles = {
    INPUT_TEXT_CONTAINER: {
        borderRadius: '10px',
        backgroundColor: '#ffffffa1',
        color: "#000000",
        width: '300px',
    },
    INPUT_TEXT: {
        backgroundColor: 'var(--LIGHTGREY)',
        color: 'var(--BLACK)',
        width: '100%',
        fontSize: '12px',
        padding: '4px'
    },
    CONTAINER: {
        width: '500px',
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
        cursor: 'default',
      },
      modalHeader: {
          fontSize: '1.3rem',
          color: "#000000d9",
          borderBottom: '2px solid #22242626'
      },
      deleteContactModalContent: {
          height: 'fit-content',
          width: '400px',
          margin: 'auto',
          fontFamily: 'DM Sans',
          borderRadius: '20px'
      },
      modalContent: {
          fontSize: '1rem'
      },
      selectedPostCaption: {
          whiteSpace: 'pre-line'
      },
      enterKeywordInput: {
          outline: 0,
          // '&:focus-visible': {
          //     outline: '0',
          // }
    }
};

export default styles