export enum ROUTES {
  ALL = '*',
  HOME = '/',
  SIGNUP_CONNECT_PAGE = "/connect/instagram",
  IG_CONNECT = "/connect/ig",
  IG_CONNECT_SUCCESS = "/connect/instagram/success",
  INBOX = "/inbox",
  CONTACTS = "/contacts",
  LOGIN="/login",
  SIGNUP_PAGE="/signup",
  CONNECT_FACEBOOK = "/connect/facebook",
  SETTINGS="/setting",
  AUTOMATIONS = "/automations",
  EDIT_AUTOMATIONS = "/automations/edit_old/:automation_id",
  USER_DETAILS = "/setting/user",
  CHANNELS = "/setting/channels",
  SCHEDULED_POST = "/scheduled_posts",
  EDIT_SCHEDULED_POST = "/scheduled_posts/edit/:schedule_post_id",
  BLANK = "/",
  FLOW_BUILDER = "/automations/:automation_doc_id",
  COMMENTS = "/comments"
}
