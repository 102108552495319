// Import the functions you need from the SDKs you need
import { ENV } from "@Constants/config";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAqIHR43wlsFU2tP8w15INUDK0kyHf1po8",
    authDomain: "linkplease-stage.firebaseapp.com",
    databaseURL: "https://linkplease-stage-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "linkplease-stage",
    storageBucket: "linkplease-stage.appspot.com",
    messagingSenderId: "196673618729",
    appId: "1:196673618729:web:c1403fa529f40cdf892d38"
};

const firebaseConfigProd = {
    apiKey: "AIzaSyBvWZQ0zqeOcDLI8KkOkuM30_LPKquudKY",
    authDomain: "prod-linkplease.firebaseapp.com",
    projectId: "prod-linkplease",
    storageBucket: "prod-linkplease.appspot.com",
    messagingSenderId: "388771921169",
    appId: "1:388771921169:web:9e556de67842daac1d60b0"
};

const app = initializeApp(ENV == "prod" ? firebaseConfigProd : firebaseConfig);
// const app = initializeApp(firebaseConfigProd);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);