import { UserIcon } from '@heroicons/react/24/solid'
import React, { useEffect, useState } from 'react'
import { format } from 'timeago.js/lib/format'

function UserDetails(props: any) {
    console.log(props?.user)
    const [userData, setUserData] = useState<any>(null)

    useEffect(() => {
        const unsubscribe= ()=>{
            if (!props?.user) return
            console.log(props?.user)
            setUserData(props?.user)

        }
        unsubscribe()
        return () => unsubscribe()
    }, [props?.user])

    if (userData == null) return <span></span>
    
    return (
        <div className='w-full '>
            <div className='flex flex-col w-full p-3 gap-3'>
                <div className='flex flex-col item-center w-full p-3 bg-[#f5f6fa] rounded-lg'>
                    <img className='mx-auto rounded-full' src={userData?.profile_pic} />
                    <h1 className='font-bold text-xl italic mt-2 text-center text-[#887df2]'>@{userData?.username}</h1>
                    <h2 className=' text-md  text-center'>{userData?.name}</h2>
                </div>
                <div className='flex justify-center p-3 gap-3  bg-[#f5f6fa] rounded-lg'>
                    <div className='text-center border-r-2 px-2'>
                        <p className='text-xl font-bold text-[#887df2]'>{userData?.follower_count}</p>
                        <p className='text-[.9rem]'>Total Followers</p>
                    </div>
                    <div className='text-center  border-r-2 px-2'>
                        <p className='text-lg font-bold text-[#887df2]'>{userData?.is_user_follow_business ? "Yes" : "No"}</p>
                        <p className='text-[.9rem]'>Is Following you</p>
                    </div>
                    <div className='text-center  px-2'>
                        <p className='text-lg font-bold text-[#887df2]'>{userData?.is_business_follow_user ? "Yes" : "No"}</p>
                        <p className='text-[.9rem]'>You Following them</p>
                    </div>

                    <div></div>
                </div>

                <div className='text-left p-3 gap-3  bg-[#f5f6fa] rounded-lg'>
                    <h3 className='font-bold text-lg'>Basic Details</h3>
                    <p className='text-md'>Last Interacted time : {format(new Date(userData?.last_interacted_timestamp?.seconds*1000))}</p>
                </div>

                <div className='text-left p-3 gap-3  bg-[#f5f6fa] rounded-lg'>
                    <h3 className='font-bold text-lg'>Custom Attributes</h3>
                    <div className='text-yellow-500 text-xs'>Coming Soon</div>
                </div>
                <div className='text-left p-3 gap-3  bg-[#f5f6fa] rounded-lg'>
                    <h3 className='font-bold text-lg'>Tags</h3>
                    <div className='text-yellow-500 text-xs'>Coming Soon</div>
                </div>
            </div>
        </div>
    )
}

export default UserDetails