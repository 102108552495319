import { combineReducers } from 'redux';

import { baseApi } from '../services/api';
// import { LoginSlice as LoginReducer } from '../pages/Login/loginSlice';
import { profileSlice } from '@Pages/Profile/profileSlice';
import nodeReducer from './nodeReducer';
import editAutomationSidebar from './editAutomationSidebar';


const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  // login: LoginReducer.reducer,
  profile: profileSlice.reducer,
  node: nodeReducer,
  sidebar: editAutomationSidebar
});

export default rootReducer;
