import React, { useEffect } from 'react'
import styles from "./styles";
import { CRYPTOJS_IV, CRYPTOJS_KEY, ENV } from '@Constants/config';
import { useConnectIGLoginMutation } from './apiSlice';
import { FBWhite, LPLogoWName, Selfie2 } from '@assets/index';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '@Constants/routes';
import { displayToast } from '@Utils/toast';
import LocalStorage from '@Utils/storage';
import Loader from '@Components/Loader';
import { useLazyGetProfileQuery } from '@Pages/Profile/apiSlice';
var CryptoJS = require("crypto-js");



declare global {
  interface Window {
    FB: any;
    fbAsyncInit: any;
  }
}

function InstagramLogin() {


  const navigate = useNavigate();

  var key = CryptoJS.enc.Utf8.parse(CRYPTOJS_KEY);
  var iv = CryptoJS.enc.Utf8.parse(CRYPTOJS_IV);
  

  const [connIG, { data: connIGData, error: connIGErr, isLoading: connIGLoading }] = useConnectIGLoginMutation();

  const [getProfile, { data: getProfileDataResponse }] = useLazyGetProfileQuery();

  const query = new URLSearchParams(useLocation().search);

  useEffect(() => {

    const ig_code = query.get('code')
    
    if (ig_code == null) return;

    connIG({ code: ig_code })
    
  
  }, [])

  useEffect(() => {
    if (connIGData) {
      displayToast('success', 'Instagram Account connected')
      LocalStorage.setItem('igu', connIGData?.data?.username)
      LocalStorage.setItem('ppu', connIGData?.data?.profile_picture_url)
      if (connIGData.status) {
        getProfile({});
        navigate(`${ROUTES.IG_CONNECT_SUCCESS}`,{replace:true})
      }
    };
    if (connIGErr) {
      displayToast('error', connIGErr.data.message)
      console.log(connIGErr);
    } 


  }, [connIGData, connIGErr])
  

  
  const handleClickLoginButton = () => {
    if (ENV == 'prod'){
    window.location.href = 'https://www.instagram.com/oauth/authorize?client_id=1018531886387218&redirect_uri=https://app.linkplease.co/connect/ig&response_type=code&scope=business_basic%2Cbusiness_manage_messages%2Cbusiness_manage_comments'
    } else if (ENV == 'stage'){
      window.location.href = 'https://www.instagram.com/oauth/authorize?client_id=1018531886387218&redirect_uri=https://stage.theauto.chat/connect/ig&response_type=code&scope=business_basic%2Cbusiness_manage_messages%2Cbusiness_manage_comments'
    }
  } 
  return (
    <div className="grid grid-cols-2">
      <div className="h-[100vh] bg-[#EAE8FD] px-[1rem] py-[1rem]">
        <div>
          <p className="font-bold text-2xl">
            <img src={LPLogoWName} className="w-[200px] item-center justify-center" />
          </p>
        </div>
        <div className="mt-[1rem]">
          <img src={Selfie2} className="w-[70%] item-center justify-center" />
          <div className="mt-[4rem]">
            <p className="font-bold text-[48px]">Connect Instagram</p>
            <p className="text-[#787781]">Follow the instruction to connect to instagram, account</p>
          </div>
        </div>
      </div>
      <div className="flex item-center justify-center  align-middle my-auto">
        <div className="flex item-center justify-center  text-center w-[500px]" style={styles.SIGNUP_CONTAINER}>
          <div>
            <p className="font-bold text-2xl">
              a few steps away to connect<br />your instagram
            </p>
            <p className="text-[12px] mt-4 text-[#9F9F9F]">Loging with Instagram to connect</p>
            <button disabled={connIGLoading} onClick={handleClickLoginButton} className="bg-gradient-to-r from-[#833ab4] via-[#fd1d1d] to-[#fcb045] flex items-center justify-center gap-4 px-4 py-2 rounded-[0.5rem] w-[100%] my-12 disabled:opacity-[.5]">
              {/* <Loader isLoading={true} /> */}
              {connIGLoading ? <span className="font-[600] text-white text-[16px]">Connecting Instagram</span> :(<>
                <img className='w-[30px] h-[30px] ' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACi0lEQVR4nO2ZsU8UURCHV0ULlBhjZaGSiKU29CRqAMXCoKKJVnbGf8BGE4IJEkMjhTY2xkJRG9QzRG3sCI0XowF7O0EQRcDiPjPhkWyx9+693dndd8n9kpdcsTu/+W53Z+fNRlFLLYUtYA8wAIwBr4F54Cfwzyz5PQe8Au4CZ+ScKAQB20xCz4F1/LUGTAKnJVZUEsQQ8Bk9VYHzRQIcBT6Qn94BXXlDXACWyV8rwJW8noVhiteY2rNjIB5Snh6owJhSWbbuZIW4TDi6mqU6/VZKQt4Xj0zJlrj7gb3APqATOAaMN4jxCziSBkSrxL4HDjj4XXOINe0LcVEJogK0OXq6gIgGfaqUxht7QW4hjz/PFeSTUxUzvZOGRl0hPEFE/S4BpQHU0HGLRzcwYby21qxH7KeNIDpSdrFJFWaHBWIjY/y/QLsNRPYTGqpaPB4refTZQKS30dBHi8f33J9BNnd2GnprqYiyW9TQlA3km5JJpU78Xehpzgay2EQgP2wgWatJkSDr2iCDpvmLr7rTkYRjt9Y9T98NG8hSCpDeSEHAM0/fBVuw+RJBpIfy0VdbsEoZIMB2YFWz/I6WBHJYdfsLnE0R8Ibpn+Kry/JC7E5Y11P49ttA2oE/hF9+V4HdjS7ziyYAmbRCGLO+JgA55QIi9/GXgEGqDSFihucCBhlwBlEYB+UFMu0FYUw7zVQ8FJBl4JA3iDGWhrAWAEgNuJQKImY+EgDIcCaIWAL3SwQZV4GIJXHT4zbTAKmpXYk6n96WCgBZdJ7xZuxU3+QIMgUczBUioVOeVQSZkblzYQAJyfUAT8yY1BdkxUwee6JQxGaSJ4FbUhjqHLMTeAncBk7IOcVn2lJLkY/+A1tlbk1c+pFNAAAAAElFTkSuQmCC" />
                <span className="font-[500] text-white text-[16px]">Login with Instagram</span>
              </>)}
              
            </button>
            <div className="mt-3 flex justify-center flex-col items-center text-[14px]">
              <p className="text-[#9F9F9F]">By signing up, you agree to AutoChat's</p>
              <p className="text-[#0966FF]"><a href="https://linkplease.co/terms-and-conditions/" target='_black'>Terms of Service</a> <span className="text-[#9F9F9F]">and</span> <a href="https://linkplease.co/privacy-policy/" target='_black'>Privacy Policy</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstagramLogin
