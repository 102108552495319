import React, { useEffect } from 'react'
import styles from "./styles";
import { CRYPTOJS_IV, CRYPTOJS_KEY, ENV } from '@Constants/config';
import { useConnectFacebookMutation } from './apiSlice';
import { FBWhite, LPLogoWName, Selfie2 } from '@assets/index';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@Constants/routes';
var CryptoJS = require("crypto-js");



declare global {
  interface Window {
    FB: any;
    fbAsyncInit: any;
  }
}

function ConnectFacebook() {


  const navigate = useNavigate();

  var key = CryptoJS.enc.Utf8.parse(CRYPTOJS_KEY);
  var iv = CryptoJS.enc.Utf8.parse(CRYPTOJS_IV)
  

  const [connFb, { data: connFbData, error: connFbErr, isLoading: connFbLoading }] = useConnectFacebookMutation()

  
  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        // appId: '3981642552159438', 
        appId: ENV == 'prod' ? '3981642552159438':'1054441078939265',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v18.0'
      });
    };

    (function (d, s, id) {
      let js:any, fjs:any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);

  useEffect(() => {
    if (connFbData) {
      if (connFbData.status) navigate(ROUTES.SIGNUP_CONNECT_PAGE)
    } else if (connFbErr) {
      // setShowError(parseErrorMessage(verifyErr));
      console.error(connFbErr)
    }
  }, [connFbData, connFbErr]);

  const handleClickLoginButton = () => {
    window.FB.login((response:any) => {
      if (response.authResponse) {
        var encrypted = CryptoJS.AES.encrypt(response.authResponse.accessToken, key, { iv: iv, mode: CryptoJS.mode.CBC });
        encrypted = encrypted.toString();
        connFb({ enc_at: encrypted })
        
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, { scope:'email,read_insights,pages_show_list,business_management,pages_messaging,pages_messaging_subscriptions,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,instagram_manage_messages,pages_read_engagement,pages_manage_metadata,pages_read_user_content,pages_manage_posts,pages_manage_engagement,instagram_manage_events,public_profile,ads_management'});
  }

  return (
    <div className="grid grid-cols-2">
      <div className="h-[100vh] bg-[#EAE8FD] px-[1rem] py-[1rem]">
        <div>
          <p className="font-bold text-2xl">
            <img src={LPLogoWName} className="w-[200px] item-center justify-center" />
          </p>
        </div>
        <div className="mt-[1rem]">
          <img src={Selfie2} className="w-[70%] item-center justify-center" />
          <div className="mt-[4rem]">
            <p className="font-bold text-[48px]">Connect Instagram</p>
            <p className="text-[#787781]">Follow the instruction to connect to instagram, account</p>
          </div>
        </div>
      </div>
      <div className="flex item-center justify-center  align-middle my-auto">
        {/* <div className="absolute right-10 top-10">
            <button className="border-2 border-[#7772FF] font-bold rounded-3xl p-3 text-[14px]">Get Started Free</button>
        </div> */}
        <div className="flex item-center justify-center  text-center w-[500px]" style={styles.SIGNUP_CONTAINER}>
          <div>
            <p className="font-bold text-2xl">
              a few steps away to connect<br />your instagram
            </p>
            <p className="text-[12px] mt-4 text-[#9F9F9F]">To connect your instagram, you'll have to login with facebook</p>
            <div className="bg-[#1877F2] flex items-center justify-center gap-4 px-4 py-2 rounded-[0.5rem] w-[70%] mt-12 relative left-[2.5rem]">
              <img className='w-[30px] h-[30px]' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACi0lEQVR4nO2ZsU8UURCHV0ULlBhjZaGSiKU29CRqAMXCoKKJVnbGf8BGE4IJEkMjhTY2xkJRG9QzRG3sCI0XowF7O0EQRcDiPjPhkWyx9+693dndd8n9kpdcsTu/+W53Z+fNRlFLLYUtYA8wAIwBr4F54Cfwzyz5PQe8Au4CZ+ScKAQB20xCz4F1/LUGTAKnJVZUEsQQ8Bk9VYHzRQIcBT6Qn94BXXlDXACWyV8rwJW8noVhiteY2rNjIB5Snh6owJhSWbbuZIW4TDi6mqU6/VZKQt4Xj0zJlrj7gb3APqATOAaMN4jxCziSBkSrxL4HDjj4XXOINe0LcVEJogK0OXq6gIgGfaqUxht7QW4hjz/PFeSTUxUzvZOGRl0hPEFE/S4BpQHU0HGLRzcwYby21qxH7KeNIDpSdrFJFWaHBWIjY/y/QLsNRPYTGqpaPB4refTZQKS30dBHi8f33J9BNnd2GnprqYiyW9TQlA3km5JJpU78Xehpzgay2EQgP2wgWatJkSDr2iCDpvmLr7rTkYRjt9Y9T98NG8hSCpDeSEHAM0/fBVuw+RJBpIfy0VdbsEoZIMB2YFWz/I6WBHJYdfsLnE0R8Ibpn+Kry/JC7E5Y11P49ttA2oE/hF9+V4HdjS7ziyYAmbRCGLO+JgA55QIi9/GXgEGqDSFihucCBhlwBlEYB+UFMu0FYUw7zVQ8FJBl4JA3iDGWhrAWAEgNuJQKImY+EgDIcCaIWAL3SwQZV4GIJXHT4zbTAKmpXYk6n96WCgBZdJ7xZuxU3+QIMgUczBUioVOeVQSZkblzYQAJyfUAT8yY1BdkxUwee6JQxGaSJ4FbUhjqHLMTeAncBk7IOcVn2lJLkY/+A1tlbk1c+pFNAAAAAElFTkSuQmCC" />
              <span className="font-bold text-white text-[14px]" onClick={handleClickLoginButton}>Signup with facebook</span>
            </div>
            <div className="mt-3 flex justify-center flex-col items-center text-[14px]">
              <p className="text-[#9F9F9F]">By signing up, you agree to AutoChat's</p>
              <p className="text-[#0966FF]"><a href="https://linkplease.co/terms-and-conditions/" target='_black'>Terms of Service</a> <span className="text-[#9F9F9F]">and</span> <a href="https://linkplease.co/privacy-policy/" target='_black'>Privacy Policy</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConnectFacebook